/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Melder Rolle
 */
export type ReporterRole = 'MAJOR' | 'CHAMBERLAIN' | 'BUILDINGDEPTLEAD' | 'TIMBERYARDLEAD' | 'TIMBERYARDEMPLOYEE' | 'MECHANIC' | 'BUSINESSSALES' | 'MUNICIPCONSULTANT' | 'CONSTRUCTIONCOORDINATOR' | 'CONTROLCENTER' | 'COORDINATORSPECIALTASK' | 'OTHER';

export const ReporterRole = {
    Major: 'MAJOR' as ReporterRole,
    Chamberlain: 'CHAMBERLAIN' as ReporterRole,
    Buildingdeptlead: 'BUILDINGDEPTLEAD' as ReporterRole,
    Timberyardlead: 'TIMBERYARDLEAD' as ReporterRole,
    Timberyardemployee: 'TIMBERYARDEMPLOYEE' as ReporterRole,
    Mechanic: 'MECHANIC' as ReporterRole,
    Businesssales: 'BUSINESSSALES' as ReporterRole,
    Municipconsultant: 'MUNICIPCONSULTANT' as ReporterRole,
    Constructioncoordinator: 'CONSTRUCTIONCOORDINATOR' as ReporterRole,
    Controlcenter: 'CONTROLCENTER' as ReporterRole,
    Coordinatorspecialtask: 'COORDINATORSPECIALTASK' as ReporterRole,
    Other: 'OTHER' as ReporterRole
};

