import { NetArea } from '../../api/generated/v2/incident';
import { IncidentStatus } from '../../api/generated/v2/incident';

export class Device {
  public constructor(public id: string,
                     public technicalNo: string,
                     public longitude: number,
                     public latitude: number,
                     public street: string,
                     public houseNo: string,
                     public zipCode: string,
                     public city: string,
                     public cityDistrict: string,
                     public controlCabinetNo?: string,
                     public customerScope?: string,
                     public netArea?: NetArea,
                     public incidentStatus?: IncidentStatus) {
  }
}
