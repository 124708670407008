/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderType } from './orderType';


/**
 * Daten zur automatischen Auftragsanlage
 */
export interface AutomatedOrderCreationActionTO { 
    /**
     * Ausführender Dienstleister für den Auftrag (nicht für jede Auftragsart)
     */
    contractor?: string;
    /**
     * Hinweis für den ausführender Dienstleister (nicht für jede Auftragsart)
     */
    contractorNote?: string;
    /**
     * Emailadressen des ausführenden Dienstleisters (nicht für jede Auftragsart)
     */
    emailsTo?: Set<string>;
    orderType: OrderType;
    /**
     * Auftragsausführung benötigt bis
     */
    resolutionExpectedWithinDurationDays?: number;
}
export namespace AutomatedOrderCreationActionTO {
}


