import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { User } from 'app/shared/common/models/user';
import { loggedInUserSelector } from 'app/auth/state/auth.reducers';
import { loadUserSettingsRequest } from 'app/shared/state/shared.actions';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { DialogType } from '../../models/enums/dialog-type/dialog.type';
import { NavigationService } from '../../services/navigation.service';
import { NetAreaInfo, NetAreaUtils } from '../../services/netarea.utils';
import { ObjUtils } from '../../services/obj.utils';
import { UserInfoService } from '../../services/user.info.service';
import { UserRoleUtils } from '../../services/user.role.utils';
import { ResultModalDialogDataImpl } from '../result-modal/result-modal-dialog.data.impl';
import { ResultModalComponent } from '../result-modal/result-modal.component';
import { Spinner } from '../spinner-modal/spinner';
import { SystemUserSettings } from '../../models/systemUserSettings';
import { UserSettingsTO } from '../../../api/generated/v2/incident';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {
  public userSettingsForm = new UntypedFormGroup({
    userId: new UntypedFormControl({value: '', disabled: true}),
    username: new UntypedFormControl({value: '', disabled: true}),
    email: new UntypedFormControl({value: '', disabled: true}),
    firstname: new UntypedFormControl({value: '', disabled: true}),
    lastname: new UntypedFormControl({value: '', disabled: true}),
    roles: new UntypedFormControl({value: '', disabled: true}),
    primaryRole: new UntypedFormControl({value: '', disabled: true}),
    netArea: new UntypedFormControl(''),
    emailFavorites: new UntypedFormControl(''),
    emailFavoriteToAdd: new UntypedFormControl('', [Validators.email]),
  });

  public displayedColumns: string[] = [
    'email',
    'actions',
  ];
  public dataSource: MatTableDataSource<string> = new MatTableDataSource<string>();
  private paginator: MatPaginator;
  private sort: MatSort;
  @ViewChild(MatPaginator) set pagi(pagi: MatPaginator) {
    if (!pagi) {
      return;
    }
    this.paginator = pagi;
    this.dataSource.paginator = this.paginator;
    // this.dataSource.paginator._intl = this.matPaginatorIntl;
  }
  @ViewChild(MatSort) set srt(srt: MatSort) {
    if (!srt) {
      return;
    }
    this.sort = srt;
    this.dataSource.sort = this.sort;
  }

  public loggedInUser: User;

  public userSettings: SystemUserSettings;

  public UserRoleUtils = UserRoleUtils;

  public readonly dropdownDefaultSelection: string = 'bitte auswählen';
  public readonly netAreas: NetAreaInfo[] = NetAreaUtils.NET_AREAS;

  constructor(private logger: NGXLogger,
    private userInfoService: UserInfoService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private matPaginatorIntl: MatPaginatorIntl,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry) {
      this.iconRegistry.addSvgIcon('dcplus', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus.svg'));
  }

  ngOnInit(): void {
    this.store.select(loggedInUserSelector).subscribe((user: User) => {
      this.loggedInUser = user;
      if (!this.isValidUser()) {
        return;
      }
      this.loadUserSettings(UserRoleUtils.getUserId(this.loggedInUser), this.loggedInUser.internal);
    });
  }

  public onBackBtnClick(): void {
    this.navigationService.navigateToIncidentList();
  }

  public saveUserSettings(): void {
    Object.keys(this.userSettingsForm.controls).forEach(key => {
      this.userSettingsForm.controls[key].markAsTouched();
    });
    if (!this.isValidUser() || this.userSettingsForm.invalid) {
      return;
    }
    this.hydrate();
    const userSettingsObs: Observable<any> = this.userInfoService.saveUserSettings(this.loggedInUser.email, this.userSettings).pipe(share());
    new Spinner().spin(userSettingsObs, this.dialog, { message: 'Nutzereinstellungen speichern...' });
    userSettingsObs.subscribe((resp: HttpResponse<any>) => {
      this.openSaveResultDialog(200 === resp.status);
      this.store.dispatch(loadUserSettingsRequest({ userId: UserRoleUtils.getUserId(this.loggedInUser), internal: !!this.loggedInUser.internal }));
    }, (error: any) => {
      this.logger.log('error saving user settings=', error);
      this.openSaveResultDialog(false);
    });
  }

  public deleteUserSettings(): void {
    // currently not in use
    if (!this.isValidUser()) {
      return;
    }
    const userSettingsObs: Observable<any> = this.userInfoService.deleteUserSettings(UserRoleUtils.getUserId(this.loggedInUser));
    new Spinner().spin(userSettingsObs, this.dialog, { message: 'Nutzereinstellungen löschen...' });
    userSettingsObs.subscribe((resp: HttpResponse<any>) => {
      this.logger.trace('successfully deleted user settings');
    }, (error: any) => {
      this.logger.log('error deleting user settings=', error);
    });
  }

  public addEmailFavorite(): void {
    if (this.userSettingsForm.controls.emailFavoriteToAdd.invalid
      || ObjUtils.isNullOrUndefinedOrEmpty(this.userSettingsForm.value.emailFavoriteToAdd)) {
      return;
    }
    const emailToAdd: string = this.userSettingsForm.value.emailFavoriteToAdd;
    const currentEmailFavorites: string[]
      = ObjUtils.isEmptyArray(this.userSettingsForm.value.emailFavorites)? [] : this.userSettingsForm.value.emailFavorites;
    if (currentEmailFavorites.includes(emailToAdd)) {
      return;
    }
    currentEmailFavorites.push(emailToAdd);
    this.dataSource.data = currentEmailFavorites;
    this.patchEmailFavorites(currentEmailFavorites);
    this.userSettingsForm.patchValue({
      emailFavoriteToAdd: '',
    });
  }

  public removeEmailFavorite(email: string): void {
    const currentEmailFavorites: string[] = this.userSettingsForm.value.emailFavorites;
    if (ObjUtils.isEmptyArray(currentEmailFavorites)
      || ObjUtils.isNullOrUndefinedOrEmpty(email)) {
      return;
    }
    const index: number = currentEmailFavorites.findIndex(elem => email === elem);
    currentEmailFavorites.splice(index, 1);
    this.dataSource.data = currentEmailFavorites;
  }

  private loadUserSettings(userId: string, internal?: boolean): void {
    const userSettingsObs: Observable<any> = this.userInfoService.getSystemUserSettings(userId, !!internal).pipe(share());
    new Spinner().spin(userSettingsObs, this.dialog, { message: 'Lädt Nutzereinstellungen...' });
    userSettingsObs.subscribe((usrSttngs: UserSettingsTO) => {
      this.userSettings = usrSttngs;
      this.dataSource.data =
        ObjUtils.isNullOrUndefined(usrSttngs) || ObjUtils.isEmptyArray(usrSttngs.emailFavorites)?
          [] : usrSttngs.emailFavorites;
      this.patchForm(this.userSettings);
    }, (error: any) => {
        // case data could not be loaded
        this.logger.log('error loading user settings=', error);
        this.patchForm();
      }
    );
  }

  private patchForm(userSettings?: SystemUserSettings): void {
    const hasSettings: boolean = !ObjUtils.isNullOrUndefined(userSettings);
    this.userSettingsForm.patchValue({
      userId: this.loggedInUser.id,
      username: this.loggedInUser.username,
      email: this.loggedInUser.email,
      firstname: this.loggedInUser.firstname,
      lastname: this.loggedInUser.lastname,
      roles: !ObjUtils.isEmptyArray(this.loggedInUser.roles) && this.loggedInUser.roles.map(role => UserRoleUtils.getUserRoleLabel(role)),
      primaryRole: ObjUtils.isNullOrUndefined(this.loggedInUser.primaryRole) ? null : this.loggedInUser.primaryRole.label,
      netArea: !!hasSettings? userSettings.netArea : null,
      emailFavorites: !!hasSettings? userSettings.emailFavorites : null,
    });
  }

  private patchEmailFavorites(emailFavorites?: string[]): void {
    if (ObjUtils.isEmptyArray(emailFavorites)) {
      return;
    }
    this.userSettingsForm.patchValue({
      emailFavorites,
    });
  }

  private hydrate(): void {
    if (ObjUtils.isNullOrUndefined(this.userSettings)) {
      this.userSettings = new SystemUserSettings();
    }
    this.userSettings.netArea = this.userSettingsForm.value.netArea;
    this.userSettings.emailFavorites
      = ObjUtils.isEmptyArray(this.userSettingsForm.value.emailFavorites)? [] : this.userSettingsForm.value.emailFavorites;
  }

  private isValidUser(): boolean {
    return !ObjUtils.isNullOrUndefined(this.loggedInUser)
      && !ObjUtils.isNullOrUndefinedOrEmpty(this.loggedInUser.email)
      && !!UserRoleUtils.hasRoleOperator(this.loggedInUser);
  }

  private openSaveResultDialog(success: boolean): void {
    this.dialog.open(ResultModalComponent, {
      disableClose: true,
      data: ResultModalDialogDataImpl.getDefaultResultModalDialgData(`Nutzereinstellungen${!!success? ' ' : ' nicht '}gespeichert`,
      `Ihre Einstellungen wurden ${!!success? 'erfolgreich' : 'nicht'} gespeichert.`, !!success? DialogType.SUCCESS : DialogType.FAIL),
    });
  }

  get emailFavoriteToAdd(): AbstractControl {
    return this.userSettingsForm.get('emailFavoriteToAdd');
  }
}
