/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Typ des Symptoms
 */
export type SymptomType = 'LAMP_MISSING' | 'WRONG_LIGHTING_AREA' | 'LAMP_FLICKERS' | 'LAMP_HUMS' | 'ONE_LAMP_OFF' | 'SEVERAL_LAMPS_OFF' | 'LAMPGLASS_DAMAGED_MISSING' | 'MAST_DEFECT' | 'MASTDOOR_MISSING' | 'CABLE_DAMAGED' | 'LINE_DAMAGED' | 'SWITCH_STATION_DAMAGED' | 'LAMP_INGROWN' | 'OTHER';

export const SymptomType = {
    LampMissing: 'LAMP_MISSING' as SymptomType,
    WrongLightingArea: 'WRONG_LIGHTING_AREA' as SymptomType,
    LampFlickers: 'LAMP_FLICKERS' as SymptomType,
    LampHums: 'LAMP_HUMS' as SymptomType,
    OneLampOff: 'ONE_LAMP_OFF' as SymptomType,
    SeveralLampsOff: 'SEVERAL_LAMPS_OFF' as SymptomType,
    LampglassDamagedMissing: 'LAMPGLASS_DAMAGED_MISSING' as SymptomType,
    MastDefect: 'MAST_DEFECT' as SymptomType,
    MastdoorMissing: 'MASTDOOR_MISSING' as SymptomType,
    CableDamaged: 'CABLE_DAMAGED' as SymptomType,
    LineDamaged: 'LINE_DAMAGED' as SymptomType,
    SwitchStationDamaged: 'SWITCH_STATION_DAMAGED' as SymptomType,
    LampIngrown: 'LAMP_INGROWN' as SymptomType,
    Other: 'OTHER' as SymptomType
};

