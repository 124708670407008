/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Netzgebiet
 */
export type NetArea = 'NORTH' | 'WEST' | 'SOUTH' | 'MIDDLE' | 'NO_CODE' | 'TEST';

export const NetArea = {
    North: 'NORTH' as NetArea,
    West: 'WEST' as NetArea,
    South: 'SOUTH' as NetArea,
    Middle: 'MIDDLE' as NetArea,
    NoCode: 'NO_CODE' as NetArea,
    Test: 'TEST' as NetArea
};

