/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Art der beschriebenen Störung
 */
export type IssueType = 'CABLE_INCIDENT' | 'DEFAULT_INCIDENT' | 'ACCIDENT' | 'VANDALISM';

export const IssueType = {
    CableIncident: 'CABLE_INCIDENT' as IssueType,
    DefaultIncident: 'DEFAULT_INCIDENT' as IssueType,
    Accident: 'ACCIDENT' as IssueType,
    Vandalism: 'VANDALISM' as IssueType
};

