import { AuthMode } from 'app/shared/common/models/enums/auth-mode/auth-mode';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { ObjUtils } from 'app/shared/common/services/obj.utils';

export class AuthUrlUtils {

  public static getRedirectAbsoluteUrl(relativePath?: string): string {
    const protocol: string = window.location.protocol;
    const hostname: string = window.location.hostname;
    const port: string = window.location.port;
    const relPath: string = ObjUtils.isNullOrUndefinedOrEmpty(relativePath) ? '/auth/token' : relativePath;
    return `${protocol}//${hostname}${'localhost' === hostname ? `:${port}` : ''}${relPath}`;
  }

  public static getTokenAbsoluteUrl(): string {
    return `${this.getAuthPoolUrl()}${this.getTokenRelativeUrl()}`;
  }

  public static getTokenRelativeUrl(): string {
    return '/oauth2/token';
  }

  public static getLoginAbsoluteUrl(withParams: boolean, state?: string): string {
    return `${this.getAuthPoolUrl()}${this.getLoginRelativeUrl(withParams, state)}`;
  }

  public static getLogoutAbsoluteUrl(): string {
    return `${this.getAuthPoolUrl()}${this.getLogoutRelativeUrl()}`;
  }

  public static getLoginRelativeUrl(withParams: boolean, state?: string): string {
    return `/login${!!withParams ? `?${this.getLoginUrlQueryParams(state)}` : ''}`;
  }

  public static getLogoutRelativeUrl(): string {
    return `/logout?${this.getLogoutUrlQueryParams()}`;
  }

  public static getLoginUrlQueryParams(state?: string): string {
    const queryParams = new URLSearchParams();
    queryParams.set('client_id', AuthUrlUtils.getAuthClientId());
    queryParams.set('response_type', 'code');
    queryParams.set('redirect_uri', AuthUrlUtils.getRedirectAbsoluteUrl());
    const scopeParam = `scope=${AuthUrlUtils.getScopes().join('+')}`;
    if (!ObjUtils.isNullOrUndefinedOrEmpty(state)) {
      queryParams.set('state', state);
    }
    return `${queryParams.toString()}&${scopeParam}`;
  }

  public static getLogoutUrlQueryParams(): string {
    const queryParams = new URLSearchParams();
    queryParams.set('client_id', AuthUrlUtils.getAuthClientId());
    queryParams.set('logout_uri', AuthUrlUtils.getRedirectAbsoluteUrl('/auth/login'));
    return queryParams.toString();
  }

  public static getAuthClientId(): string {
    const env = EnvironmentService.getEnvironment();
    const authModeStr: string = env.authMode;
    switch (authModeStr) {
      case AuthMode.EXTERNAL_DEMO:
        return env.authDemoClientId;
      case AuthMode.EXTERNAL_MSAD:
        return env.authMsadClientId;
      default:
        return '';
    }
  }

  public static getAuthPoolUrl(): string {
    const env = EnvironmentService.getEnvironment();
    const authModeStr: string = env.authMode;
    switch (authModeStr) {
      case AuthMode.EXTERNAL_DEMO:
        return env.demoPoolUrl;
      case AuthMode.EXTERNAL_MSAD:
        return env.msadPoolUrl;
      default:
        return '';
    }
  }

  public static getScopes(): string[] {
    const env = EnvironmentService.getEnvironment();
    const authModeStr: string = env.authMode;
    switch (authModeStr) {
      case AuthMode.EXTERNAL_DEMO:
        return ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile', 'de.netzebw.technicaldata/reade'];
      case AuthMode.EXTERNAL_MSAD:
        return ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'];
      default:
        return [];
    }
  }
}
