import { Injectable } from '@angular/core';
import { UploadedDocumentListEntry } from '../../models/uploadedDocumentListEntry';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IncidentListEntry } from '../../../../incidents/models/incidentListEntry';
import { NGXLogger } from 'ngx-logger';
import { Order } from 'app/incidents/models/order';
import { IncdntService } from 'app/incidents/services/incdnt.service';
import { ControlCabinetTechnicalData, LightingPointTechnicalData, StateHistoryEntryTO } from '../../../api/generated/v2/incident';
import { Incident } from '../../../../incidents/models/incident';

@Injectable({ providedIn: 'root' })
export class DataHoldingService {
  private currentDocsSub = new Subject<UploadedDocumentListEntry[]>();
  private currentStateChangesSub = new Subject<
    StateHistoryEntryTO[]
  >();
  private currentTechnicalControlCabinetDataSub =
    new Subject<ControlCabinetTechnicalData>();
  private currentTechnicalLightPointDataSub =
    new Subject<LightingPointTechnicalData>();
  private incidentChildrenDataSub = new Subject<IncidentListEntry[]>();
  private currentDocs: UploadedDocumentListEntry[] = [];
  private currentStateChanges: StateHistoryEntryTO[] = [];
  private currentTechnicalControlCabinetData: ControlCabinetTechnicalData;
  private currentTechnicalLightPointData: LightingPointTechnicalData;
  private currentIncidentChildren: IncidentListEntry[];
  private incLoaded = false;
  private docsLoaded = false;
  private stateChangesLoaded = false;
  private technicalControlCabinetDataLoaded = false;
  private technicalLightPointDataLoaded = false;
  private incidentChildrenLoaded = false;
  // Every field down from here is used to maintain the app state and is already tested
  private currentIncidentSub: BehaviorSubject<Incident> = new BehaviorSubject(
    undefined
  );
  private currentIncident: Observable<Incident> =
    this.currentIncidentSub.asObservable();
  private currentIncidentOrdersSub: BehaviorSubject<Order[]> =
    new BehaviorSubject([]);
  private currentIncidentOrders: Observable<Order[]> =
    this.currentIncidentOrdersSub.asObservable();
  private currentOrderSub: BehaviorSubject<Order> = new BehaviorSubject(
    undefined
  );
  private currentOrder: Observable<Order> = this.currentOrderSub.asObservable();

  constructor(
    private logger: NGXLogger,
    private incidentService: IncdntService
  ) {}

  setCurrentInc(incident: Incident, isClear?: boolean): void {
    this.incLoaded = !isClear;
    this.currentIncidentSub.next(incident);
    this.logger.trace('current inc set=', incident);
  }

  // docs
  getCurrentDocs(): UploadedDocumentListEntry[] {
    return this.currentDocs;
  }

  setCurrentDocs(docs: UploadedDocumentListEntry[], isClear?: boolean): void {
    this.currentDocs = docs;
    this.docsLoaded = !isClear;
    this.currentDocsSub.next(docs);
    this.logger.trace('current docs set=', docs);
  }

  areDocsLoaded(): boolean {
    return this.docsLoaded;
  }

  // state changes
  getCurrentStateChanges(): StateHistoryEntryTO[] {
    return this.currentStateChanges;
  }

  setCurrentStateChanges(
    stateChanges: StateHistoryEntryTO[],
    isClear?: boolean
  ): void {
    this.currentStateChanges = stateChanges;
    this.stateChangesLoaded = !isClear;
    this.currentStateChangesSub.next(stateChanges);
    this.logger.trace('current state changes set=', stateChanges);
  }

  areStateChangesLoaded(): boolean {
    return this.stateChangesLoaded;
  }

  setCurrentTechnicalControlCabinetData(
    technicalControlCabinetData: ControlCabinetTechnicalData,
    isClear?: boolean
  ): void {
    this.currentTechnicalControlCabinetData = technicalControlCabinetData;
    this.technicalControlCabinetDataLoaded = !isClear;
    this.currentTechnicalControlCabinetDataSub.next(
      technicalControlCabinetData
    );
    this.logger.trace(
      'current technical control cabinet data set=',
      technicalControlCabinetData
    );
  }

  setCurrentTechnicalLightPointData(
    technicalLightPointData: LightingPointTechnicalData,
    isClear?: boolean
  ): void {
    this.currentTechnicalLightPointData = technicalLightPointData;
    this.technicalLightPointDataLoaded = !isClear;
    this.currentTechnicalLightPointDataSub.next(technicalLightPointData);
    this.logger.trace(
      'current technical light point data set=',
      technicalLightPointData
    );
  }

  setCurrentIncidentChildren(
    incidentChildren: IncidentListEntry[],
    isClear?: boolean
  ): void {
    this.currentIncidentChildren = incidentChildren;
    this.incidentChildrenLoaded = !isClear;
    this.incidentChildrenDataSub.next(incidentChildren);
    this.logger.trace('current incident children set=', incidentChildren);
  }

  areIncidentChildrenLoaded(): boolean {
    return this.incidentChildrenLoaded;
  }

  // Every function down from here is used to maintain the app state and is already tested
  setCurrentIncident(incident: Incident) {
    this.currentIncidentSub.next(incident);
    this.loadIncidentOrders(incident.uuid);
  }

  getCurrentIncident(): Observable<Incident> {
    return this.currentIncident;
  }

  loadIncidentOrders(incidentUuid: string): void {
    this.incidentService
      .getOrdersByIncidentId(incidentUuid)
      .subscribe((orders: Order[]) => {
        this.currentIncidentOrdersSub.next(orders);
      });
  }

  addOrderFeedback(incidentUuid: string) {
    this.loadIncidentOrders(incidentUuid);
  }

  setCurrentOrder(order: Order) {
    this.currentOrderSub.next(order);
  }

  validateOrderFeedback(incidentUuid: string) {
    this.loadIncidentOrders(incidentUuid);
  }

  getCurrentOrder(): Observable<Order> {
    return this.currentOrder;
  }

  getCurrentIncidentOrders(): Observable<Order[]> {
    return this.currentIncidentOrders;
  }
}
