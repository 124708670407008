import { ViewMode } from '../models/enums/view-mode/view-mode';
import { ObjUtils } from './obj.utils';

export class ViewModeUtils {

  public static isViewModeOwn(viewMode?: ViewMode): boolean {
    return !ObjUtils.isNullOrUndefined(viewMode)
      && ViewMode.OWN === viewMode;
  }
}
