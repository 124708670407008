import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SystemUser } from '../common/models/systemUser';
import { SystemUserSettings } from '../common/models/systemUserSettings';
import { NetAreaData } from '../common/services/netarea.data';
import { loadNetAreaSuccessfulResponse, loadSystemUsersSuccessfulResponse, loadUserSettingsSuccessfulResponse } from './shared.actions';
import { NetArea } from '../api/generated/v2/incident';

export interface SharedState {
  netAreas: { [key: string]: NetArea };
  systemUsers: SystemUser[];
  userSettings: SystemUserSettings;
}

export const initialState: SharedState = {
  netAreas: NetAreaData.NET_AREAS,
  systemUsers: [],
  userSettings: undefined,
};

const getSharedFeatureState = createFeatureSelector<SharedState>('shared');
export const getNetAreasSelector = createSelector(
  getSharedFeatureState,
  (state: SharedState) => state.netAreas
);
export const getNetAreaSelector = createSelector(
  getSharedFeatureState,
  (state: SharedState, props: { zipCode: string }) => {
    return Object.entries(state.netAreas).filter(([zipCode, _]): boolean => {
      return zipCode === props.zipCode;
    });
  },
);
export const systemUsersSelector = createSelector(
  getSharedFeatureState,
  (state: SharedState) => state.systemUsers
);
export const userSettingsSelector = createSelector(
  getSharedFeatureState,
  (state: SharedState) => state.userSettings
);

const reducer = createReducer<SharedState>(
  initialState,
  on(loadNetAreaSuccessfulResponse, (state, action): SharedState => {
    return {
      ...state,
      netAreas: action.netAreas,
    };
  }),
  on(loadSystemUsersSuccessfulResponse, (state, action): SharedState => {
    return {
      ...state,
      systemUsers: action.systemUsers,
    };
  }),
  on(loadUserSettingsSuccessfulResponse, (state, action): SharedState => {
    return {
      ...state,
      userSettings: action.userSettings,
    };
  }),
);

export function sharedReducer(state: SharedState | undefined, action: Action) {
  return reducer(state, action);
}
