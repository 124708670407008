/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ArchiveLuxdataResponseTO } from '../model/archiveLuxdataResponseTO';
// @ts-ignore
import { FeatureCollectionV2 } from '../model/featureCollectionV2';
// @ts-ignore
import { GeoLocationResponseV2ControlCabinetLocationDataV2 } from '../model/geoLocationResponseV2ControlCabinetLocationDataV2';
// @ts-ignore
import { GeoLocationResponseV2LightingPointLocationDataV2 } from '../model/geoLocationResponseV2LightingPointLocationDataV2';
// @ts-ignore
import { LuxdataArchiveTO } from '../model/luxdataArchiveTO';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LuxdataService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Returns location data of control cabinets.
     * Returns location data of all control cabinets in Luxdata filtered by customer scope and control cabinet number. This is used for rendering control cabinets on the map. The response uses our own, proprietary format.
     * @param customerScope 
     * @param cabinetNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2ControlCabinetLocationDataV2>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2ControlCabinetLocationDataV2>>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2ControlCabinetLocationDataV2>>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getControlCabinetGeoLocationsV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cabinetNo !== undefined && cabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cabinetNo, 'cabinetNo');
        }
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/controlcabinets/search`;
        return this.httpClient.request<GeoLocationResponseV2ControlCabinetLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns lighting points in GeoJSON format
     * Returns all lighting points which match the given filters. The response is in GeoJSON format for rendering on the Mapbox map.
     * @param customerScope An optional customer scope to be used as filter
     * @param lightingNo A valid lighting point number or parts of it
     * @param maxFeatures max. features to be loaded; if not provided, a maximum of 10000 features is loaded
     * @param neLatitude Latitude of the north-east corner of the bounding box
     * @param neLongitude Longitude of the north-east corner of the bounding box
     * @param startIndex Index for pagination in combination with maxFeatures
     * @param swLatitude Latitude of the south-west corner of the bounding box
     * @param swLongitude Longitude of the south-west corner of the bounding box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FeatureCollectionV2>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FeatureCollectionV2>>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FeatureCollectionV2>>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (maxFeatures !== undefined && maxFeatures !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxFeatures, 'maxFeatures');
        }
        if (neLatitude !== undefined && neLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLatitude, 'neLatitude');
        }
        if (neLongitude !== undefined && neLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLongitude, 'neLongitude');
        }
        if (startIndex !== undefined && startIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIndex, 'startIndex');
        }
        if (swLatitude !== undefined && swLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLatitude, 'swLatitude');
        }
        if (swLongitude !== undefined && swLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLongitude, 'swLongitude');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/geo-json`;
        return this.httpClient.request<FeatureCollectionV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns location data for lighting points within the specified bounds.
     * Returns the location data of all lighting points which lie in the specified bounding box. The bounding box has to be specified by the SW and the NE corner. The response uses our own, proprietary format.
     * @param ceLatitude Deprecated. The latitude of the center
     * @param ceLongitude Deprecated. The longitude of the center
     * @param customerScope The customer scope
     * @param lightingNo The number of the lighting point
     * @param neLatitude The latitude of the North-East corner
     * @param neLongitude The longitude of the North-East corner
     * @param swLatitude The latitude of the South-West corner
     * @param swLongitude The longitude of the South-West corner
     * @param zoom Deprecated. The zoom level
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2LightingPointLocationDataV2>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (ceLatitude !== undefined && ceLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ceLatitude, 'ceLatitude');
        }
        if (ceLongitude !== undefined && ceLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ceLongitude, 'ceLongitude');
        }
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (neLatitude !== undefined && neLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLatitude, 'neLatitude');
        }
        if (neLongitude !== undefined && neLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLongitude, 'neLongitude');
        }
        if (swLatitude !== undefined && swLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLatitude, 'swLatitude');
        }
        if (swLongitude !== undefined && swLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLongitude, 'swLongitude');
        }
        if (zoom !== undefined && zoom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zoom, 'zoom');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/by-bounds`;
        return this.httpClient.request<GeoLocationResponseV2LightingPointLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns location data of lighting points.
     * Returns location data of all lighting points in Luxdata filtered by customer scope and lighting point number. This is used for rendering lighting points on the map. The response uses our own,proprietary format.
     * @param customerScope 
     * @param lightingNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2LightingPointLocationDataV2>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getLightingPointsGeoLocationsV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/search`;
        return this.httpClient.request<GeoLocationResponseV2LightingPointLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers an incident to Luxdata.
     * Allows the transfer of the given incident to Luxdata. Either a new fault is created in Luxdata if it is the first transfer or otherwise the existing fault is updated.
     * @param id Störung UUID
     * @param luxdataArchiveTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ArchiveLuxdataResponseTO>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ArchiveLuxdataResponseTO>>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ArchiveLuxdataResponseTO>>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sendIncidentToLuxdataV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/luxdata/transfer`;
        return this.httpClient.request<ArchiveLuxdataResponseTO>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: luxdataArchiveTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
