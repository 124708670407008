import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { ConfirmModalDialogData } from './confirm-modal-dialog.data';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('qm', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-mark.svg'));
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public onRevokeBtnClick(): void {
    this.close(DialogResult.NO);
  }

  public onConfirmBtnClick(): void {
    this.close(DialogResult.YES);
  }

  private close(res: DialogResult): void {
    this.dialogRef.close(res);
  }
}
