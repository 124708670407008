/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderType } from './orderType';


/**
 * TO for creating a contractor order by mail
 */
export interface CreateContractorMailOrderInfo { 
    orderType: OrderType;
    wishResolutionDate: string;
    contractor: string;
    contractorNote?: string;
    emailsTo: Array<string>;
    emailsCc?: Array<string>;
    subject?: string;
    attachments?: Array<string>;
}
export namespace CreateContractorMailOrderInfo {
}


