/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LightingPointLocationDataV2 } from './lightingPointLocationDataV2';


export interface GeoLocationResponseV2LightingPointLocationDataV2 { 
    totalCount?: number;
    centerLatitude?: number;
    centerLongitude?: number;
    customerScope?: string;
    data?: Array<LightingPointLocationDataV2>;
}

