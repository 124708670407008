/**
 * DiCon Incident Service API
 * Folgende API wird dazu verwendet um Störungen im #LightOn System zu bearbeiten.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SendMailNotificationResponseTO { 
    /**
     * Zeitpunkt an dem die Störung per Mail übertragen wurde.
     */
    sendMailNotificationDateTimeUtc?: string;
}

