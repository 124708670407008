<div style="height: 100%; display: flex; flex-direction: column;">

  <mat-sidenav-container class="dicon-sidenav-container">
    <mat-sidenav #sidenav mode="side" opened class="dicon-sidenav"
                 fixedInViewport="true" fixedTopGap="0"
                 fixedBottomGap="0" *ngIf="!!isAuthenticated">
      <div class="col dicon-sidenav-wrap">
        <div class="col dicon-sidenav-top">
        </div>
        <div class="col dicon-sidenav-middle">
          <div *ngIf="IsSnowVisible()">
          <style>
          .snowflakes {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            margin-top: -300px;


            pointer-events:none;
          }
          .snowflake {
            color: #fff;
            font-size: 1em;
            font-family: Arial;
            text-shadow: 0 0 1px #000;
            flex: 1;
            position: relative;
          }
          .snowflake,.snowflake .inner{animation-iteration-count:infinite;animation-play-state:running}@keyframes snowflakes-fall{0%{transform:translateY(0)}100%{transform:translateY(110vh)}}@keyframes snowflakes-shake{0%,100%{transform:translateX(0)}50%{transform:translateX(80px)}}.snowflake{position:relative;top:-10%;z-index:9999;-webkit-user-select:none;user-select:none;cursor:default;animation-name:snowflakes-shake;animation-duration:3s;animation-timing-function:ease-in-out}.snowflake .inner{animation-duration:10s;animation-name:snowflakes-fall;animation-timing-function:linear}.snowflake:nth-of-type(0){left:1%;animation-delay:0s}.snowflake:nth-of-type(0) .inner{animation-delay:0s}.snowflake:first-of-type{left:10%;animation-delay:1s}.snowflake:first-of-type .inner,.snowflake:nth-of-type(8) .inner{animation-delay:1s}.snowflake:nth-of-type(2){left:20%;animation-delay:.5s}.snowflake:nth-of-type(2) .inner,.snowflake:nth-of-type(6) .inner{animation-delay:6s}.snowflake:nth-of-type(3){left:30%;animation-delay:2s}.snowflake:nth-of-type(11) .inner,.snowflake:nth-of-type(3) .inner{animation-delay:4s}.snowflake:nth-of-type(4){left:40%;animation-delay:2s}.snowflake:nth-of-type(10) .inner,.snowflake:nth-of-type(4) .inner{animation-delay:2s}.snowflake:nth-of-type(5){left:50%;animation-delay:3s}.snowflake:nth-of-type(5) .inner{animation-delay:8s}.snowflake:nth-of-type(6){left:60%;animation-delay:2s}.snowflake:nth-of-type(7){left:70%;animation-delay:1s}.snowflake:nth-of-type(7) .inner{animation-delay:2.5s}.snowflake:nth-of-type(8){left:80%;animation-delay:0s}.snowflake:nth-of-type(9){left:90%;animation-delay:1.5s}.snowflake:nth-of-type(9) .inner{animation-delay:3s}.snowflake:nth-of-type(10){left:25%;animation-delay:0s}.snowflake:nth-of-type(11){left:65%;animation-delay:2.5s}
          </style>
            <div class="snowflakes" aria-hidden="true" >
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
              <div class="snowflake">
                <div class="inner">❅</div>
              </div>
            </div>
          </div>

          <!-- new sidenav -->
          <button
            id="netOverviewBtn"
            mat-icon-button
            class="sidenavbtn"
            matTooltip="Netzübersicht"
            matTooltipShowDelay="1000"
            matTooltipPosition="above"
            routerLink="/incident/list"
            routerLinkActive="active"
            [ngClass]="{ 'navigated' : getCurrentRoute() === '/incident/list' }"
          >
            <mat-icon
              class="mat-18"
              svgIcon="group77"></mat-icon>
          </button>
          <button
            id="myTicketsBtn"
            mat-icon-button
            class="sidenavbtn"
            matTooltip="Meine Störungen"
            matTooltipShowDelay="1000"
            matTooltipPosition="above"
            [routerLink]="['/incident/list']" [queryParams]="{viewMode: 'OWN'}"
            [ngClass]="{ 'navigated' : getCurrentRoute() === '/incident/list?viewMode=OWN' }"
            *ngIf="UserRoleUtils.hasRoleOperator(loggedInUser)"
          >
            <mat-icon
              class="mat-18"
              svgIcon="group78"></mat-icon>
          </button>
          <button
            mat-icon-button
            class="sidenavbtn"
            matTooltip="Archivierte Störungen"
            matTooltipShowDelay="1000"
            matTooltipPosition="above"
            [routerLink]="['/incident/list']" [queryParams]="{viewMode: 'ARCHIVED'}"
            [ngClass]="{ 'navigated' : getCurrentRoute() === '/incident/list?viewMode=ARCHIVED' }"
          >
            <mat-icon class="mat-18" svgIcon="archive"></mat-icon>
          </button>
          <button
            mat-icon-button
            class="sidenavbtn"
            matTooltip="Kundeneinstellungen"
            matTooltipShowDelay="1000"
            matTooltipPosition="above"
            routerLink="/client-settings"
            routerLinkActive="active"
            [ngClass]="{ 'navigated' : getCurrentRoute() === '/client-settings' }"
          >
            <mat-icon class="mat-18">settings</mat-icon>
          </button>
        </div>
        <div class="col dicon-sidenav-bottom">
          <div class="col dicon-sidenav-bottom-inner">
            <button
              id="infoBtn"
              mat-icon-button
              class="sidenavbtn"
              matTooltip="Version Info"
              matTooltipShowDelay="1000"
              matTooltipPosition="above"
              style="margin: 0px;"
              [matMenuTriggerFor]="infoMenu"
              [ngClass]="{ 'navigated' : getCurrentRoute() === '/shared/releasenotes'}"
            >
              <mat-icon class="mat-18">information_outline</mat-icon>
            </button>
            <mat-menu #infoMenu class="materialmenu">
              <button mat-menu-item
                class="menubtn"
                matTooltip="Version Info"
                matTooltipShowDelay="1000"
                matTooltipPosition="above"
                (click)="openInfo()"
              >
                <mat-icon>info</mat-icon>
                <span i18n="@@button.version.info">Version Info</span>
              </button>
              <button mat-menu-item
                class="menubtn"
                matTooltip="Release Notes"
                matTooltipShowDelay="1000"
                matTooltipPosition="above"
                routerLink="/shared/releasenotes"
                routerLinkActive="active">
                <mat-icon>monitor</mat-icon>
                <span i18n="@@button.release.notes">Release Notes</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="dicon-sidenav-content">
      <div *ngIf="environment" class="environment-banner">
        <div class="banner-content">
          <h1>Environment: {{ environment }}</h1>
          <div>Commit hash: {{ COMMIT_HASH }}</div>
          <div>Build ID: {{ BUILD_ID }}</div>
        </div>
      </div>

      <div id="header" class="header-container">




        <div class="header-logo">
          <a routerLink="/incident/list" routerLinkActive="active">
            <mat-icon svgIcon="netze-logo"></mat-icon>
            <h1>DiCon</h1>
          </a>
          <div class="header-logo-separator"></div>
          <span>Störungsmanagement Straßenbeleuchtung</span>
        </div>

        <app-user-avatar *ngIf="!!isAuthenticated" [user]="loggedInUser" (logout)="logout()"></app-user-avatar>
      </div>

      <div id="content">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

  <div id="footer" class="footer-container">
    <footer class="nd-footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5">
            <div class="nd-footer__links">
              <a href="https://www.netze-bw.de" target="_blank" class="mb-3 mb-lg-0">
                <i class="nd-ico-arrow-left mr-2"></i>Zur Netze BW Website</a>
              <a href="https://www.netze-bw.de/datenschutz" target="_blank" class="mb-3 mb-lg-0">Datenschutz</a>
              <a href="https://www.netze-bw.de/impressum" target="_blank">Impressum</a>
            </div>
          </div>
          <div class="col-12 col-lg-2 d-flex justify-content-center">
            <a [href]="'mailto:' + supportEmail">
              <button class="nd-footer__feedback js-modal-trigger" data-modal-target="feedback-modal">
                <i class="nd-ico-thumb-up mr-3"></i>Feedback
                <span class="d-inline d-md-none d-xxl-inline">zum Portal</span> geben
              </button>
            </a>
          </div>
          <div class="col-12 col-lg-5 nd-footer__copyright">
            <button class="nd-footer__feedback js-modal-trigger" data-modal-target="feedback-modal"
              *ngIf="!isAuthenticated" (click)="openInfo()">
              <i class="nd-ico-info mr-3"></i>
            </button>
            Ⓒ Netze BW GmbH {{ copyrightYear }}. Alle Rechte vorbehalten
          </div>
        </div>
      </div>
    </footer>
  </div>

</div>
