/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderType } from './orderType';


/**
 * Data for automated creation and assignment of orders
 */
export interface AutomatedOrderCreation { 
    /**
     * The identifier for the contractor
     */
    contractor?: string;
    /**
     * Additional notes or details about the contractor
     */
    contractorNote?: string;
    /**
     * List of email addresses to which the order details should be sent
     */
    emailsTo?: Array<string>;
    orderType?: OrderType;
    /**
     * Expected resolution time for the order in days
     */
    resolutionExpectedWithinDurationDays?: number;
}
export namespace AutomatedOrderCreation {
}


