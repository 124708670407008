<div class="cls-div" *ngIf="!!data.dlt" >
    <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
        <mat-icon style="margin: 0px;">close</mat-icon>
    </button>
</div>
<div class="div-stl" style="width: 400px;">
    <mat-icon id="icon" svgIcon="bin" style="height: 50px; width: 50px;" *ngIf="data.dlt"></mat-icon>
</div>
<h1 id="message" mat-dialog-title>{{data.message}}</h1>
<div mat-dialog-content>
    <div id="hint" class="information">
        {{data.hint}}
    </div>
</div>
<div *ngIf="data.dlt" mat-dialog-actions class="div-stl" style="padding-bottom: 30px; width: 400px;">
    <button id="btn1" *ngIf="data.button" class="nd-btn nd-btn-outline btn-fnt btn-spc" style="width: 160px;" mat-button (click)="onClsBtnClick()"> {{data.button}} </button>
    <button id="btn2" *ngIf="data.button2" class="nd-btn nd-btn-main btn-fnt btn-spc" style="width: 160px;" mat-button (click)="onConfirmBtnClick()"> {{data.button2}} </button>
</div>
