/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * DiCon status groups
 */
export type StatusGroup = 'IN_PROGRESS' | 'COMPLETED';

export const StatusGroup = {
    InProgress: 'IN_PROGRESS' as StatusGroup,
    Completed: 'COMPLETED' as StatusGroup
};

