import { UserRole } from 'app/shared/common/models/enums/user-role/user.role';
import { UserRoleInfo } from 'app/shared/common/services/user.role.utils';
import { TokenData } from './tokenData';

export class User {
  id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  primaryRole?: UserRoleInfo;
  roles?: UserRole[];
  email?: string;
  tokenData?: TokenData;
  internal?: boolean;
}
