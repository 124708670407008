/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentRelationType } from './incidentRelationType';
import { IncidentStatus } from './incidentStatus';
import { Priority } from './priority';
import { NetArea } from './netArea';
import { AssignedParty } from './assignedParty';


/**
 * Basis Daten der Störung für Listenansicht
 */
export interface IncidentListItemTO1 { 
    /**
     * UUID der Störung
     */
    uuid?: string;
    /**
     * Beinhaltet ein Identifikator des Kunden, dem die Leuchtstelle zugeordnet ist. I.d.R. ein amtlicher Gemeindeschlüssel (AGS).
     */
    customerScope: string;
    /**
     * Ort der Störungsmeldung
     */
    city: string;
    /**
     * Straße der Störungsmeldung
     */
    street: string;
    incidentStatus: IncidentStatus;
    priority: Priority;
    priorityDueDateTime?: string;
    reportingTimestamp: string;
    /**
     * Beschwerdeflag
     */
    hasComplaint?: boolean;
    netArea?: NetArea;
    /**
     * Leucht- oder Schaltstellennummer der Störung
     */
    technicalId?: string;
    /**
     * Zugewiesener Bearbeiter der Störungsmeldung
     */
    assignee?: string;
    assignedParty?: AssignedParty;
    relationType?: IncidentRelationType;
    /**
     * Verweis auf die Parent Störung, falls es sich um ein Duplikat handelt
     */
    parentId?: string;
    orderDueDateTime?: string;
    completedAtDateTimeUtc?: string;
    /**
     * Die Email-Adresse des Anwenders, der den Incident approved oder rejected hat
     */
    approvalDecisionByEmail?: string;
    /**
     * Der Name des Anwenders, der den Incident approved oder rejected hat
     */
    approvalDecisionByName?: string;
}
export namespace IncidentListItemTO1 {
}


