/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApprovalState } from './approvalState';


/**
 * Object for changing the approval state of an Incident in DiCon
 */
export interface IncidentApprovalStateTOV2 { 
    approvalState: ApprovalState;
}
export namespace IncidentApprovalStateTOV2 {
}


