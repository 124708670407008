import { ObjUtils } from './obj.utils';

export class DateUtils {

    public static readonly TIME_MIDNIGHT: string = '00:00';

    public static formatTimestampSt(timestamp: Date): string {
      if (ObjUtils.isNullOrUndefined(timestamp)) {
        return '';
      }
      try {
        const dateTimeFormat = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        const [
          { value: day },
          ,
          { value: month },
          ,
          { value: year },
          ,
          { value: hour },
          ,
          { value: minute }
        ] = dateTimeFormat.formatToParts(timestamp);
        return `${day}.${month}.${year}   ${hour}:${minute}`;
      } catch (error) {
        console.error(`error formatting date=[${timestamp}].`);
        return '';
      }
    }

    public static formatTimestampLabel(date: Date, time: string): string {
      try {
        const dateTimeFormat = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [
          { value: day },
          ,
          { value: month },
          ,
          { value: year }
        ] = dateTimeFormat.formatToParts(date);
        return `${day}.${month}.${year}   ${time} Uhr`;
      } catch (error) {
        console.error(`error formatting date=[${date}].`);
        return '';
      }
    }

    public static formatTimestampLabelDeStandard(date: Date): string {
      const options: any = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
      const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('de', options);
      return `${dateTimeFormat.format(date)} Uhr`;
    }

    public static formatDateLabelDeStandard(date: Date): string {
      const options: any = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('de', options);
      return dateTimeFormat.format(date);
    }

    public static getTimestampAsStringForService(date: Date): string {
      return ObjUtils.isNullOrUndefined(date) ? '' :  DateUtils.getTimestampAsString(date, DateUtils.getHoursAndMinutesString(date));
    }

    public static getTimestampAsString(date: Date, time: string): string {
      const timestamp: Date = DateUtils.getTimestamp(date, time);
      try {
        const dateTimeFormat = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        const [
          { value: day },
          ,
          { value: month },
          ,
          { value: year },
          ,
          { value: hour },
          ,
          { value: minute }
        ] = dateTimeFormat .formatToParts(timestamp);
        const dtSt = `${year}-${month}-${day}T${hour}:${minute}:00`;
        console.error('timestamp: ', dtSt);
        return dtSt;
      } catch (error) {
        console.error(`error formatting date=[${timestamp}].`);
        return '';
      }
    }

    public static getTimestamp(date: Date, time: string): Date {
      if (ObjUtils.isNullOrUndefined(date)) {
        date = new Date();
      }
      if (ObjUtils.isNullOrUndefinedOrEmpty(time)) {
        time = this.TIME_MIDNIGHT;
      }
      // fix for date-moment objects
      date = new Date(date);
      date.setHours(parseInt(time.substring(0, 2)));
      date.setMinutes(parseInt(time.substring(3, time.length)));
      return date;
    }

    public static toTimestampDate(timestampSt: string): Date {
      const date: Date = new Date(timestampSt);
      return DateUtils.isValidDate(date) ? date : null;
    }

    public static toClientLocalDateTime(dt: Date): Date {
      return ObjUtils.isNullOrUndefined(dt) ? new Date()
          : DateUtils.addMinutes(dt, dt.getTimezoneOffset() * -1);
    }

    public static toUTCTime(dt: Date): Date {
      return ObjUtils.isNullOrUndefined(dt) ? new Date()
          : DateUtils.addMinutes(dt, dt.getTimezoneOffset());
    }

    public static getDateSortString(tmstmp: Date): string {
      return ObjUtils.isNullOrUndefined(tmstmp) ? '' : tmstmp.toISOString();
    }

    public static addHours(dt: Date, hours: number): Date {
      if (ObjUtils.isNullOrUndefined(dt)) {
        return new Date();
      }
      if (ObjUtils.isNullOrUndefinedOrZero(hours)) {
        return new Date(dt);
      }
      return new Date(dt.getTime() + (1000 * 60 * 60 * hours));
    }

    public static addMinutes(dt: Date, mins: number): Date {
      if (ObjUtils.isNullOrUndefined(dt)) {
        return new Date();
      }
      if (ObjUtils.isNullOrUndefinedOrZero(mins)) {
        return new Date(dt);
      }
      return new Date(dt.getTime() + (1000 * 60 * mins));
    }

    public static setStartOfDay(dt: Date): Date {
      if (ObjUtils.isNullOrUndefined(dt)) {
        return;
      }
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      dt.setMilliseconds(0);
      return dt;
    }

    public static getHoursAndMinutesString(dt: Date): string {
      return `${DateUtils.getHoursString(dt)}:${DateUtils.getMinutesString(dt)}`;
    }

    public static getHoursString(dt: Date): string {
      return dt.getHours().toString().padStart(2, '0');
    }

    public static getMinutesString(dt: Date): string {
      return dt.getMinutes().toString().padStart(2, '0');
    }

    public static isAfterOrSameDate(date0: Date, date1: Date): boolean {
      return date0 >= date1;
    }

    public static isAfterOrSameTime(time0: string, time1: string): boolean {
      return Date.parse(`01/01/2020 ${time0}`) >= Date.parse(`01/01/2020 ${time1}`);
    }

    private static isValidDate(obj: any): boolean {
      return !isNaN(obj) && obj instanceof Date;
    }
}
