import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjUtils } from '../../services/obj.utils';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { DocumentFilenameChangeModalData } from './document-filename-change-modal.data';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-document-filename-change-modal',
  templateUrl: './document-filename-change-modal.component.html',
  styleUrls: ['./document-filename-change-modal.component.scss']
})
export class DocumentFilenameChangeModalComponent {
  public fileInfoForm: UntypedFormGroup = new UntypedFormGroup({
    newFilename: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
  });

  public filenameExists = false;

  constructor(
    public dialogRef: MatDialogRef<DocumentFilenameChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentFilenameChangeModalData,
    sanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry,
    private logger: NGXLogger) {
    iconRegistry.addSvgIcon('pen', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pen.svg'));
    this.fileInfoForm.patchValue({
      newFilename: data.newFilename,
    });
    this.determineFilenameExists();
  }

  onSaveBtnClick(): void {
    this.determineFilenameExists();
    if (!this.fileInfoForm.valid || !!this.filenameExists) {
      return;
    }
    this.close(DialogResult.MODIFY);
  }

  onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public determineFilenameExists(): void {
    const newFilename = this.getNewFilename().toLowerCase();

    if (!ObjUtils.isEmptyArray(this.data.existingFilenames)) {
      const existingFilenames = this.data.existingFilenames.map(existingFilename => existingFilename.toLowerCase());

      this.filenameExists = existingFilenames.includes(newFilename);
    }
    this.setValidationErrors();
  }

  private setValidationErrors(): void {
    let errors: ValidationErrors = this.fileInfoForm.controls.newFilename.errors;
    this.logger.trace('errors before=', this.fileInfoForm.controls.newFilename.errors);

    if (!!this.filenameExists) {
      errors = { incorrect: true };
    }
    this.fileInfoForm.controls.newFilename.setErrors(errors);
    this.logger.trace('errors after=', this.fileInfoForm.controls.newFilename.errors);
  }

  private getNewFilename(): string {
    return this.fileInfoForm.value.newFilename;
  }

  close(res: DialogResult): void {
    this.data.dialogResult = res;
    this.data.newFilename = this.getNewFilename();
    this.dialogRef.close(this.data);
  }
}
