/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcessingState } from './processingState';


/**
 * Antwort für den Fall, dass es bereits eine Störung zu der gegebenen Technical Id gibt.
 */
export interface DuplicateResponseTO { 
    /**
     * Leucht- oder Schaltstellennummer der Störung
     */
    technicalId?: string;
    reportingTimestamp: string;
    processingState?: ProcessingState;
    /**
     * UUID der Parent-Störung, welcher die angelegte Störung zugeordnet wurde.
     */
    parentId?: string;
    /**
     * Die Priorität des Parents wurde gemäß der Prio des Childs angepasst.
     */
    parentPriorityChanged?: boolean;
}
export namespace DuplicateResponseTO {
}


