import { WorkMode } from '../models/enums/work-mode/work.mode';
import { ObjUtils } from './obj.utils';

export class WorkModeUtils {

  public static isWorkModeCreate(workMode?: WorkMode): boolean {
    return !ObjUtils.isNullOrUndefined(workMode)
      && WorkMode.CREATE === workMode;
  }

  public static isWorkModeEdit(workMode?: WorkMode): boolean {
    return !ObjUtils.isNullOrUndefined(workMode)
      && WorkMode.EDIT === workMode;
  }
}
