import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { User } from 'app/shared/common/models/user';
import { loggedInUserSelector } from 'app/auth/state/auth.reducers';
import { NGXLogger } from 'ngx-logger';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { DateUtils } from '../../services/date.utils';
import { EnvironmentService } from '../../services/env.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent {

  public readonly envNameLbl: string = 'Environment Name';
  public readonly buildIdLbl: string = 'Build Id';
  public readonly buildNumberLbl: string = 'Build Number';
  public readonly sourceBranchNameLbl: string = 'Source Branch Name';
  public readonly sourceVersionLbl: string = 'Source Version';
  public readonly requestedForLbl: string = 'Requested For';
  public readonly pipelineStartTimeLbl: string = 'Pipeline Start Time';
  public readonly userAgentLbl: string = 'User Agent';
  public readonly currentTimestampLbl: string = 'Current Timestamp';
  public readonly loggedInUserLbl: string = 'Logged-In User';
  public readonly rolesLbl: string = 'Roles';

  public supportEmail: string = EnvironmentService.getEnvironment().supportEmail;
  private versionInfoStr: string = '';

  public infoForm: UntypedFormGroup = new UntypedFormGroup({
    envName: new UntypedFormControl(''),
    buildId: new UntypedFormControl(''),
    buildNumber: new UntypedFormControl(''),
    sourceBranchName: new UntypedFormControl(''),
    sourceVersion: new UntypedFormControl(''),
    requestedFor: new UntypedFormControl(''),
    pipelineStartTime: new UntypedFormControl(''),
    userAgent: new UntypedFormControl(''),
    currentTimestamp: new UntypedFormControl(''),
    loggedInUser: new UntypedFormControl(''),
    roles: new UntypedFormControl(''),
  });

  constructor(public dialogRef: MatDialogRef<VersionInfoComponent>,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private store: Store<AppState>) {
      iconRegistry.addSvgIcon('streetlight', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/streetlight.svg'));
    this.patchForm();
  }

  public onClsBtnClick(): void {
    this.close(DialogResult.CLOSED);
  }

  public getVersionInfoString(): string {
    if (!!this.versionInfoStr) {
      return this.versionInfoStr;
    }
    this.versionInfoStr += `Version Info: `
    this.versionInfoStr += `\n${this.envNameLbl}: ${this.infoForm.value.envName}`;
    this.versionInfoStr += `\n${this.buildIdLbl}: ${this.infoForm.value.buildId}`;
    this.versionInfoStr += `\n${this.buildNumberLbl}: ${this.infoForm.value.buildNumber}`;
    this.versionInfoStr += `\n${this.sourceBranchNameLbl}: ${this.infoForm.value.sourceBranchName}`;
    this.versionInfoStr += `\n${this.sourceVersionLbl}: ${this.infoForm.value.sourceVersion}`;
    this.versionInfoStr += `\n${this.requestedForLbl}: ${this.infoForm.value.requestedFor}`;
    this.versionInfoStr += `\n${this.pipelineStartTimeLbl}: ${this.infoForm.value.pipelineStartTime}`;
    this.versionInfoStr += `\n${this.userAgentLbl}: ${this.infoForm.value.userAgent}`;
    this.versionInfoStr += `\n${this.currentTimestampLbl}: ${this.infoForm.value.currentTimestamp}`;
    this.versionInfoStr += `\n${this.loggedInUserLbl}: ${this.infoForm.value.loggedInUser}`;
    this.versionInfoStr += `\n${this.rolesLbl}: ${this.infoForm.value.roles}`;
    return this.versionInfoStr;
  }

  private close(dialogResult: DialogResult): void {
    this.dialogRef.close(dialogResult);
  }

  private patchForm(): void {
    let loggedInUser: User = null;
    this.store.select(loggedInUserSelector).subscribe((user: User) => {
      loggedInUser = user;
    });
    this.infoForm.patchValue({
      envName: EnvironmentService.getEnvironment().name,
      buildId: EnvironmentService.getEnvironment().buildId,
      buildNumber: EnvironmentService.getEnvironment().buildNumber,
      sourceBranchName: EnvironmentService.getEnvironment().sourceBranchName,
      sourceVersion: EnvironmentService.getEnvironment().sourceVersion,
      requestedFor: EnvironmentService.getEnvironment().requestedFor,
      pipelineStartTime: EnvironmentService.getEnvironment().pipelineStartTime,
      userAgent: window.navigator.userAgent,
      currentTimestamp: DateUtils.getDateSortString(new Date()),
      loggedInUser: !loggedInUser ? 'LOGGED OUT' : loggedInUser.username,
      roles: !loggedInUser ? 'NO ROLES' : loggedInUser.roles,
    });
  }
}
