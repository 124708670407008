<div class="container" [matMenuTriggerFor]="menu">
  <img *ngIf="avatarUrl$ | async as avatarUrl" [src]="avatarUrl" class="avatar-img" alt="Avatar">
  <div class="side-text-container" *ngIf="user">
    <span *ngIf="user.firstname && user.lastname; else username">{{ user.firstname }} {{ user.lastname }}</span>
    <ng-template #username>
      <span> {{ user.username || '-' }}</span>
    </ng-template>
    <span *ngIf="user.primaryRole?.label" class="role">{{ user.primaryRole.label }}</span>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item
      *ngIf="UserRoleUtils.hasRoleOperator(user)"
      [routerLink]="['/shared/settings']"
    >
      <span>Meine Einstellungen</span>
    </button>
    <button mat-menu-item (click)="handleLogoutClick()">
      <span>Abmelden</span>
    </button>
  </mat-menu>
</div>