import { DateUtils } from 'app/shared/common/services/date.utils';

export class UploadedDocumentListEntry {
  docUuid?: string;
  fileName: string;
  uploadTimestamp: Date;
  uploadTimestampSt: string;
  objectUrl: string;
  objectKey: string;
  contentLength?: number;

  constructor(
    docUuid: string,
    fileName: string,
    uploadTimestamp: string,
    objectUrl: string,
    objectKey: string,
    contentLength?: string
  ) {
    this.docUuid = docUuid;
    this.fileName = fileName;
    // use date as UTC date, then add offset in minutes to get correct client time
    this.uploadTimestamp = DateUtils
      .toClientLocalDateTime(DateUtils.toTimestampDate(uploadTimestamp));
    this.uploadTimestampSt = DateUtils.formatTimestampSt(
      this.uploadTimestamp
    );
    this.objectUrl = objectUrl;
    this.objectKey = objectKey;
    this.contentLength = parseInt(contentLength);
  }

  public getDateSortString(): string {
    return DateUtils.getDateSortString(this.uploadTimestamp)
  }
}
