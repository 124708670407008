/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * State history entry
 */
export interface StateHistoryEntryTO { 
    /**
     * New state after the state transition
     */
    state: string;
    /**
     * Date and time of the change
     */
    createdDateTime: string;
    /**
     * ID
     */
    id?: number;
    /**
     * Email address of the person who made the change
     */
    createdBy: string;
    /**
     * Full name of the person who made the change
     */
    createdByName: string;
    /**
     * Additional comment to the status change
     */
    comment: string;
}

