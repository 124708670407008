import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { loginFailureResponse, loginRequest, loginSuccessResponse, logoutAction, sessionExpiredAction, tokenRequest } from './auth.actions';
import { SessionExpiredDialogComponent } from '../../shared/common/components/session-expired-dialog/session-expired-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  public login$ = createEffect(() => this.actions$.pipe(
      ofType(loginRequest),
      mergeMap((value, index) =>
        this.authService.login(value.username, value.password).pipe(
          map(user => loginSuccessResponse({ user })),
          catchError(error => of(loginFailureResponse({ errors: [error] })))
        )
      )
    )
  );

  public token$ = createEffect(() => this.actions$.pipe(
      ofType(tokenRequest),
      mergeMap((value, index) =>
        this.tokenService.retrieveTokenByCode(value.code).pipe(
          map(user => loginSuccessResponse({ user })),
          catchError(error => of(loginFailureResponse({ errors: [error] })))
        )
      )
    )
  );

  public loginSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(loginSuccessResponse),
      tap(() => this.router.navigate(['/incident/list']))
    ), { dispatch: false });

  public loginFailure$ = createEffect(() => this.actions$.pipe(
      ofType(loginFailureResponse),
      tap(() => this.router.navigate(['/auth/login']))
    ), { dispatch: false });

  public logout$ = createEffect(() => this.actions$.pipe(
      ofType(logoutAction),
      tap(() => this.router.navigate(['/auth/login']))
    ), { dispatch: false });

  public sessionExpired$ = createEffect(() => this.actions$.pipe(
    ofType(sessionExpiredAction),
    tap(() => {
      this.dialog.open(SessionExpiredDialogComponent, {
        disableClose: true
      }).afterClosed().subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
    })
  ), { dispatch: false});
}
