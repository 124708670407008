<div style="margin-bottom: 50px;">
  <div class="col" style="padding-left: 0px;">
    <ng-template *ngIf="!!isWizard" [ngTemplateOutlet]="uploadComponents"></ng-template>
    <div id="docInfo" class="information" style="margin: 10px auto;" *ngIf="dataSource.data.length > 0 && !!isWizard"
         i18n="@@doc.upload.find">{{ isMailOrder ? "Hier finden Sie die bereits hochgeladenen Dokumente, die ausschließlich an diesen Auftrag angehängt und versendet werden:"
      : "Hier finden Sie die bereits hochgeladenen Dokumente:" }}</div>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDirection="desc"
      matSortActive="docUploadTimestamp"
      matSortDisableClear
      *ngIf="dataSource.data.length > 0"
    >
      <ng-container matColumnDef="docName">
        <th id="docNameTh"
          mat-header-cell
          *matHeaderCellDef
          i18n="@@doc.name"
        >Name des Dokuments</th>
        <td id="docNameTd" mat-cell *matCellDef="let element">
          {{ element.fileName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="docUploadTimestamp" *ngIf="mode == 'INCIDENT'">
        <th
          id="docUploadTimestampTh"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          i18n="@@doc.date"
        >Datum</th>
        <td id="docUploadTimestampTd" mat-cell *matCellDef="let element">
          {{ element.uploadTimestampSt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          id="actionsTh"
          mat-header-cell
          *matHeaderCellDef
          [attr.colspan]="1"
          i18n="@@actions"
        >Aktionen</th>
        <td id="actionsTd" mat-cell *matCellDef="let element">
          <div class="row" style="margin: 0; display: flex; align-items: center; flex-wrap: nowrap;">
            <button *ngIf="mode == 'INCIDENT'" id="detailsBtn" mat-icon-button class="actbtn" type="button" (click)="redirectToDetails(element)">
              <mat-icon class="mat-18">search</mat-icon>
            </button>
            <button id="renameBtn" *ngIf="!!isWorkModeEdit() && UserRoleUtils.hasRoleOperator(loggedInUser)" mat-icon-button class="actbtn" type="button" (click)="redirectToRename(element.objectKey, element.fileName)">
              <mat-icon class="mat-18">text_format</mat-icon>
            </button>
            <button id="deleteBtn" mat-icon-button class="actbtn" type="button" (click)="redirectToDelete(element.objectKey)" *ngIf="UserRoleUtils.hasRoleOperator(loggedInUser)">
              <mat-icon class="mat-18">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      id="docsPaginator"
      [pageSize]="4"
      [showFirstLastButtons]="false"
      [hidePageSize]="true"
      *ngIf="dataSource.data.length > 0"
    >
    </mat-paginator>
    <ng-template *ngIf="!isWizard" [ngTemplateOutlet]="uploadComponents"></ng-template>
  </div>
</div>

<ng-template #uploadComponents>
  <div id="uploadInfo" class="information" style="margin-bottom: 5px;"
    *ngIf="UserRoleUtils.hasRoleOperator(loggedInUser)"
    i18n="@@doc.upload.new">{{ mode == 'ORDER' ? 'Zusätzliche Dokumente versenden' : 'Neue Dokumente hochladen' }}:</div>
  <form class="upload-form">
    <div *ngIf="UserRoleUtils.hasRoleOperatorOrUser(loggedInUser)">
      <div>
        <div id="uploadElement" class="uplrw" *ngIf="!files.length">
          <div class="left">
            <div id="dz" class="nd-dropzone form-group" style="margin: 0px;">
              <label id="fileTypeLbl" for="dz"
              class="nd-label lbl-stl">JPG, PDF, PNG, MSG, XLS(X), XLA - Max. {{ max_mib }} MiB
              </label>
              <div class="filepicker dropzone dz-clickable" style="min-height: 44px;" (click)="files.click()">
                  <div class="dz-details" style="padding: 0px; height: 44px;">
                    <div class="dz-fileinfo">
                      <span id="fileSelectHint" class="dz-info-filename plc-stl"
                      data-dz-name="true" i18n="@@hint.select">Bitte auswählen...</span>
                    </div>
                  </div>
                  <div id="fileSelectBtn" class="dz-default dz-message"
                      style="font-size: 15px; font-family: enbw-regular; min-height: 42px; max-height: 42px; height: 42px; border: none">
                      <span style="border: none;" i18n="@@file.select">Datei wählen</span>
                      <input #files multiple="multiple" type="file" (change)="setFiles(files.files)" style="display: none;" />
                  </div>
              </div>
            </div>
          </div>
          <div class="">
            <button id="uploadBtn" (click)="upload($event)"
              class="nd-btn nd-btn-main btn-fnt btn-spc"
              i18n="@@file.upload.now">Jetzt hochladen</button>
          </div>
        </div>
        <div class="row uplrw" *ngIf="files.length">
          <div class="col-6 col-6-ext">
            <div id="dz3" class="nd-dropzone form-group" style="margin: 0px">
              <label for="dz3"
                class="nd-label lbl-stl">JPG, PDF, PNG, MSG, XLS(X), XLA - Max. {{ max_mib }} MiB
              </label>
              <div *ngFor="let file of files" class="dropzone dz-preview dz-file-preview dz-processing dz-success dz-complete" style="min-height: 44px; height: 44px;">
                <div class="dz-details" style="padding: 0px; height: 44px;">
                  <div class="dz-fileinfo">
                    <span class="dz-info-filesize" data-dz-size="true"> <strong
                      [style.color]="+file.size > 104857600 ? 'red' : 'green'">{{file.size}}</strong> MiB </span>
                    <span class="dz-info-filename" data-dz-name="true">
                      {{file.name}}
                    </span>
                  </div>
                </div>
                <div class="dz-default dz-message"
                    style="font-size: 15px; font-family: enbw-regular; min-height: 42px; max-height: 42px; height: 42px;">
                  <a class="dz-remove" data-dz-remove="true" (click)="clearFile(file)" style="height: 42px;">
                    <i class="nd-ico-close"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-6-ext">
            <button (click)="upload($event)"
              class="nd-btn nd-btn-main btn-fnt btn-spc"
              i18n="@@file.upload.now">Jetzt hochladen</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div id="upload-components-spacer" style="height: 25px;"></div>
</ng-template>
