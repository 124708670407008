<div class="m-0" style="width: 600px; height: 320px; overflow: auto; margin-bottom: 0px;">
  <div class="flex flex-col">
    <img alt="" src="assets/icons/wartung.png" width="100" style="float: right;">
    <div class="flex px-0 pt-4 items-center justify-between">
      <div>
        <h2 class="my-0 ms-0 w-1/2">Geplante Wartungsarbeiten</h2>
        <h3 class="ms-0 w-3/5">von {{ maintenance.startDateTime | date:'dd.MM.yyyy HH:mm' }} Uhr bis {{ maintenance.endDateTime | date:'dd.MM.yyyy HH:mm' }} Uhr</h3>
      </div>
    </div>
    <div class="text-left pt-4">
      <p>Sehr geehrte Damen und Herren,</p>
      <br>
      <p>
        aufgrund von technischen Wartungsarbeiten ist das Modul Straßenbeleuchtung aktuell nicht verfügbar. Wir
        bitten um Ihr Verständnis. Nach Abschluss der geplanten Wartungsarbeiten können Sie das Modul in gewohnter
        Weise verwenden.
      </p>
      <br>
      <br>
      <p>Ihr #LightOn-Team der Netze BW GmbH</p>
    </div>
  </div>
</div>
