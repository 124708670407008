/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Betroffener Anlagentyp der Störungsmeldung
 */
export type SystemType = 'LIGHTING_POINT' | 'CONTROL_CABINET_POINT';

export const SystemType = {
    LightingPoint: 'LIGHTING_POINT' as SystemType,
    ControlCabinetPoint: 'CONTROL_CABINET_POINT' as SystemType
};

