/**
 * LightOn Technical Data Service API
 * Folgende API werden dazu verwendet technische Daten zur Leuchtstelle bzw. Schaltschrank aus einem externen System zu ermitteln.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Configuration }                                     from '../configuration';
import { ControlCabinetTechnicalData, LightingPointTechnicalData } from '../../generated/v2/incident';



export interface TechnicalServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;


    /**
     * Technische Daten zum übergebenen Schaltschrank lesen.
     * API um externe Daten zum Schaltschrank auszulesen.
     * @param controlCabinetNo Vollständige Schaltschranknummer.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<ControlCabinetTechnicalData>;

    /**
     * Technische Daten zum übergebenen Lichtpunkt lesen.
     * API um externe Daten zum Lichtpunkt auszulesen.
     * @param lightingNo Vollständige Leuchtstellennummer.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    findTechnicalLightpointByLightingNo(lightingNo: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<LightingPointTechnicalData>;

}
