import { SystemUserSettings } from './systemUserSettings';

export class SystemUser {
  constructor(
    public email: string,
    public firstname?: string,
    public lastname?: string,
    public username?: string,
    public settings?: SystemUserSettings
    ) { }
}
