<div class="cls-div">
  <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
    <mat-icon style="margin: 0px;">close</mat-icon>
  </button>
</div>
<div class="div-stl" style="width: 400px;">
  <mat-icon class='infIcon'>info</mat-icon>
</div>
<h1 mat-dialog-title>Ihre Sitzung ist abgelaufen</h1>
<div mat-dialog-content>
  <div class="information">
    Bitte loggen Sie sich erneut ein.<br>
  </div>
</div>
