/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentLightingPointRelation } from './incidentLightingPointRelation';
import { NetArea } from './netArea';


export interface LightingPointLocationData { 
    customerScope?: string;
    longitude?: number;
    latitude?: number;
    zipCode?: string;
    city?: string;
    district?: string;
    street?: string;
    houseNumber?: string;
    netArea?: NetArea;
    incident?: IncidentLightingPointRelation;
    number?: string;
    cabinetNumber?: string;
}
export namespace LightingPointLocationData {
}


