/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderState = 'NEW' | 'ERROR' | 'IN_PROGRESS' | 'SCHEDULED' | 'DONE_WITH_FOLLOW_UP' | 'DONE' | 'NOT_DONE' | 'UNSPECIFIED';

export const OrderState = {
    New: 'NEW' as OrderState,
    Error: 'ERROR' as OrderState,
    InProgress: 'IN_PROGRESS' as OrderState,
    Scheduled: 'SCHEDULED' as OrderState,
    DoneWithFollowUp: 'DONE_WITH_FOLLOW_UP' as OrderState,
    Done: 'DONE' as OrderState,
    NotDone: 'NOT_DONE' as OrderState,
    Unspecified: 'UNSPECIFIED' as OrderState
};

