import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerModalDialogData } from './spinner.modal.dialog.data';

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: SpinnerModalDialogData) { }
}
