import {
  AssignedParty,
  Direction,
  IncidentRelationType,
  IncidentStatus, NetArea,
  OrderType,
} from '../../generated/v2/incident';

export interface IncidentFilterOptions {
  city?: string;
  assignee?: string;
  assignedParty?: AssignedParty;
  street?: string;
  lightingOrControlCabinetNo?: string;
  incidentStatus?: IncidentStatus[];
  fromDate?: string;
  hasComplaint?: boolean;
  relationTypes?: IncidentRelationType[]; // use this
  netArea?: NetArea;
  orderDueDatePassed?: boolean;
  sortBy?: string;
  sortDir?: Direction;
  toDate?: string;
  orderTypes?: OrderType[];
  isNotTransferredToLuxData?: boolean;
}
