import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MatPaginatorI18n extends MatPaginatorIntl {
  itemsPerPageLabel = 'Elemente pro Seite';
  nextPageLabel = 'nächste Seite';
  previousPageLabel = 'vorherige Seite';
  firstPageLabel = 'erste Seite';
  lastPageLabel = 'letzte Seite';

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const KEYWORD_OF = 'von';
    if (0 === length || 0 === pageSize) {
      return `0 ${KEYWORD_OF} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${KEYWORD_OF} ${length}`;
  }
}
