import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { UserRole } from 'app/shared/common/models/enums/user-role/user.role';
import { SystemUser } from 'app/shared/common/models/systemUser';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { SystemUserData } from 'app/shared/common/services/system.user.data';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { User } from '../../shared/common/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private store: Store<AppState>,
              private logger: NGXLogger) {}

  public login(username: string, password: string): Observable<User> {
    const users = EnvironmentService.getEnvironment().users;

    if (username.toLocaleLowerCase() in users) {
      if (users[username.toLocaleLowerCase()] === password) {
        const roles: UserRole[] = [UserRole.DICON_OPERATOR];
        const user: User = {
          id: v4(),
          username,
          roles,
          primaryRole: UserRoleUtils.determinePrimaryUserRoleInfo(roles),
          internal: true,
        };
        const systemUser: SystemUser = SystemUserData.findSystemUserByUsername(username);
        if (!!systemUser) {
          this.logger.trace('detected system user=', systemUser);
          user.email = systemUser.email;
          user.firstname = systemUser.firstname;
          user.lastname = systemUser.lastname;
        }
        return of(user);
      }
      else {
        return throwError('Username and password does not match');
      }
    } else {
      return throwError('User not exists');
    }
  }
}
