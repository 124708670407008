/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Dokumenten Struktur
 */
export interface OrderDocumentTO { 
    /**
     * Eindeutiger logischer Dateiname
     */
    fileName: string;
    /**
     * S3 object key
     */
    objectKey: string;
    /**
     * S3 document key
     */
    docId: string;
    /**
     * Uploaded timestamp
     */
    uploadTimestamp?: string;
    /**
     * Direct document link url
     */
    objectUrl?: string;
    /**
     * The uuid of the incident where the doc was first created
     */
    orderSourceUuid?: string;
    /**
     * Content length in bytes
     */
    contentLength?: string;
}

