/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderFeedBackType = 'TECHNICAL_NOTIFICATION_CREATION_FAILED' | 'TECHNICAL_NOTIFICATION_CREATION_ACCEPTED' | 'NOTIFICATION_CREATION_TRIGGERED' | 'NOTIFICATION_CREATED' | 'NOTIFICATION_CREATION_FAILED' | 'NOTIFICATION_ASSIGNED_TO_ORDER' | 'NOTIFICATION_DISPATCHED' | 'NOTIFICATION_COMPLETED' | 'NOTIFICATION_PARTLY_PROCESSED' | 'NOTIFICATION_NOT_PROCESSED' | 'MINZE_NB_NOTIFICATION_CREATED' | 'MINZE_NC_NOTIFICATION_CREATED' | 'MINZE_ND_NOTIFICATION_CREATED' | 'MAIL_TRANSFER_TRIGGERED' | 'MAIL_SUBMIT_SUCCESS' | 'MAIL_SUBMIT_ERROR' | 'MANUAL_COMPLETED' | 'MANUAL_PARTLY_PROCESSED' | 'MANUAL_NOT_PROCESSED' | 'MATERIAL_ORDER_COMPLETED';

export const OrderFeedBackType = {
    TechnicalNotificationCreationFailed: 'TECHNICAL_NOTIFICATION_CREATION_FAILED' as OrderFeedBackType,
    TechnicalNotificationCreationAccepted: 'TECHNICAL_NOTIFICATION_CREATION_ACCEPTED' as OrderFeedBackType,
    NotificationCreationTriggered: 'NOTIFICATION_CREATION_TRIGGERED' as OrderFeedBackType,
    NotificationCreated: 'NOTIFICATION_CREATED' as OrderFeedBackType,
    NotificationCreationFailed: 'NOTIFICATION_CREATION_FAILED' as OrderFeedBackType,
    NotificationAssignedToOrder: 'NOTIFICATION_ASSIGNED_TO_ORDER' as OrderFeedBackType,
    NotificationDispatched: 'NOTIFICATION_DISPATCHED' as OrderFeedBackType,
    NotificationCompleted: 'NOTIFICATION_COMPLETED' as OrderFeedBackType,
    NotificationPartlyProcessed: 'NOTIFICATION_PARTLY_PROCESSED' as OrderFeedBackType,
    NotificationNotProcessed: 'NOTIFICATION_NOT_PROCESSED' as OrderFeedBackType,
    MinzeNbNotificationCreated: 'MINZE_NB_NOTIFICATION_CREATED' as OrderFeedBackType,
    MinzeNcNotificationCreated: 'MINZE_NC_NOTIFICATION_CREATED' as OrderFeedBackType,
    MinzeNdNotificationCreated: 'MINZE_ND_NOTIFICATION_CREATED' as OrderFeedBackType,
    MailTransferTriggered: 'MAIL_TRANSFER_TRIGGERED' as OrderFeedBackType,
    MailSubmitSuccess: 'MAIL_SUBMIT_SUCCESS' as OrderFeedBackType,
    MailSubmitError: 'MAIL_SUBMIT_ERROR' as OrderFeedBackType,
    ManualCompleted: 'MANUAL_COMPLETED' as OrderFeedBackType,
    ManualPartlyProcessed: 'MANUAL_PARTLY_PROCESSED' as OrderFeedBackType,
    ManualNotProcessed: 'MANUAL_NOT_PROCESSED' as OrderFeedBackType,
    MaterialOrderCompleted: 'MATERIAL_ORDER_COMPLETED' as OrderFeedBackType
};

