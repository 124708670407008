import { createAction, props } from '@ngrx/store';
import { ViewMode } from 'app/shared/common/models/enums/view-mode/view-mode';
import { IncidentsFilter } from '../models/incidentsFilter';

export enum IncidentActionTypes {
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_INCIDENTS_FILTER = 'SET_INCIDENTS_FILTER',
  RESET_INCIDENTS_FILTER = 'RESET_INCIDENTS_FILTER'
}

export const setIncidentsFilterAction = createAction(
  IncidentActionTypes.SET_INCIDENTS_FILTER,
  props<{ filter: IncidentsFilter, viewMode: ViewMode }>()
);

export const resetIncidentsFilterAction = createAction(
  IncidentActionTypes.RESET_INCIDENTS_FILTER,
  props<{ filter: IncidentsFilter }>()
);
