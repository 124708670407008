import { Injectable } from '@angular/core';
import { TechnicalService } from 'app/shared/api/technical-data/api/technical.service';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import { EnvironmentService } from './env.service';
import {
  CommuneTO, ControlCabinetTechnicalData,
  GeoLocationResponseV2ControlCabinetLocationDataV2,
  GeoLocationResponseV2LightingPointLocationDataV2, LightingPointTechnicalData,
  LuxdataService,
} from '../../api/generated/v2/incident';
@Injectable({
  providedIn: 'root'
})
export class LuxDataTechnicalService {

  constructor(private technicalService: TechnicalService,
              private luxDataService: LuxdataService) {
    this.technicalService.configuration.basePath = EnvironmentService.getEnvironment().incidentService;
    this.luxDataService.configuration.basePath = EnvironmentService.getEnvironment().lionBasePath;
  }

  public getLightpointDataById(lightPointNo: string): Observable<LightingPointTechnicalData> {
    return this.technicalService
      .findTechnicalLightpointByLightingNo(lightPointNo, v4(), EnvironmentService.getEnvironment(). name);
  }

  public getControlCabinetDataById(controlCabinetNo: string): Observable<ControlCabinetTechnicalData> {
    return this.technicalService
      .findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo, v4(), EnvironmentService.getEnvironment().name);
  }

  public getCommune(params: any): Observable<CommuneTO[]> {
    return this.technicalService
      .findCommune(params, v4(), EnvironmentService.getEnvironment(). name);
  }

  public getLightpointsByCustomerScope(customerScope: any, lightingNo?: string): Observable<GeoLocationResponseV2LightingPointLocationDataV2> {
    return this.luxDataService
      .getLightingPointsGeoLocationsV2(customerScope, lightingNo);
  }

  // tslint:disable-next-line:max-line-length
  public getLightpointsByCustomerScopeV2(customerScope: any, lightingNo?: string): Observable<GeoLocationResponseV2LightingPointLocationDataV2> {
    return this.luxDataService.getLightingPointsGeoLocationsV2(customerScope, lightingNo);
  }

  // tslint:disable-next-line:max-line-length
 public getControlCabinetByCustomerScope(customerScope: any, cabinetNo?: string): Observable<GeoLocationResponseV2ControlCabinetLocationDataV2> {
    return this.luxDataService
      .getControlCabinetGeoLocationsV2(customerScope, cabinetNo);
  }

  // tslint:disable-next-line:max-line-length
  public getControlCabinetGeolocation(customerScope: any, controlCabinetNo?: any): Observable<GeoLocationResponseV2ControlCabinetLocationDataV2> {
    return this.luxDataService
      .getControlCabinetGeoLocationsV2(customerScope, controlCabinetNo);
  }
}
