import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from 'app/shared/api/generated/v2/incident';

@Pipe({
  name: 'orderTypeLabel',
})
export class OrderTypeLabelPipe implements PipeTransform {
  transform(
    value: OrderType,
    args: 'default' | 'detailed' = 'default'
  ): string {
    switch (value) {
      case OrderType.WfmOrderNa:
        return args === 'detailed' ? 'WFM' : 'WFM - Reparaturfahrt';
      case OrderType.WfmOrderOnly:
        return 'WFM - Manueller Auftrag';
      case OrderType.MinzeOrderNc: // This case should never be reached because NC orders are never renderd
      case OrderType.MinzeOrderNd:
        return 'WFM Monteur - Gefahr in Verzug';
      case OrderType.MinzeOrderNb:
        return 'WFM Monteur - Wartung';
      case OrderType.Other:
        return 'Sonstiger Auftrag';
      case OrderType.OtherMail:
        return args === 'detailed'
          ? 'Sonstiger Auftrag per Mail'
          : 'Sonstiger Auftrag';
      case OrderType.ContractorMail:
        return args === 'detailed'
          ? 'DL Mail'
          : 'Dienstleister - Reparaturfahrt';
      case OrderType.ContractorOrderOnly:
        return 'Dienstleister - Manueller Auftrag';
      case OrderType.MaterialOrder:
        return 'Materialbestellung';
      case OrderType.MaterialOrderMail:
        return 'Materialbestellung per Mail';
      default:
        return value;
    }
  }
}
