// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  name: 'lighton-fe-prod',
  authMode: 'EXTERNAL_MSAD',
  authDemoClientId: '5dlo0vo66vqdv20355me08l27g',
  authMsadClientId: '7mafneohfetbkus6bme49prhsp',
  supportEmail: 'support-strassenbeleuchtung@netze-bw.de',
  buildId: '1577473',
  buildNumber: '20241115.4',
  sourceBranchName: 'master',
  sourceVersion: '1803bb462bcbe89c512197e6dba2f65efe70ad4d',
  requestedFor: 'Sylwester Feduk',
  pipelineStartTime: '2024-11-15 16:03:23+01:00',
  xApiKey: 'GooRDiKrSj5eevcvjecCnaB3wTaVqllK3ShNF6rd',
  automatedLoginState: '853012c0-54cf-4191-b1be-8c9cb214e939',

  incidentBasePath: 'https://api.dienstleistung-light-on-prod.enbw.cloud',
  incidentService: 'https://api.dienstleistung-light-on-prod.enbw.cloud',

  demoPoolUrl: 'https://dl-light-on-dev-demo.auth.eu-central-1.amazoncognito.com',
  msadPoolUrl: 'https://dl-light-on-production.auth.eu-central-1.amazoncognito.com',

  lionBasePath: 'https://api.lion.dienstleistungen.netze-bw.de',
  lionService: 'https://api.lion.dienstleistungen.netze-bw.de',

  users: {
    lighton: 'bnmyxcv',
    koerker: '#happy',
    augstej: '#light',
    anselre: '#laterne',
  },
};
