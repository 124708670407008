import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentUploadModalData } from './document-upload-modal.data';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss']
})
export class DocumentUploadModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DocumentUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentUploadModalData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.addSvgIcon('checked', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/checked.svg'));
    iconRegistry.addSvgIcon('failed', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/failed.svg'));
  }

  onClsBtnClick(): void {
    this.dialogRef.close(DialogResult.CLOSED);
  }
}
