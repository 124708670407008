/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IncidentStatus = 'APPROVAL_REQUIRED_BY_COMMUNE' | 'APPROVED_BY_COMMUNE' | 'APPROVAL_REQUIRED_BY_NETZE_BW' | 'NEW' | 'ORDER_PLACED' | 'OVERDUE' | 'CHECK_FEEDBACK' | 'CHECK_FEEDBACK_FOLLOW_UP_MEASURES' | 'INTERNAL_CLARIFICATION' | 'FOLLOW_UP_MEASURES_CABLE_TEST_VAN' | 'FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT' | 'FOLLOW_UP_MEASURES_MORE_DEFICIENCIES' | 'FOLLOW_UP_MEASURE_IN_PROGRESS' | 'IMMINENT_DANGER' | 'IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT' | 'UNREPAIRABLE' | 'REJECTED_BY_COMMUNE' | 'REJECTED_BY_NETZE_BW' | 'FAULT_FIXED' | 'FAULT_NOT_FOUND' | 'FAULT_FIXED_PROVISIONAL_SOLUTION' | 'POLE_NOT_ACCESSIBLE' | 'TRANSMITTED_TO_CONTROL_CENTER' | 'DELETED' | 'ORDER_MATERIAL_OPEN' | 'ORDER_MATERIAL_CLOSED';

export const IncidentStatus = {
    ApprovalRequiredByCommune: 'APPROVAL_REQUIRED_BY_COMMUNE' as IncidentStatus,
    ApprovedByCommune: 'APPROVED_BY_COMMUNE' as IncidentStatus,
    ApprovalRequiredByNetzeBw: 'APPROVAL_REQUIRED_BY_NETZE_BW' as IncidentStatus,
    New: 'NEW' as IncidentStatus,
    OrderPlaced: 'ORDER_PLACED' as IncidentStatus,
    Overdue: 'OVERDUE' as IncidentStatus,
    CheckFeedback: 'CHECK_FEEDBACK' as IncidentStatus,
    CheckFeedbackFollowUpMeasures: 'CHECK_FEEDBACK_FOLLOW_UP_MEASURES' as IncidentStatus,
    InternalClarification: 'INTERNAL_CLARIFICATION' as IncidentStatus,
    FollowUpMeasuresCableTestVan: 'FOLLOW_UP_MEASURES_CABLE_TEST_VAN' as IncidentStatus,
    FollowUpMeasuresMaterialProcurement: 'FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT' as IncidentStatus,
    FollowUpMeasuresMoreDeficiencies: 'FOLLOW_UP_MEASURES_MORE_DEFICIENCIES' as IncidentStatus,
    FollowUpMeasureInProgress: 'FOLLOW_UP_MEASURE_IN_PROGRESS' as IncidentStatus,
    ImminentDanger: 'IMMINENT_DANGER' as IncidentStatus,
    ImminentDangerLiesAtSalesDepartment: 'IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT' as IncidentStatus,
    Unrepairable: 'UNREPAIRABLE' as IncidentStatus,
    RejectedByCommune: 'REJECTED_BY_COMMUNE' as IncidentStatus,
    RejectedByNetzeBw: 'REJECTED_BY_NETZE_BW' as IncidentStatus,
    FaultFixed: 'FAULT_FIXED' as IncidentStatus,
    FaultNotFound: 'FAULT_NOT_FOUND' as IncidentStatus,
    FaultFixedProvisionalSolution: 'FAULT_FIXED_PROVISIONAL_SOLUTION' as IncidentStatus,
    PoleNotAccessible: 'POLE_NOT_ACCESSIBLE' as IncidentStatus,
    TransmittedToControlCenter: 'TRANSMITTED_TO_CONTROL_CENTER' as IncidentStatus,
    Deleted: 'DELETED' as IncidentStatus,
    OrderMaterialOpen: 'ORDER_MATERIAL_OPEN' as IncidentStatus,
    OrderMaterialClosed: 'ORDER_MATERIAL_CLOSED' as IncidentStatus
};

