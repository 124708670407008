import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocumentService as IncidentDocumentService,
} from 'app/shared/api/incident';

import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import { EnvironmentService } from './env.service';
import {
  DocumentService,
  DocumentUploadRequestTO,
  DocumentUploadResponseTO,
  IncidentDocumentCollectionTO,
  UpdateDocumentTO,
  DocumentUploadRequestTO as DocumentUploadRequestTOV1,
} from 'app/shared/api/generated/v2/incident';

@Injectable({
  providedIn: 'root',
})
export class DocService {
  constructor(
    private incidentDocumentService: DocumentService,
    private incidentDocumentServicev1: IncidentDocumentService
  ) {
    this.incidentDocumentService.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
    this.incidentDocumentServicev1.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
  }

  public deleteDocument(
    objUuid: string,
    objKey: string
  ): Observable<HttpResponse<any>> {
    return this.incidentDocumentService.deleteIncidentDocumentByKeyV2(
      objUuid,
      objKey,
      'response'
    );
  }

  public getDocuments(objId: string): Observable<IncidentDocumentCollectionTO> {
    return this.getDocumentsForIncident(objId);
  }

  public changeDocName(
    objId: string,
    objKey: string,
    docName: string
  ): Observable<any> {
    return this.changeDocNameForIncident(objId, objKey, { fileName: docName });
  }

  public generatePresignedDocumentUrl(
    incidentUuid: string,
    fileName: string,
    file: File
  ): Observable<DocumentUploadResponseTO> {
    const fileSizeInBytes: number = file.size;
    let mimeType: string = file.type;
    if (!mimeType && fileName.endsWith('.msg')) {
      mimeType = 'application/vnd.ms-outlook';
    }
    const request: DocumentUploadRequestTO = {
      fileName,
      fileSizeInBytes,
      mimeType,
    };
    const id: string = !incidentUuid ? 'tmp' : incidentUuid;
    return this.incidentDocumentService.generatePresignedDocumentUrlV2(
      id,
      request
    );
  }

  public generateTempPresignedDocumentUrl(
    fileName: string,
    file: File
  ): Observable<DocumentUploadResponseTO> {
    const fileSizeInBytes: number = file.size;
    var mimeType: string = file.type;
    if (!mimeType && fileName.endsWith('.msg')) {
      mimeType = 'application/vnd.ms-outlook';
    }
    const request: DocumentUploadRequestTOV1 = {
      fileName,
      fileSizeInBytes,
      mimeType,
    };
    return this.incidentDocumentServicev1.generateTempPresignedDocumentUrl(
      v4(),
      EnvironmentService.getEnvironment().name,
      request
    );
  }

  getDocumentsForIncident(
    incUuid: string
  ): Observable<IncidentDocumentCollectionTO> {
    return this.incidentDocumentService.getIncidentDocumentsByIncidentIdV2(
      incUuid
    );
  }

  private changeDocNameForIncident(
    incUuid: string,
    objKey: string,
    doc: UpdateDocumentTO
  ): Observable<any> {
    return this.incidentDocumentService.updateDocumentByKeyV2(
      incUuid,
      objKey,
      doc,
      'response'
    );
  }
}
