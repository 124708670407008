export class ObjUtils {

  public static toNullableString(st: string): string {
    return ObjUtils.isNullOrUndefinedOrEmpty(st) ? null : st;
  }

  public static toNullableNumber(no: number): number {
    return ObjUtils.isNullOrUndefinedOrZero(no) ? null : no;
  }

  public static toNullableStrng(no: number): string {
    return ObjUtils.isNullOrUndefinedOrZero(no) ? null : no.toString();
  }

  public static toNullableBoolean(bl: boolean): boolean {
    return ObjUtils.isNullOrUndefined(bl) ? null : bl;
  }

  public static toNullableArray(arr: any[]): any[] {
    return ObjUtils.isEmptyArray(arr) ? null : arr;
  }

  public static toNullableAny(obj: any): any {
    return ObjUtils.isNullOrUndefined(obj) ? null : obj;
  }

  public static isNullOrUndefinedOrEmpty(st: string): boolean {
    return null === st || undefined === st || '' === st || 'null' === st;
  }

  public static isNullOrUndefinedOrZero(no: number): boolean {
    return null === no || undefined === no || isNaN(no) || 0 === no;
  }

  public static isNullOrUndefined(obj: any): boolean {
    return null === obj || undefined === obj;
  }

  public static isEmptyArray(obj: any[]): boolean {
    return ObjUtils.isNullOrUndefined(obj) || obj.length < 1;
  }
}
