/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LightPointState } from './lightPointState';
import { Activity } from './activity';
import { OrderFeedBackType } from './orderFeedBackType';


export interface CreateManualOrderFeedbackTO { 
    type: OrderFeedBackType;
    worker?: string;
    protocolActivity?: Set<Activity>;
    protocolActivityOther?: string;
    protocolLink?: string;
    protocolText?: string;
    usedMaterials?: Array<string>;
    lightPointState?: LightPointState;
    executedAt: string;
    receivedAt?: string;
}
export namespace CreateManualOrderFeedbackTO {
}


