/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NetArea } from './netArea';


/**
 * Technische Daten eines Lichtpunktes
 */
export interface LightingPointTechnicalData { 
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo?: string;
    /**
     * Tragsystem-Ausleger (Arm)
     */
    arms?: string;
    /**
     * Tragsystem-Masten
     */
    poles?: string;
    /**
     * Leuchte
     */
    luminaires?: string;
    /**
     * Lampe
     */
    lamps?: string;
    /**
     * Leitungszug
     */
    cableNumber?: string;
    /**
     * Schaltschrank Nummer
     */
    controlCabinetNo?: string;
    /**
     * Schaltschrank Beschreibung
     */
    controlCabinetDescription?: string;
    /**
     * Dienstleister
     */
    supplier?: string;
    /**
     * Standort Nummer
     */
    locationNumber?: string;
    /**
     * Identifikator des Kunden
     */
    customerScope?: string;
    netArea?: NetArea;
    /**
     * Vertragsnummer
     */
    contractId?: string;
    /**
     * Vertragsbezeichnung
     */
    contractDescription?: string;
    /**
     * Warnsystem
     */
    warningSystem?: string;
    /**
     * Additional infos for the light point
     */
    description?: string;
}
export namespace LightingPointTechnicalData {
}


