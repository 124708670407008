/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentRelationType } from './incidentRelationType';
import { OrderType } from './orderType';
import { StatusGroup } from './statusGroup';
import { IncidentStatus } from './incidentStatus';
import { NetArea } from './netArea';
import { Direction } from './direction';
import { AssignedParty } from './assignedParty';


/**
 * Request object for filtering incidents
 */
export interface IncidentFilterRequestV2 { 
    lightingOrControlCabinetNo?: string;
    assignee?: string;
    assignedParty?: AssignedParty;
    street?: string;
    city?: string;
    netArea?: NetArea;
    incidentStatus?: Set<IncidentStatus>;
    hasComplaint?: boolean;
    fromDate?: string;
    toDate?: string;
    orderDueDatePassed?: boolean;
    incidentRelationTypes?: Set<IncidentRelationType>;
    orderTypes?: Set<OrderType>;
    sortBy?: string;
    sortDir?: Direction;
    page?: number;
    size?: number;
    statusGroup?: StatusGroup;
    isNotTransferredToLuxData?: boolean;
}
export namespace IncidentFilterRequestV2 {
}


