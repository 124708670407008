/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Priorität der Störungsmeldung
 */
export type Priority = 'IMMEDIATELY' | 'SHORT_TERM' | 'MEDIUM_TERM';

export const Priority = {
    Immediately: 'IMMEDIATELY' as Priority,
    ShortTerm: 'SHORT_TERM' as Priority,
    MediumTerm: 'MEDIUM_TERM' as Priority
};

