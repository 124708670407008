import { DocumentFilenameChangeModalData } from './document-filename-change-modal.data';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';

export class DocumentFilenameChangeModalDataImpl implements DocumentFilenameChangeModalData {
  message: string;
  currentFilename: string;
  newFilename: string;
  existingFilenames?: string[];
  dialogResult?: DialogResult;

  public static getDefaultFilenameChangeModalData(currFilename: string, exFilenames?: string[]): DocumentFilenameChangeModalData {
    return {
      message: 'Dateiname ändern',
      currentFilename: currFilename,
      newFilename: currFilename,
      existingFilenames: exFilenames,
      dialogResult: DialogResult.CLOSED,
    };
  }
}
