/**
 * DiCon Incident Service API
 * Folgende API wird dazu verwendet um Störungen im #LightOn System zu bearbeiten.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateMailTO { 
    /**
     * Mail CC Empfänger Adresse
     */
    ccRecipients?: Array<string>;
    /**
     * Object Keys der Dokumente, die mitgeschickt werden sollen
     */
    docObjectKeys?: Array<string>;
    /**
     * Mail Info Text
     */
    mailText: string;
    /**
     * Mail Empfänger Adresse
     */
    recipients: Array<string>;
}

