/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderType = 'WFM_ORDER_NA' | 'WFM_ORDER_ONLY' | 'MINZE_ORDER_NB' | 'MINZE_ORDER_NC' | 'MINZE_ORDER_ND' | 'CONTRACTOR_MAIL' | 'CONTRACTOR_ORDER_ONLY' | 'MATERIAL_ORDER' | 'MATERIAL_ORDER_MAIL' | 'OTHER' | 'OTHER_MAIL';

export const OrderType = {
    WfmOrderNa: 'WFM_ORDER_NA' as OrderType,
    WfmOrderOnly: 'WFM_ORDER_ONLY' as OrderType,
    MinzeOrderNb: 'MINZE_ORDER_NB' as OrderType,
    MinzeOrderNc: 'MINZE_ORDER_NC' as OrderType,
    MinzeOrderNd: 'MINZE_ORDER_ND' as OrderType,
    ContractorMail: 'CONTRACTOR_MAIL' as OrderType,
    ContractorOrderOnly: 'CONTRACTOR_ORDER_ONLY' as OrderType,
    MaterialOrder: 'MATERIAL_ORDER' as OrderType,
    MaterialOrderMail: 'MATERIAL_ORDER_MAIL' as OrderType,
    Other: 'OTHER' as OrderType,
    OtherMail: 'OTHER_MAIL' as OrderType
};

