import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Spinner } from 'app/shared/common/components/spinner-modal/spinner';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, map, share, take, takeUntil } from 'rxjs/operators';
import { User } from '../../shared/common/models/user';
import jwt_decode from 'jwt-decode';
import { AuthUrlUtils } from './auth.url.utils';
import { ObjUtils } from 'app/shared/common/services/obj.utils';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { loggedInUserSelector } from '../state/auth.reducers';
import { TokenData } from '../../shared/common/models/tokenData';
import { LoginResultModalDialogDataImpl } from '../components/login-result-modal/login-result-modal-dialog.data.impl';
import { loginFailureResponse, logoutAction, resetLoginErrorAction, sessionExpiredAction } from '../state/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnDestroy {

  public loggedInUser: User;
  private tokenExpirationTimer: Subscription;
  private destroy$ = new Subject<void>();

  constructor(private httpClient: HttpClient,
              private httpBackend: HttpBackend,
              private dialog: MatDialog,
              private store: Store<AppState>,
              private logger: NGXLogger,
  ) {
      // avoid using default http interceptor
      this.httpClient = new HttpClient(httpBackend);
  }

  private setAutoLogout(expirationDuration: number): void {
    if (this.tokenExpirationTimer) {
      this.tokenExpirationTimer.unsubscribe();
    }

    this.tokenExpirationTimer = timer(expirationDuration * 1000).subscribe(() => {
      this.store.dispatch(sessionExpiredAction());
    });
  }

  public getRefreshToken(): Observable<string> {
    return this.store.select(loggedInUserSelector).pipe(
      take(1),
      map(user => user?.tokenData?.refresh_token)
    );
  }

  public retrieveTokenByCode(code: string): Observable<User> {
    const body: URLSearchParams = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', AuthUrlUtils.getAuthClientId());
    body.set('code', code);
    body.set('redirect_uri', AuthUrlUtils.getRedirectAbsoluteUrl());
    return this.performTokenRequest(body, false);
  }

  public retrieveTokenByRefreshToken(refreshToken: string): Observable<User> {
    const body: URLSearchParams = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', AuthUrlUtils.getAuthClientId());
    body.set('refresh_token', refreshToken);
    body.set('redirect_uri', AuthUrlUtils.getRedirectAbsoluteUrl());
    return this.performTokenRequest(body, true);
  }

  private performTokenRequest(body: URLSearchParams, isRefresh: boolean): Observable<User> {
    const hdrs: HttpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const expectedHttpCode = 200;
    const tokenObs: Observable<any>
      = this.httpClient.post(AuthUrlUtils.getTokenAbsoluteUrl(), body.toString(),
      {
        headers: hdrs,
        observe: 'response'
      })
    .pipe(
      share(),
      takeUntil(this.destroy$)
    );

    new Spinner().spin(tokenObs, this.dialog, { message: 'Benutzer authentifizieren...' });

    return tokenObs.pipe(
      map(response => {
        this.logger.trace('response=', response);
        const user = this.handleResponse(expectedHttpCode === response.status, isRefresh, response.body);
        if (user && user.tokenData && user.tokenData.expires_in) {
          this.setAutoLogout(user.tokenData.expires_in);
        }
        return user;
      }),
      catchError(error => {
        this.logger.log('error=', error);
        this.handleTokenRequestFailure(error);
        return throwError('Authentication failed');
      })
    );
  }

  private handleResponse(success: boolean, isRefresh: boolean, body?: string): User {
    return !success ? null : this.responseToUser(body, isRefresh);
  }

  private responseToUser(body: any, isRefresh: boolean): User {
    if (isRefresh) {
      this.store.select(loggedInUserSelector).pipe(
        take(1),
        map((user: User) => {
          this.loggedInUser = user;
          return this.createUserFromToken(body);
        }))
        .subscribe();
    } else {
      return this.createUserFromToken(body);
    }
  }

  private createUserFromToken(body: any): User {
    const user: User = this.tokenToUserData(body.id_token);
    user.tokenData = this.createTokenData(body);
    this.logger.trace('user data=', user);
    return user;
  }

  private createTokenData(body: any, existing?: TokenData): TokenData {
    let tokenData: TokenData = new TokenData();
    // in case there is no existing token data just take the response body
    if (ObjUtils.isNullOrUndefined(existing)) {
      tokenData = body;
    // in case there is existing token data keep the refresh token from the existing data and override everything else
    } else {
      tokenData.refresh_token = existing.refresh_token;
      tokenData.access_token = body.access_token;
      tokenData.id_token = body.id_token;
      tokenData.expires_in = body.expires_in;
      tokenData.token_type = body.token_type;
    }
    return tokenData;
  }

  private tokenToUserData(encodedToken: string): User {
    const decodedToken: any = this.decodeToken(encodedToken);
    const user: User = new User();
    // TODO: set properly acc to token content
    user.id = this.determineUserId(decodedToken);
    user.username = this.determineUsername(decodedToken);
    user.email = decodedToken['email'];
    user.firstname = decodedToken['given_name'];
    user.lastname = decodedToken['family_name'];
    // in case firstname and lastname could not be determined
    // try to parse the token in another way
    if (ObjUtils.isNullOrUndefinedOrEmpty(user.firstname)
      && ObjUtils.isNullOrUndefinedOrEmpty(user.lastname)) {
      const names: string[] = user.username.split(' ');
      if (!ObjUtils.isEmptyArray(names)) {
        user.lastname = names[0];
        user.firstname = names[1];
      }
    }
    // TODO: change back to normal role determination
    //user.roles = [UserRole.DICON_USER];
    user.roles = UserRoleUtils.getUserRolesFromToken(decodedToken['custom:roles']);
    user.primaryRole = UserRoleUtils.determinePrimaryUserRoleInfo(user.roles);
    return user;
  }

  private determineUserId(decodedToken: string) {
    const userId: string = decodedToken['email'];
    return !ObjUtils.isNullOrUndefinedOrEmpty(userId)?
      userId : decodedToken['cognito:username'];
  }

  private determineUsername(decodedToken: string): string {
    let username: string = decodedToken['name'];
    if (!ObjUtils.isNullOrUndefinedOrEmpty(username)) {
      return username;
    }
    username = decodedToken['preferred_username'];
    return !ObjUtils.isNullOrUndefinedOrEmpty(username)?
      username : decodedToken['email'];
  }

  private decodeToken(encodedToken: string): any {
    return jwt_decode(encodedToken);
  }

  private handleTokenRequestFailure(error: any): void {
    LoginResultModalDialogDataImpl.openAuthenticationFailedResultDialog(this.dialog);
    this.store.dispatch(loginFailureResponse({ errors: [error] }));
    this.store.dispatch(resetLoginErrorAction());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.tokenExpirationTimer) {
      this.tokenExpirationTimer.unsubscribe();
    }
  }
}
