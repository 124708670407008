import { ObjUtils } from 'app/shared/common/services/obj.utils';
import { SystemType } from '../../shared/api/generated/v2/incident';

export class SystemTypeUtils {
  public static readonly SYSTEM_TYPES = [
    {
      key: SystemType.LightingPoint,
      label: 'Leuchtstelle',
      hasText: false,
      active: true,
      checkboxKey: 'isLightningPointCheck'
    },
    {
      key: SystemType.ControlCabinetPoint,
      label: 'Schaltstelle',
      hasText: false,
      active: true,
      checkboxKey: 'isControlCabinetCheck'
    }
  ];

  public static getSystemType(systemType: SystemType | string) {
    const element = SystemTypeUtils.SYSTEM_TYPES.find(elem => elem.key === systemType);
    return ObjUtils.isNullOrUndefined(element) ? null : element;
  }

  public static getSystemTypeKey(systemType: SystemType | string): SystemType {
    const element = SystemTypeUtils.getSystemType(systemType);
    return ObjUtils.isNullOrUndefined(element) ? null : element.key;
  }

  public static getSystemTypeLabel(systemType: SystemType | string): string {
    const element = SystemTypeUtils.getSystemType(systemType);
    return ObjUtils.isNullOrUndefined(element) ? '' : element.label;
  }
}
