/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Ergebnis eines asynchronen Regelauswertungsprozesses
 */
export interface Regelauswertungsprozessergebnis1 { 
    /**
     * zugeordneter Mitarbeiter
     */
    assignee?: string;
    /**
     * UUID des angelegten Auftrags
     */
    createdOrderUuid?: string;
    /**
     * Fehlerdetails
     */
    errorMessage?: string;
    /**
     * Ergebnis
     */
    statusMessage?: string;
}

