import { DialogType } from "../../models/enums/dialog-type/dialog.type";
import { ViewMode } from "../../models/enums/view-mode/view-mode";
import { ResultModalDialogData } from "./result-modal-dialog.data";

export class ResultModalDialogDataImpl implements ResultModalDialogData {
  message: string;
  hint: string;
  button: string;
  link: string;
  dialogType: DialogType;
  viewMode?: ViewMode;
  withRouting?: boolean;

  public static getDefaultResultModalDialgData(msg?: string, hnt?: string, dt?: DialogType): ResultModalDialogData {
    return ResultModalDialogDataImpl.getResultModalDialgData(msg, hnt, dt, 'Schließen', null);
  }

  public static getResultModalDialgData(msg?: string, hnt?: string, dt?: DialogType, btn?: string, lnk?: string): ResultModalDialogData {
    return this.getResultModalDialgDataViewMode(msg, hnt, dt, btn, lnk);
  }

  public static getResultModalDialgDataViewMode(msg?: string, hnt?: string, dt?: DialogType, btn?: string, lnk?: string, viewMode?: ViewMode, withRouting?: boolean): ResultModalDialogData {
    return {
      message: msg,
      hint: hnt,
      button: btn,
      link: lnk,
      dialogType: dt,
      viewMode: viewMode,
      withRouting: withRouting,
    };
  }
}
