/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status-Objekt zur Freigabe einer Störung.
 */
export interface ApprovalStateTO { 
    approved?: boolean;
    remarks?: string;
    shortTerm?: boolean;
}

