/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrioritySelection = 'SHORT_TERM' | 'MEDIUM_TERM' | 'ANY';

export const PrioritySelection = {
    ShortTerm: 'SHORT_TERM' as PrioritySelection,
    MediumTerm: 'MEDIUM_TERM' as PrioritySelection,
    Any: 'ANY' as PrioritySelection
};

