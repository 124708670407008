/**
 * LightOn Technical Data Service API
 * Folgende API werden dazu verwendet technische Daten zur Leuchtstelle bzw. Schaltschrank aus einem externen System zu ermitteln.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    TechnicalServiceInterface
} from './technical.serviceInterface';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { CommuneTO, ControlCabinetTechnicalData, LightingPointTechnicalData } from '../../generated/v2/incident';



@Injectable({
  providedIn: 'root'
})
export class TechnicalService implements TechnicalServiceInterface {

    protected basePath = 'http://localhost:8181';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    lionServiceBasePath;
    lionBasePath;
    incidentBasePath;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
        this.lionServiceBasePath = EnvironmentService.getEnvironment().lionService;
        this.lionBasePath = EnvironmentService.getEnvironment().lionBasePath;
        this.incidentBasePath = EnvironmentService.getEnvironment().incidentBasePath;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Technische Daten zum übergebenen Schaltschrank lesen.
     * API um externe Daten zum Schaltschrank auszulesen.
     * @param controlCabinetNo Vollständige Schaltschranknummer.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo: string, xRequestId?: string, xExternalId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ControlCabinetTechnicalData>;
    public findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo: string, xRequestId?: string, xExternalId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ControlCabinetTechnicalData>>;
    public findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo: string, xRequestId?: string, xExternalId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ControlCabinetTechnicalData>>;
    public findTechnicalControlCabinetByControlCabinetNo(controlCabinetNo: string, xRequestId?: string, xExternalId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (controlCabinetNo === null || controlCabinetNo === undefined) {
            throw new Error('Required parameter controlCabinetNo was null or undefined when calling findTechnicalControlCabinetByControlCabinetNo.');
        }

        let headers = this.defaultHeaders;
        if (xRequestId !== undefined && xRequestId !== null) {
            headers = headers.set('x-request-id', String(xRequestId));
        }
        if (xExternalId !== undefined && xExternalId !== null) {
            headers = headers.set('x-external-id', String(xExternalId));
        }

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["ApiKeyAuth"] || this.configuration.apiKeys["x-api-key"];
            if (key) {
                headers = headers.set('x-api-key', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ControlCabinetTechnicalData>(`${this.configuration.basePath}/technicaldata/controlcabinets/${encodeURIComponent(String(controlCabinetNo))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technische Daten zum übergebenen Lichtpunkt lesen.
     * API um externe Daten zum Lichtpunkt auszulesen.
     * @param lightingNo Vollständige Leuchtstellennummer.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public findTechnicalLightpointByLightingNo(lightingNo: string, xRequestId?: string, xExternalId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LightingPointTechnicalData>;
     public findTechnicalLightpointByLightingNo(lightingNo: string, xRequestId?: string, xExternalId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LightingPointTechnicalData>>;
     public findTechnicalLightpointByLightingNo(lightingNo: string, xRequestId?: string, xExternalId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LightingPointTechnicalData>>;
     public findTechnicalLightpointByLightingNo(lightingNo: string, xRequestId?: string, xExternalId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
         if (lightingNo === null || lightingNo === undefined) {
             throw new Error('Required parameter lightingNo was null or undefined when calling findTechnicalLightpointByLightingNo.');
         }

         let headers = this.defaultHeaders;
         if (xRequestId !== undefined && xRequestId !== null) {
             headers = headers.set('x-request-id', String(xRequestId));
         }
         if (xExternalId !== undefined && xExternalId !== null) {
             headers = headers.set('x-external-id', String(xExternalId));
         }

         // authentication (ApiKeyAuth) required
         if (this.configuration.apiKeys) {
             const key: string | undefined = this.configuration.apiKeys["ApiKeyAuth"] || this.configuration.apiKeys["x-api-key"];
             if (key) {
                 headers = headers.set('x-api-key', key);
             }
         }

         let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
         if (httpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
             const httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         }
         if (httpHeaderAcceptSelected !== undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }


         let responseType: 'text' | 'json' = 'json';
         if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
             responseType = 'text';
         }

         return this.httpClient.get<LightingPointTechnicalData>(`${this.configuration.basePath}/technicaldata/lightingpoints/${encodeURIComponent(String(lightingNo))}`,
             {
                 responseType: <any>responseType,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
     * Finde Kommune
     * @param params
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCommune(params?: any, xRequestId?: string, xExternalId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CommuneTO[]>;
    public findCommune(params?: any, xRequestId?: string, xExternalId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CommuneTO[]>>;
    public findCommune(params?: any, xRequestId?: string, xExternalId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CommuneTO[]>>;
    public findCommune(params?: any, xRequestId?: string, xExternalId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        let headers = this.defaultHeaders;
        if (xRequestId !== undefined && xRequestId !== null) {
            headers = headers.set('x-request-id', String(xRequestId));
        }
        if (xExternalId !== undefined && xExternalId !== null) {
            headers = headers.set('x-external-id', String(xExternalId));
        }

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["ApiKeyAuth"] || this.configuration.apiKeys["x-api-key"];
            if (key) {
                headers = headers.set('x-api-key', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        let queryParams = new URLSearchParams();
        if (params?.zipCode) queryParams.set('zipCode', params.zipCode);
        if (params?.city) queryParams.set('city', params.city);
        if (params?.ags) queryParams.set('ags', params.ags);

        return this.httpClient.get<CommuneTO[]>(`${this.incidentBasePath}/communes?${queryParams.toString()}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
