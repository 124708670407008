/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Zustand der Leuchtstelle
 */
export type LightPointState = 'INCIDENT_RESOLVED' | 'INCIDENT_RESOLVED_BUT_NEEDS_MORE_WORK' | 'INCIDENT_PERSISTS';

export const LightPointState = {
    Resolved: 'INCIDENT_RESOLVED' as LightPointState,
    ResolvedButNeedsMoreWork: 'INCIDENT_RESOLVED_BUT_NEEDS_MORE_WORK' as LightPointState,
    Persists: 'INCIDENT_PERSISTS' as LightPointState
};

