import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IncdntService } from 'app/incidents/services/incdnt.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserInfoService } from '../common/services/user.info.service';
import {
  loadSystemUsersFailedResponse,
  loadSystemUsersRequest,
  loadSystemUsersSuccessfulResponse,
  loadUserSettingsFailedResponse,
  loadUserSettingsRequest,
  loadUserSettingsSuccessfulResponse,
} from './shared.actions';

@Injectable()
export class SharedEffects {

  public loadSystemUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSystemUsersRequest),
      switchMap((value, index) => {
        return this.userInfoService.getSystemUsers(value.internal).pipe(
          map(systemUsers => loadSystemUsersSuccessfulResponse({ systemUsers })),
          catchError(error => of(loadSystemUsersFailedResponse({ error })))
        );
      })
    );
  });

  public loadUserSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserSettingsRequest),
      switchMap((value, index) => {
        return this.userInfoService.getSystemUserSettings(value.userId, value.internal).pipe(
          map(userSettings => loadUserSettingsSuccessfulResponse({ userSettings })),
          catchError(error => of(loadUserSettingsFailedResponse({ error })))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private incdntService: IncdntService,
    private userInfoService: UserInfoService
  ) { }
}
