/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NetArea } from './netArea';
import { SymptomType } from './symptomType';


/**
 * Meldung einer Störung aus dem Bürgerformular
 */
export interface IncidentNotification { 
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo: string;
    /**
     * Vollständige Schaltstellennummer.
     */
    controlCabinetNo?: string;
    netArea: NetArea;
    /**
     * Zuordnung der Störung zu einem bestimmten Kunden innerhalb eines Netzgebiets. (AGS, Zweckverband ZV)
     */
    customerScope: string;
    /**
     * PLZ der Störungsmeldung
     */
    zipCode: string;
    /**
     * Ort der Störung
     */
    city: string;
    /**
     * Ortsteil
     */
    cityDistrict?: string;
    /**
     * Straße der Störung
     */
    street: string;
    /**
     * Hausnummer der Störungsmeldung
     */
    houseNo?: string;
    /**
     * Liste der gefundenen Symptome
     */
    symptoms: Set<SymptomType>;
    /**
     * Anmerkungen zur Störungsmeldung vom Bürger
     */
    remarksCitizen?: string;
    recognitionDateTime?: string;
}
export namespace IncidentNotification {
}


