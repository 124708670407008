/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * LiOn state of the incident
 */
export type LiOnState = 'APPROVAL_PENDING' | 'REJECTED' | 'RECEIVED' | 'IN_PROGRESS' | 'FIXED_WITH_FOLLOW_UP' | 'COMPLETED';

export const LiOnState = {
    ApprovalPending: 'APPROVAL_PENDING' as LiOnState,
    Rejected: 'REJECTED' as LiOnState,
    Received: 'RECEIVED' as LiOnState,
    InProgress: 'IN_PROGRESS' as LiOnState,
    FixedWithFollowUp: 'FIXED_WITH_FOLLOW_UP' as LiOnState,
    Completed: 'COMPLETED' as LiOnState
};

