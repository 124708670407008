import { createAction, props } from '@ngrx/store';
import { SystemUser } from '../common/models/systemUser';
import { SystemUserSettings } from '../common/models/systemUserSettings';
import { NetArea } from '../api/generated/v2/incident';

export enum SharedActionTypes {
  LOAD_NET_AREA_REQUEST = '[Shared] load net area request',
  LOAD_NET_AREA_SUCCESSFUL_RESPONSE = '[Shared] load net area successful response',
  LOAD_NET_AREA_FAILED_RESPONSE = '[Shared] load net area failed response',
  LOAD_SYSTEM_USERS_REQUEST = '[Shared] load system users request',
  LOAD_SYSTEM_USERS_SUCCESSFUL_RESPONSE = '[Shared] load system users successful response',
  LOAD_SYSTEM_USERS_FAILED_RESPONSE = '[Shared] load system users failed response',
  LOAD_USER_SETTINGS_REQUEST = '[Shared] load user settings request',
  LOAD_USER_SETTINGS_SUCCESSFUL_RESPONSE = '[Shared] load user settings successful response',
  LOAD_USER_SETTINGS_FAILED_RESPONSE = '[Shared] load user settings failed response'
}

export const loadNetAreaRequest = createAction(
  SharedActionTypes.LOAD_NET_AREA_REQUEST
);
export const loadNetAreaSuccessfulResponse = createAction(
  SharedActionTypes.LOAD_NET_AREA_SUCCESSFUL_RESPONSE,
  props<{ netAreas: { [key: string]: NetArea } }>()
);
export const loadNetAreaFailedResponse = createAction(
  SharedActionTypes.LOAD_NET_AREA_FAILED_RESPONSE,
  props<{ error: string }>()
);

export const loadSystemUsersRequest = createAction(
  SharedActionTypes.LOAD_SYSTEM_USERS_REQUEST,
  props<{ internal: boolean }>()
);
export const loadSystemUsersSuccessfulResponse = createAction(
  SharedActionTypes.LOAD_SYSTEM_USERS_SUCCESSFUL_RESPONSE,
  props<{ systemUsers: SystemUser[] }>()
);
export const loadSystemUsersFailedResponse = createAction(
  SharedActionTypes.LOAD_SYSTEM_USERS_FAILED_RESPONSE,
  props<{ error: string }>()
);

export const loadUserSettingsRequest = createAction(
  SharedActionTypes.LOAD_USER_SETTINGS_REQUEST,
  props<{ userId: string, internal: boolean }>()
);
export const loadUserSettingsSuccessfulResponse = createAction(
  SharedActionTypes.LOAD_USER_SETTINGS_SUCCESSFUL_RESPONSE,
  props<{ userSettings: SystemUserSettings }>()
);
export const loadUserSettingsFailedResponse = createAction(
  SharedActionTypes.LOAD_USER_SETTINGS_FAILED_RESPONSE,
  props<{ error: string }>()
);
