/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrioritySelection } from './prioritySelection';
import { AutomatedOrderCreation } from './automatedOrderCreation';
import { LightingTypeSelection } from './lightingTypeSelection';


export interface IncidentProcessingCriteriaTO { 
    /**
     * CustomerScope(AGS/ZV) für die die Aktion angewandt wird
     */
    customerScope: string;
    priority: PrioritySelection;
    lightingType: LightingTypeSelection;
    /**
     * Die zum customerScope gehörige Stadt
     */
    city: string;
    action?: AutomatedOrderCreation;
}
export namespace IncidentProcessingCriteriaTO {
}


