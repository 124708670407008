/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Melder Kategorie
 */
export type ReporterType = 'CITIZEN' | 'MUNICIPALITY' | 'INTERN' | 'COMPANY' | 'OTHER';

export const ReporterType = {
    Citizen: 'CITIZEN' as ReporterType,
    Municipality: 'MUNICIPALITY' as ReporterType,
    Intern: 'INTERN' as ReporterType,
    Company: 'COMPANY' as ReporterType,
    Other: 'OTHER' as ReporterType
};

