/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentStatus } from './incidentStatus';
import { Activity } from './activity';


export interface CreateNonSapOrderFeedbackTO { 
    /**
     * The person who worked on the order
     */
    worker?: string;
    /**
     * Activities which have been carried out
     */
    protocolActivity?: Set<Activity>;
    /**
     * Additional activities as freetext
     */
    protocolActivityOther?: string;
    /**
     * Link to SAP with additional documents
     */
    protocolLink?: string;
    /**
     * Additional freetext
     */
    protocolText?: string;
    /**
     * Material that was used during repair
     */
    usedMaterials?: Array<string>;
    executedAt: string;
    receivedAt?: string;
    incidentStatus: IncidentStatus;
}
export namespace CreateNonSapOrderFeedbackTO {
}


