import { Injectable } from '@angular/core';
import { environment as dev } from 'environments/environment';
import { environment as prod } from 'environments/environment.prod';
import { environment as stage } from 'environments/environment.stage';

export enum Environment {
  LOCAL = 'localhost',
  LOCAL_IP = '127.0.0.1',
  DEV = 'dienstleistung-light-on-dev.enbw.cloud',
  DEV_LEGACY = 'lighton-frontend-develop.s3-website.eu-central-1.amazonaws.com',
  STAGING = 'dienstleistung-light-on-staging.enbw.cloud',
  STAGING_LEGACY = 'lighton-frontend-test.s3-website.eu-central-1.amazonaws.com',
  PROD = 'dienstleistung-light-on-prod.enbw.cloud',
  PROD_LEGACY = 'lighton-frontend.s3-website.eu-central-1.amazonaws.com',
}

@Injectable()
export class EnvironmentService {
  public static isProduction(): boolean {
    const host: string = window.location.hostname;
    return host === Environment.PROD
      || host === Environment.PROD_LEGACY;
  }

  public static isStage(): boolean {
    const host: string = window.location.hostname;
    return host === Environment.STAGING
      || host === Environment.STAGING_LEGACY;
  }

  public static isDev(): boolean {
    const host: string = window.location.hostname;
    return host === Environment.DEV
      || host === Environment.DEV_LEGACY;
  }

  public static isLocal(): boolean {
    const host: string = window.location.hostname;
    return host === Environment.LOCAL
      || host === Environment.LOCAL_IP;
  }

  public static getEnvironment(): typeof dev {
    const host: string = window.location.hostname;
    const environments = {
      [Environment.LOCAL]: dev,
      [Environment.LOCAL_IP]: dev,
      [Environment.DEV]: dev,
      [Environment.DEV_LEGACY]: dev,
      [Environment.STAGING]: stage,
      [Environment.STAGING_LEGACY]: stage,
      [Environment.PROD]: prod,
      [Environment.PROD_LEGACY]: prod,
    };
    if (host in environments) {
      return environments[host];
    }
    return dev;
  }
}
