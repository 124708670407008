import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: string, ...args: any[]): string {
    if (!value) {
      return '-';
    }

    // most dates currently arrive from the backend in UTC format but
    // are missing the timezone representation (i.e. yyyy-MM-ddTHH:mm:ss)
    // so we add the 'Z' to be able to show the right local time.
    // the following measure is taken in case some date arrive in
    // the correct UTC format (i.e. yyyy-MM-ddTHH:mm:ssZ) or yyyy-MM-dd, etc...
    const date = new Date(value + 'Z');
    const formattedDate = isNaN(date.getTime()) ? value : date;

    return this.datePipe.transform(formattedDate, 'dd.MM.yyyy HH:mm');

  }
}
