/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Melderdaten der Störung, soweit vorhanden
 */
export interface FaultReport2Reporter { 
    /**
     * Anrede des Melders
     */
    salutation?: string;
    /**
     * Vorname des Melders
     */
    firstName?: string;
    /**
     * Nachname des Melders
     */
    lastName?: string;
    /**
     * Email Adresse des Melders
     */
    email?: string;
    /**
     * Telefonnummer des Melders
     */
    phoneNo0?: string;
    /**
     * Firma des Melders
     */
    company?: string;
    /**
     * Abteilung des Melders
     */
    department?: string;
    /**
     * Straße des Melders
     */
    street?: string;
    /**
     * Hausnummer des Melders
     */
    houseNo?: string;
    /**
     * PLZ des Melders
     */
    zipCode?: string;
    /**
     * Ort des Melders
     */
    city?: string;
    /**
     * Teilort des Melders
     */
    cityDistrict?: string;
}

