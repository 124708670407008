import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { NotFoundComponent } from './shared/common/components/not-found/not-found.component';

const routes: Routes = [
  // user routes
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then((module) => module.AuthModule),
      },
      {
        path: 'incident',
        loadChildren: () =>
          import('./incidents/incidents.module').then(
            (module) => module.IncidentsModule
          ),
      },
      {
        path: 'shared',
        loadChildren: () =>
          import('./shared/shared.module').then(
            (module) => module.SharedModule
          ),
      },
      {
        path: 'client-settings',
        loadChildren: () =>
          import('./client-settings/client-settings.module').then(
            (m) => m.ClientSettingsModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./incidents/incidents.module').then(
            (module) => module.IncidentsModule
          ),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
