/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Arbeitsstand der Störung
 */
export type ProcessingState = 'APPROVAL_PENDING' | 'REJECTED' | 'NEW' | 'INPROGRESS' | 'DELETED' | 'ORDER_PARTIALLY_COMPLETED' | 'ORDER_COMPLETED' | 'COMPLETED';

export const ProcessingState = {
    ApprovalPending: 'APPROVAL_PENDING' as ProcessingState,
    Rejected: 'REJECTED' as ProcessingState,
    New: 'NEW' as ProcessingState,
    Inprogress: 'INPROGRESS' as ProcessingState,
    Deleted: 'DELETED' as ProcessingState,
    OrderPartiallyCompleted: 'ORDER_PARTIALLY_COMPLETED' as ProcessingState,
    OrderCompleted: 'ORDER_COMPLETED' as ProcessingState,
    Completed: 'COMPLETED' as ProcessingState
};

