/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Eingangskanal
 */
export type InputChannel = 'CENTRAL_OFFICE' | 'LION' | 'MINZE_WFM' | 'KOK' | 'FAX' | 'PHONE' | 'EMAIL' | 'STOERUNG24' | 'INTERNAL' | 'OTHER';

export const InputChannel = {
    CentralOffice: 'CENTRAL_OFFICE' as InputChannel,
    Lion: 'LION' as InputChannel,
    MinzeWfm: 'MINZE_WFM' as InputChannel,
    Kok: 'KOK' as InputChannel,
    Fax: 'FAX' as InputChannel,
    Phone: 'PHONE' as InputChannel,
    Email: 'EMAIL' as InputChannel,
    Stoerung24: 'STOERUNG24' as InputChannel,
    Internal: 'INTERNAL' as InputChannel,
    Other: 'OTHER' as InputChannel
};

