/**
 * LightOn Technical Data Service API
 * Folgende API werden dazu verwendet technische Daten zur Leuchtstelle bzw. Schaltschrank aus einem externen System zu ermitteln.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Configuration }                                     from '../configuration';
import { DiConUserResultTO, UserSettingsTO } from '../../generated/v2/incident';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;


    /**
     * Einstellungen für einen Benutzer löschen
     * API um die Einstellungen eines Benutzers zu löschen.
     * @param email
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    deleteUserSettings(email: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<boolean>;

    /**
     * Benutzer aus der AD-Gruppe der Steuerungsstelle lesen.
     * API um die Benutzer der Steuerungsstelle aus dem AD zu lesen.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    findDiconUsersOfControlOrganization(xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<DiConUserResultTO>;

    /**
     * Benutzerfoto anhand der Email lesen
     * API um das Foto eines Benutzers abzurufen.
     * @param email
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    getUserPhoto(email: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * Einstellungen für einen Benutzer finden
     * API um die Einstellungen eines Benutzers abzurufen.
     * @param email
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    getUserSettings(email: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<object>;

    /**
     * Einstellungen für einen Benutzer speichern
     * API um die Einstellungen eines Benutzers zu speichern.
     * @param email
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param userSettingsTO
     */
    updateUserSettings(email: string, xRequestId?: string, xExternalId?: string, userSettingsTO?: UserSettingsTO, extraHttpRequestParams?: any): Observable<UserSettingsTO>;

}
