import { UploadedDocumentListEntry } from '../models/uploadedDocumentListEntry';

export class UploadedDocumentUtils {
  public static convertToObject(elem: any): UploadedDocumentListEntry {
    return new UploadedDocumentListEntry(
      elem.docId,
      elem.fileName,
      elem.uploadTimestamp,
      elem.objectUrl,
      elem.objectKey,
      elem.contentLength
    );
  }
}
