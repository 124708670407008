/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NetArea } from './netArea';
import { SymptomType } from './symptomType';


/**
 * Basis Daten der Störung
 */
export interface FaultReport1 { 
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo?: string;
    /**
     * Vollständige Schaltstellennummer.
     */
    controlCabinetNo?: string;
    /**
     * PLZ der Störungsmeldung
     */
    zipCode?: string;
    /**
     * Ort der Störung
     */
    city: string;
    /**
     * Ortsteil
     */
    cityDistrict?: string;
    /**
     * Straße der Störung
     */
    street: string;
    /**
     * Hausnummer der Störungsmeldung
     */
    houseNo?: string;
    /**
     * Liste der gefundenen Symptome
     */
    symptoms: Set<SymptomType>;
    /**
     * Symptom Beschreibung für Sonstige
     */
    symptomOther?: string;
    /**
     * Weitere Anmerkungen zur Störungsmeldung
     */
    remarksMunicipality?: string;
    recognitionDateTime?: string;
    /**
     * Weitere Anmerkungen zum Ort der Störungsmeldung
     */
    furtherRemarks?: string;
    netArea?: NetArea;
}
export namespace FaultReport1 {
}


