export * from './automation.service';
import { AutomationService } from './automation.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './faultReport.service';
import { FaultReportService } from './faultReport.service';
export * from './incident.service';
import { IncidentService } from './incident.service';
export * from './luxdata.service';
import { LuxdataService } from './luxdata.service';
export * from './maintenance.service';
import { MaintenanceService } from './maintenance.service';
export * from './orderfeedback.service';
import { OrderfeedbackService } from './orderfeedback.service';
export * from './orders.service';
import { OrdersService } from './orders.service';
export * from './private.service';
import { PrivateService } from './private.service';
export * from './stateHistory.service';
import { StateHistoryService } from './stateHistory.service';
export const APIS = [AutomationService, DocumentService, FaultReportService, IncidentService, LuxdataService, MaintenanceService, OrderfeedbackService, OrdersService, PrivateService, StateHistoryService];
