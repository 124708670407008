import { Pipe, PipeTransform } from '@angular/core';
import { Priority } from '../models/enums/priority';

@Pipe({
  name: 'priorityLabel'
})
export class PriorityLabelPipe implements PipeTransform {

  transform(value: Priority, args: 'default' | 'semantic' = 'default'): string {
    switch (value) {
      case Priority.IMMEDIATELY:
        return 'Sofort';
      case Priority.MEDIUM_TERM:
        return args === 'semantic' ? 'Reparaturfahrt' : 'Mittelfristig';
      case Priority.SHORT_TERM:
        return 'Kurzfristig';
      case Priority.SMALL_ORDER:
        return 'Kleinauftrag';
      case Priority.ANY:
        return 'Alle';
      default:
        return '-';
    }
  }

}
