import { Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { User } from 'app/shared/common/models/user';
import { loggedInUserSelector } from 'app/auth/state/auth.reducers';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { Observable } from 'rxjs';

@Directive({
  selector: '[appRbacShow]'
})
export class RbacShowDirective {

  loggedInUser$: Observable<User> = this.store.select(loggedInUserSelector);
  constructor(private el: ElementRef, private store: Store<AppState>) {

    this.loggedInUser$.subscribe(user => {
      if (!UserRoleUtils.hasRoleOperator(user)) {
        this.el.nativeElement.style.display = 'none';
      }
    });
  }

}
