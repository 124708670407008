import { DocumentUploadModalData } from './document-upload-modal.data';

export class DocumentUploadModalDataImpl implements DocumentUploadModalData {
  message: string;
  hint: string;
  success: boolean;

  public static getDefaultUploadModalData(success: boolean): DocumentUploadModalData {
    return {
      message: `Upload ${!!success ? '' : 'nicht'} erfolgreich${!!success ? '' : '!'}`,
      hint: `Die Datei wurde ${!!success ? '' : 'nicht'} erfolgreich hochgeladen.`,
      success,
    };
  }
}
