export enum Priority {
  IMMEDIATELY = 'IMMEDIATELY',
  SHORT_TERM = 'SHORT_TERM',
  MEDIUM_TERM = 'MEDIUM_TERM',
  SMALL_ORDER = 'SMALL_ORDER',
  ANY = 'ANY'
}

export const PRIORITIES = [
  Priority.IMMEDIATELY,
  Priority.SHORT_TERM,
  Priority.MEDIUM_TERM,
  Priority.SMALL_ORDER,
  Priority.ANY
]