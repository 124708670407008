/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentRelationType } from './incidentRelationType';
import { ReporterTO } from './reporterTO';
import { ReporterType } from './reporterType';
import { IncidentStatus } from './incidentStatus';
import { IssueType } from './issueType';
import { Priority } from './priority';
import { InputChannel } from './inputChannel';
import { ReporterRole } from './reporterRole';
import { SystemType } from './systemType';
import { NetArea } from './netArea';
import { SymptomType } from './symptomType';
import { AssignedParty } from './assignedParty';


export interface CreateIncidentTOV2 { 
    /**
     * UUID der Störung
     */
    uuid?: string;
    /**
     * Beinhaltet ein Identifikator des Kunden, dem die Leuchtstelle zugeordnet ist. I.d.R. ein amtlicher Gemeindeschlüssel (AGS).
     */
    customerScope: string;
    /**
     * Ort der Störungsmeldung
     */
    city: string;
    /**
     * Straße der Störungsmeldung
     */
    street: string;
    incidentStatus: IncidentStatus;
    priority: Priority;
    priorityDueDateTime?: string;
    reportingTimestamp: string;
    /**
     * Beschwerdeflag
     */
    hasComplaint?: boolean;
    netArea?: NetArea;
    /**
     * Leucht- oder Schaltstellennummer der Störung
     */
    technicalId?: string;
    /**
     * Zugewiesener Bearbeiter der Störungsmeldung
     */
    assignee?: string;
    assignedParty?: AssignedParty;
    relationType?: IncidentRelationType;
    /**
     * Verweis auf die Parent Störung, falls es sich um ein Duplikat handelt
     */
    parentId?: string;
    orderDueDateTime?: string;
    completedAtDateTimeUtc?: string;
    /**
     * Die Email-Adresse des Anwenders, der den Incident approved oder rejected hat
     */
    approvalDecisionByEmail?: string;
    /**
     * Der Name des Anwenders, der den Incident approved oder rejected hat
     */
    approvalDecisionByName?: string;
    /**
     * SofortMassnahmen erforderlich
     */
    urgentMeasure?: boolean;
    /**
     * Teilort der Störungsmeldung
     */
    cityDistrict?: string;
    /**
     * PLZ der Störungsmeldung
     */
    zipCode: string;
    /**
     * HausNr der Störungsmeldung
     */
    houseNo?: string;
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo?: string;
    /**
     * Vollständige Schaltstellennummer.
     */
    controlCabinetNo?: string;
    /**
     * List of symptoms
     */
    symptoms: Set<SymptomType>;
    /**
     * Symptom Beschreibung für Sonstige
     */
    symptomOther?: string;
    systemType?: SystemType;
    issueType: IssueType;
    /**
     * Weitere Anmerkungen für den Monteur 
     */
    remarksForMechanic?: string;
    /**
     * Melder ist anonym
     */
    anonymous?: boolean;
    inputChannel: InputChannel;
    reporterType: ReporterType;
    reporterRole?: ReporterRole;
    /**
     * Melder Rolle sonstiges
     */
    reporterRoleOther?: string;
    /**
     * Weitere Anmerkungen zum Ort der Störungsmeldung
     */
    furtherRemarks?: string;
    reporter?: ReporterTO;
    /**
     * optionale UUID einer Parent-Störung, als dessen Duplikat diese Störung angesehen wird
     */
    duplicateOf?: string;
    faultTransferTimestampUtc?: string;
    /**
     * Störungs Nummer in Luxdata
     */
    luxdataFaultId?: string;
    /**
     * SAP Nummer der Meldung
     */
    sapNotificationNo?: string;
    dlMailNotificationDateTimeUtc?: string;
    /**
     * Anmerkungen der Kommune
     */
    remarksMunicipality?: string;
    /**
     * Anmerkungen des Bürgers
     */
    remarksCitizen?: string;
    recognitionDateTime?: string;
    completedAt?: string;
    /**
     * Liste von Dokumenten, welche temporär erzeugt und der Störung zugewiesen werden.
     */
    documents?: Array<string>;
}
export namespace CreateIncidentTOV2 {
}


