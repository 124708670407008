import { Pipe, PipeTransform } from '@angular/core';
import { ReporterRole } from '../../shared/api/generated/v2/incident';

@Pipe({
  name: 'statusCode'
})
export class StatusCodePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 400:
        return 'Bad Request - Der Server konnte die Anfrage aufgrund einer ungültigen Syntax nicht verarbeiten.';
      case 401:
        return 'Unauthorized - Der Client muss sich authentifizieren, um die angeforderte Antwort zu erhalten.';
      case 403:
        return 'Forbidden - Der Client hat keine Zugriffsrechte auf den Inhalt.';
      case 404:
        return 'Not Found - Der Server kann die angeforderte Ressource nicht finden.';
      case 500:
        return 'Interner Serverfehler - Der Server ist auf einen internen Fehler gestoßen, der ihn daran hinderte, die Anfrage zu erfüllen.';
      default:
        return '-';
    }
  }
}
