/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AssignedParty = 'NONE' | 'MASTER' | 'MECHANIC' | 'ORDER_CENTER' | 'SALES' | 'SUPPLIER' | 'CONSTRUCTION_COORDINATION' | 'PROJECT_PLANNING' | 'COMMUNE' | 'MATERIAL_ORDER';

export const AssignedParty = {
    None: 'NONE' as AssignedParty,
    Master: 'MASTER' as AssignedParty,
    Mechanic: 'MECHANIC' as AssignedParty,
    OrderCenter: 'ORDER_CENTER' as AssignedParty,
    Sales: 'SALES' as AssignedParty,
    Supplier: 'SUPPLIER' as AssignedParty,
    ConstructionCoordination: 'CONSTRUCTION_COORDINATION' as AssignedParty,
    ProjectPlanning: 'PROJECT_PLANNING' as AssignedParty,
    Commune: 'COMMUNE' as AssignedParty,
    MaterialOrder: 'MATERIAL_ORDER' as AssignedParty
};

