<div class="cls-div">
  <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
    <mat-icon style="margin: 0px;">close</mat-icon>
  </button>
</div>
<div class="div-stl" style="width: 400px;">
  <mat-icon id="icon" svgIcon="pen" style="height: 50px; width: 50px;"></mat-icon>
</div>
<h1 id="title" mat-dialog-title>{{data.message}}</h1>
<div class="col div-stl">
  <form id="form-report" [formGroup]="fileInfoForm">
    <div class="col-sm div-stl">
        <mat-form-field style="width: 400px;">
          <mat-label>Dateiname</mat-label>
          <input id="input-newFilename" #newFilename matInput required formControlName="newFilename" maxlength="50" (keyup)="determineFilenameExists()">
          <mat-error id="stdErr" *ngIf="!filenameExists">
            Bitte füllen Sie dieses Feld aus.
          </mat-error>
          <mat-error id="existsErr" *ngIf="!!filenameExists">
            Dieser Dateiname ist bereits vorhanden.
          </mat-error>
        </mat-form-field>
    </div>
    <div class="cnt" style="width: 400px;">
      <span>{{newFilename.value.length}}</span> / 50 Zeichen
    </div>
    <div mat-dialog-actions class="div-stl" style="padding-bottom: 30px; width: 400px;">
      <button id="saveBtn" class="nd-btn nd-btn-main btn-fnt btn-spc" style="width: 200px;" mat-button
        (click)="onSaveBtnClick()">Speichern</button>
    </div>
  </form>
</div>
