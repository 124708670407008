/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentResponseTO { 
    /**
     * Presigned GET URL to read the document from S3
     */
    presignedObjectUrl: string;
    /**
     * The object key of the document in S3
     */
    objectKey: string;
    /**
     * The original, unchanged filename as provided initially by the client when uploaded
     */
    originalFileName: string;
}

