import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogResult } from '../../models/enums/dialog-result/dialog.result';
import { DialogType } from '../../models/enums/dialog-type/dialog.type';
import { NavigationService } from '../../services/navigation.service';
import { ResultModalDialogData } from './result-modal-dialog.data';

@Component({
  selector: 'app-result-modal',
  templateUrl: './result-modal.component.html',
  styleUrls: ['./result-modal.component.scss']
})
export class ResultModalComponent {

  public DialogType = DialogType;

  constructor(
    public dialogRef: MatDialogRef<ResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResultModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
    private navigationService: NavigationService,
  ) {
    iconRegistry.addSvgIcon('checked', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/checked.svg'));
    iconRegistry.addSvgIcon('failed', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/lightning-filled.svg'));
    iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/info.svg'));
  }

  public onClsBtnClick(): void {
    if (!!this.data.withRouting) {
      this.navigationService.navigateWithQueryParamsAndFragment([this.data.link], this.data.viewMode);
    }
    this.dialogRef.close(DialogResult.CLOSED);
  }
}
