import { Injectable } from '@angular/core';
import { MaintenanceService, PlannedMaintenanceTO } from '../../api/generated/v2/incident';
import { EnvironmentService } from './env.service';
import { Observable } from 'rxjs';
import { isAfter, isBefore, isEqual } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PlannedMaintenanceService {

  constructor(
    private maintenanceService: MaintenanceService
  ) {
    this.maintenanceService.configuration.basePath = EnvironmentService.getEnvironment().incidentService;
  }

  public getPlannedMaintenances(): Observable<Array<PlannedMaintenanceTO>> {
    return this.maintenanceService.getPlannedMaintenances();
  }

  public getCurrentMaintenance(maintenances: Array<PlannedMaintenanceTO>, currentDateTime: Date) {
    for (const maintenance of maintenances) {
      const startDate = new Date(maintenance.startDateTime);
      const endDate = new Date(maintenance.endDateTime);
      if (this.isInPeriodOfTime(currentDateTime, startDate, endDate)) {
        return maintenance;
      }
    }

    return undefined;
  }

  isInPeriodOfTime(comparingDate: Date, startDate: Date, endDate: Date) {
    return (
      isEqual(comparingDate, startDate) ||
      isEqual(comparingDate, endDate) ||
      (isAfter(comparingDate, startDate) && isBefore(comparingDate, endDate))
    );
  }
}
