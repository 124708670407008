<mat-dialog-content style='max-height: 90%;'>
  <div class='cls-div'>
    <button id='clsBtn' class='cls-btn' mat-icon-button (click)='onClsBtnClick()'>
      <mat-icon style='margin: 0px;'>close</mat-icon>
    </button>
  </div>
  <div class='div-stl' style='width: 600px;'>
    <mat-icon id='icon' svgIcon='streetlight' style='height: 50px; width: 50px;'></mat-icon>
  </div>
  <h1 id='title' mat-dialog-title>Version Info</h1>
  <div class='col' style='width: 600px; height: 400px; overflow: auto; margin-bottom: 10px;'>
    <form id='form-report' [formGroup]='infoForm' style='width: 100%;'>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{envNameLbl}}</mat-label>
        <input id='envName-input' matInput formControlName='envName' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{buildIdLbl}}</mat-label>
        <input id='buildId-input' matInput formControlName='buildId' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{buildNumberLbl}}</mat-label>
        <input id='buildNo-input' matInput formControlName='buildNumber' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{sourceBranchNameLbl}}</mat-label>
        <input id='sourceBranchName-input' matInput formControlName='sourceBranchName' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{sourceVersionLbl}}</mat-label>
        <input id='sourceVersion-input' matInput formControlName='sourceVersion' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{requestedForLbl}}</mat-label>
        <input id='requestedFor-input' matInput formControlName='requestedFor' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{pipelineStartTimeLbl}}</mat-label>
        <input id='pipelineStartTime-input' matInput formControlName='pipelineStartTime' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{userAgentLbl}}</mat-label>
        <input id='userAgent-input' matInput formControlName='userAgent' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{currentTimestampLbl}}</mat-label>
        <input id='currentTimestamp-input' matInput formControlName='currentTimestamp' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{loggedInUserLbl}}</mat-label>
        <input id='loggedInUser-input' matInput formControlName='loggedInUser' readonly>
      </mat-form-field>
      <mat-form-field style='width: 100%;'>
        <mat-label>{{rolesLbl}}</mat-label>
        <input id='roles-input' matInput formControlName='roles' readonly>
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div class='div-stl' style='padding-bottom: 30px; width: 600px;'>
    <button id='spprtBtn' class='nd-btn nd-btn-outline btn-fnt btn-spc' style='width: 250px;' mat-raised-button>
      <a class='support-link' [href]="'mailto:' + supportEmail">Support kontaktieren</a>.
    </button>
    <button id='clpBrdBtn' class='nd-btn nd-btn-main btn-fnt btn-spc' style='width: 250px;'
            mat-raised-button [cdkCopyToClipboard]='getVersionInfoString()'>In Zwischenablage kopieren
    </button>
  </div>
</mat-dialog-actions>
