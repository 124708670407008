import { Injectable } from '@angular/core';
import { UserService } from 'app/shared/api/technical-data';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 } from 'uuid';
import { SystemUser } from '../models/systemUser';
import { SystemUserSettings } from '../models/systemUserSettings';
import { EnvironmentService } from './env.service';
import { SystemUserData } from './system.user.data';
import { DiConUserResultTO, UserSettingsTO } from '../../api/generated/v2/incident';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private userService: UserService) {
  }

  public getSystemUsers(internal?: boolean): Observable<SystemUser[]> {
    return !!internal ?
      of(SystemUserData.SYSTEM_USERS) :
      this.getUsersOfControlOrganization().pipe(
        map(diconUserResultTO => diconUserResultTO.data),
        map((diconUserTOArr) =>
        diconUserTOArr.map((diconUser) => new SystemUser(diconUser.mail, diconUser.firstname, diconUser.lastname))));
  }

  public getSystemUserSettings(userId: string, internal?: boolean): Observable<SystemUserSettings> {
    return !!internal ?
      of(SystemUserData.findSystemUserSettingsByEmail(userId)) :
      this.getUserSettings(userId).pipe(
        map(res => res as SystemUserSettings));
  }

  public getUsersOfControlOrganization(): Observable<DiConUserResultTO> {
    return this.userService.findDiconUsersOfControlOrganization(v4(), EnvironmentService.getEnvironment().name);
  }

  public getUserPhoto(email: string): Observable<any> {
    return this.userService.getUserPhoto(email, v4(), EnvironmentService.getEnvironment().name, 'body', false, {httpHeaderAccept: 'image/jpeg'});
  }

  public getUserSettings(userId: string): Observable<any> {
    return this.userService.getUserSettings(userId, v4(), EnvironmentService.getEnvironment().name);
  }

  public saveUserSettings(email: string, userSettings: UserSettingsTO): Observable<any> {
    return this.userService.updateUserSettings(email, v4(), EnvironmentService.getEnvironment().name, userSettings, 'response');
  }

  public deleteUserSettings(email: string): Observable<any> {
    return this.userService.deleteUserSettings(email, v4(), EnvironmentService.getEnvironment().name, 'response');
  }

  public getEmailFavorites(email: string): Observable<string[]> {
    return of(SystemUserData.findEmailFavoritesByEmail(email));
  }
}
