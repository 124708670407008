import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthState } from './auth/state/auth.reducers';
import { IncidentState } from './incidents/state/incidents.reducers';
import { SharedState } from './shared/state/shared.reducers';

export interface AppState {
  auth: AuthState;
  shared: SharedState;
  incidents: IncidentState;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth'], rehydrate: true })(reducer);
}
export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];
