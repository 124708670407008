/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentUploadRequestTO { 
    /**
     * Full qualified name of the file
     */
    fileName: string;
    /**
     * Content type of the file
     */
    mimeType: string;
    /**
     * Size of the file in bytes
     */
    fileSizeInBytes: number;
}

