/**
 * DiCon Incident Service API
 * Folgende API wird dazu verwendet um Störungen im #LightOn System zu bearbeiten.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Configuration }                                     from '../configuration';
import {
  DocumentUploadRequestTO,
  DocumentUploadResponseTO,
  IncidentDocumentCollectionTO,
  IncidentDocumentTO, UpdateDocumentTO,
} from '../../generated/v2/incident';



export interface DocumentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;


    /**
     * Füge ein Dokument der Meldung hinzu.
     * Erlaubt nur Dokumente von Typ [application/pdf, image/png, image/jpeg, application/vnd.ms-outlook]. Speichert diese mit der Störungs ID ab.
     * @param id
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param file
     * @param filename
     */
    createDocumentById(id: string, xRequestId?: string, xExternalId?: string, file?: Blob, filename?: string, extraHttpRequestParams?: any): Observable<IncidentDocumentTO>;

    /**
     * Löscht ein Dokument der Störung.
     * Das Dokument wird endgültig ohne Archivierung oder sonstiger Sicherung entfernt.
     * @param id Eindeutige Störung UUID
     * @param objKey Eindeutiger Dokument Key
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    deleteDocumentByKey(id: string, objKey: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Erstellt eine URL welche es ermöglicht unautorisiert Dokumente für eine Störung hochzuladen.
     * @param id UUID of incident or \&#39;tmp\&#39; for temporary uploads.
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param documentUploadRequestTO
     */
    generatePresignedDocumentUrl(id: string, xRequestId?: string, xExternalId?: string, documentUploadRequestTO?: DocumentUploadRequestTO, extraHttpRequestParams?: any): Observable<DocumentUploadResponseTO>;

    /**
     * Liest alle Dokumente zu der Störung aus
     * API für GUI basierte Störungseingaben
     * @param id
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     */
    getDocumentsByIncidentId(id: string, xRequestId?: string, xExternalId?: string, extraHttpRequestParams?: any): Observable<IncidentDocumentCollectionTO>;

    /**
     * Aktualisiert den logischen Namen eines Dokumentes.
     *
     * @param id Eindeutige Meldung UUID
     * @param objKey Eindeutiger Dokument Key
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param documentTO
     */
    updateDocumentByKey(id: string, objKey: string, xRequestId?: string, xExternalId?: string, documentTO?: UpdateDocumentTO, extraHttpRequestParams?: any): Observable<IncidentDocumentTO>;

}
