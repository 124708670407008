import { ObjUtils } from './obj.utils';

export class FileUtils {
  public static determineFileName(file: File, maxLength: number): string {
    if (ObjUtils.isNullOrUndefined(file)
      || ObjUtils.isNullOrUndefinedOrEmpty(file.name)
      || ObjUtils.isNullOrUndefinedOrZero(maxLength)
      || maxLength < 0) {
      return '';
    }
    if (maxLength + 1 > file.name.length) {
      return file.name;
    }
    const idxOfFileEndingDot: number = file.name.lastIndexOf('.');
    console.trace('last index of dot=', idxOfFileEndingDot);
    const fileEnding: string = file.name.substring(idxOfFileEndingDot);
    const fileNameWithoutEnding: string = file.name.substring(0, idxOfFileEndingDot);
    const newFileNameWithoutEnding: string = fileNameWithoutEnding.substring(0, maxLength - fileEnding.length);
    const newFileName = `${newFileNameWithoutEnding}${fileEnding}`;
    return newFileName;
  }

  public static determineFileSizeInMb(file: File, digits?: number): string {
    if (ObjUtils.isNullOrUndefined(file)
      || ObjUtils.isNullOrUndefinedOrZero(file.size)) {
      return '0';
    }
    const factor = 1024; // correct to be 1024
    return (file.size / (factor * factor)).toFixed(digits ? digits : 1);
  }

  public static openFileUrlInNewTab(fileUrl: string): void {
    if (ObjUtils.isNullOrUndefinedOrEmpty(fileUrl)) {
      return;
    }
    console.trace('Call redirect with file url=', fileUrl);
    window.open(fileUrl, '_blank', 'noreferrer');
  }
}
