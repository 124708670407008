/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateOtherMailOrderInfo } from './createOtherMailOrderInfo';
import { CreateWfmOrderOnlyInfo } from './createWfmOrderOnlyInfo';
import { CreateContractorMailOrderInfo } from './createContractorMailOrderInfo';
import { OrderType } from './orderType';
import { CreateContractorOrderOnlyInfo } from './createContractorOrderOnlyInfo';
import { CreateMaterialOrderInfo } from './createMaterialOrderInfo';
import { CreateOtherOrderInfo } from './createOtherOrderInfo';
import { CreateWfmOrderInfo } from './createWfmOrderInfo';
import { CreateMaterialMailOrderInfo } from './createMaterialMailOrderInfo';


/**
 * Abstract TO for order creation
 */
/**
 * @type CreateOrderTO
 * Abstract TO for order creation
 * @export
 */
export type CreateOrderTO = CreateContractorMailOrderInfo | CreateContractorOrderOnlyInfo | CreateMaterialMailOrderInfo | CreateMaterialOrderInfo | CreateOtherMailOrderInfo | CreateOtherOrderInfo | CreateWfmOrderInfo | CreateWfmOrderOnlyInfo;

