/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApprovalState = 'APPROVED' | 'REJECTED_NO_CONTRACT' | 'REJECTED_IMMINENT_DANGER';

export const ApprovalState = {
    Approved: 'APPROVED' as ApprovalState,
    RejectedNoContract: 'REJECTED_NO_CONTRACT' as ApprovalState,
    RejectedImminentDanger: 'REJECTED_IMMINENT_DANGER' as ApprovalState
};

