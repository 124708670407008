export type DialogResult = 'CLOSED' | 'SUCCESS' | 'FAIL' | 'CREATE' | 'CREATED' | 'MODIFY' | 'MODIFIED' | 'DELETE' | 'DELETED' | 'NAVIGATED' | 'FORWARD' | 'FORWARDED' | 'FORWARDING_TRIGGERED' | 'TAKEOVER' | 'TRANSMIT' | 'YES' | 'NO' | 'ASSIGNED' | 'ASSIGN_FAILED';

export const DialogResult = {
  CLOSED: 'CLOSED' as DialogResult,
  SUCCESS: 'SUCCESS' as DialogResult,
  FAIL: 'FAIL' as DialogResult,
  CREATE: 'CREATE' as DialogResult,
  CREATED: 'CREATED' as DialogResult,
  MODIFY: 'MODIFY' as DialogResult,
  MODIFIED: 'MODIFIED' as DialogResult,
  DELETE: 'DELETE' as DialogResult,
  DELETED: 'DELETED' as DialogResult,
  NAVIGATED: 'NAVIGATED' as DialogResult,
  FORWARD: 'FORWARD' as DialogResult,
  FORWARDED: 'FORWARDED' as DialogResult,
  FORWARDING_TRIGGERED: 'FORWARDING_TRIGGERED' as DialogResult,
  TAKEOVER: 'TAKEOVER' as DialogResult,
  TRANSMIT: 'TRANSMIT' as DialogResult,
  YES: 'YES' as DialogResult,
  NO: 'NO' as DialogResult,
  ASSIGNED: 'ASSIGNED' as DialogResult,
  ASSIGN_FAILED: 'ASSIGN_FAILED' as DialogResult,
};
