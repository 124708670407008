/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DerivedState = 'UNSPECIFIED' | 'APPROVAL_PENDING' | 'REJECTED' | 'NEW_FROM_KOK' | 'NEW_FROM_LION' | 'NEW_FROM_MINZE' | 'NEW_FROM_MAIL' | 'NEW_FROM_CENTRAL_OFFICE' | 'NEW_FROM_INTERNAL_VISITOR' | 'ORDER_MISSING' | 'ORDER_PENDING' | 'FEEDBACK_PENDING' | 'FEEDBACK_VALIDATED' | 'COMPLETED' | 'DELETED';

export const DerivedState = {
    Unspecified: 'UNSPECIFIED' as DerivedState,
    ApprovalPending: 'APPROVAL_PENDING' as DerivedState,
    Rejected: 'REJECTED' as DerivedState,
    NewFromKok: 'NEW_FROM_KOK' as DerivedState,
    NewFromLion: 'NEW_FROM_LION' as DerivedState,
    NewFromMinze: 'NEW_FROM_MINZE' as DerivedState,
    NewFromMail: 'NEW_FROM_MAIL' as DerivedState,
    NewFromCentralOffice: 'NEW_FROM_CENTRAL_OFFICE' as DerivedState,
    NewFromInternalVisitor: 'NEW_FROM_INTERNAL_VISITOR' as DerivedState,
    OrderMissing: 'ORDER_MISSING' as DerivedState,
    OrderPending: 'ORDER_PENDING' as DerivedState,
    FeedbackPending: 'FEEDBACK_PENDING' as DerivedState,
    FeedbackValidated: 'FEEDBACK_VALIDATED' as DerivedState,
    Completed: 'COMPLETED' as DerivedState,
    Deleted: 'DELETED' as DerivedState
};

