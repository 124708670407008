/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NetArea } from './netArea';


/**
 * Technische Daten eines Schaltschrankes
 */
export interface ControlCabinetTechnicalData { 
    /**
     * Vollständige Schaltschranknummer.
     */
    controlCabinetNo?: string;
    /**
     * Steuersignal
     */
    triggerDescription?: string;
    /**
     * Schrankart
     */
    controlCabinetType?: string;
    /**
     * Schaltschrank Beschreibung
     */
    controlCabinetDescription?: string;
    /**
     * Standort Nummer
     */
    locationNumber?: string;
    /**
     * Identifikator des Kunden
     */
    customerScope?: string;
    netArea?: NetArea;
}
export namespace ControlCabinetTechnicalData {
}


