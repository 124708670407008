import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IncidentService,
} from 'app/shared/api/incident';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import { IncidentService as IncidentServiceV2 } from 'app/shared/api/generated/v2/incident/api/incident.service';
import { OrdersService as OrdersServiceV2 } from 'app/shared/api/generated/v2/incident/api/orders.service';
import {
  BaseIncidentCollectionTOV2,
  CreateNonSapOrderFeedbackTO,
  CreateOrderTO,
  IncidentCollectionTOV2,
  IncidentTOV2,
  OrderTO,
  OrderfeedbackService,
  StatusGroup,
  ValidateSapOrderFeedbackTO,
  StateHistoryTO,
  StateHistoryService, CreateIncidentTOV2, AssignedParty, LuxdataArchiveTO
} from 'app/shared/api/generated/v2/incident';
import { ViewMode } from 'app/shared/common/models/enums/view-mode/view-mode';
import { IncidentFilterOptions } from '../../shared/api/incident/model/incidentFilterOptions';

@Injectable({
  providedIn: 'root',
})
export class IncdntService {
  constructor(
    private incidentService: IncidentService,
    private stateHistoryService: StateHistoryService,
    private incidentServiceV2: IncidentServiceV2,
    private orderServiceV2: OrdersServiceV2,
    private orderFeedbackService: OrderfeedbackService
  ) {
    this.orderServiceV2.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
    this.orderFeedbackService.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
    this.incidentServiceV2.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
    this.incidentService.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
    this.stateHistoryService.configuration.basePath =
      EnvironmentService.getEnvironment().incidentService;
  }

  public createIncident(incident: CreateIncidentTOV2): Observable<object> {
    return this.incidentServiceV2.createByIdV2(incident, 'response');
  }

  exportIncidents(
    filter?: IncidentFilterOptions,
    statusGroup?: StatusGroup
  ): Observable<HttpResponse<any>> {
    return this.incidentServiceV2.exportCsvV2(
      filter.assignedParty,
      filter.assignee != null && filter.assignee !== '' ? filter.assignee : null,
      filter.city != null && filter.city !== '' ? filter.city : null,
      filter.fromDate,
      filter.hasComplaint === false ? null : filter.hasComplaint,
      new Set(filter.incidentStatus),
      filter.isNotTransferredToLuxData === false ? null : filter.isNotTransferredToLuxData,
      filter.lightingOrControlCabinetNo != null &&
        filter.lightingOrControlCabinetNo !== ''
        ? filter.lightingOrControlCabinetNo
        : null,
      filter.netArea,
      filter.orderDueDatePassed,
      new Set(filter.orderTypes),
      null,
      new Set(filter.relationTypes),
      null,
      filter.sortBy,
      filter.sortDir,
      statusGroup,
      filter.street != null && filter.street !== '' ? filter.street : null,
      filter.toDate,
      'response'
    );
  }

  public updateIncident(id: string, incident: any): Observable<object> {
    return this.incidentServiceV2.updateByIdV2(id, incident, 'response');
  }

  public createOrder(incId: string, data: CreateOrderTO): Observable<object> {
    return this.orderServiceV2.createOrderV2(incId, data);
  }

  public getInProgressIncidents(
    filter?: IncidentFilterOptions,
    page?: number,
    size?: number
  ): Observable<BaseIncidentCollectionTOV2> {
    return this.incidentServiceV2.getInProgress(
      filter.assignedParty,
      filter.assignee != null && filter.assignee !== '' ? filter.assignee : null,
      filter.city != null && filter.city !== '' ? filter.city : null,
      filter.fromDate,
      filter.hasComplaint === false ? null : filter.hasComplaint,
      new Set(filter.incidentStatus),
      filter.isNotTransferredToLuxData === false ? null : filter.isNotTransferredToLuxData,
      filter.lightingOrControlCabinetNo != null &&
        filter.lightingOrControlCabinetNo !== ''
        ? filter.lightingOrControlCabinetNo
        : null,
      filter.netArea,
      filter.orderDueDatePassed,
      new Set(filter.orderTypes),
      page ? page : 0,
      new Set(filter.relationTypes),
      size ? size : 10,
      filter.sortBy,
      filter.sortDir,
      StatusGroup.InProgress,
      filter.street != null && filter.street !== '' ? filter.street : null,
      filter.toDate
    );
  }

  public getOwnInProgressIncidents(
    filter?: IncidentFilterOptions,
    page?: number,
    size?: number
  ): Observable<BaseIncidentCollectionTOV2> {
    return this.incidentServiceV2.getOwnInProgress(
      filter.assignedParty,
      filter.assignee != null && filter.assignee !== '' ? filter.assignee : null,
      filter.city != null && filter.city !== '' ? filter.city : null,
      filter.fromDate,
      filter.hasComplaint === false ? null : filter.hasComplaint,
      new Set(filter.incidentStatus),
      filter.isNotTransferredToLuxData === false ? null : filter.isNotTransferredToLuxData,
      filter.lightingOrControlCabinetNo != null &&
        filter.lightingOrControlCabinetNo !== ''
        ? filter.lightingOrControlCabinetNo
        : null,
      filter.netArea,
      filter.orderDueDatePassed,
      new Set(filter.orderTypes),
      page ? page : 0,
      new Set(filter.relationTypes),
      size ? size : 10,
      filter.sortBy,
      filter.sortDir,
      StatusGroup.InProgress,
      filter.street != null && filter.street !== '' ? filter.street : null,
      filter.toDate
    );
  }

  public getCompletedIncidents(
    filter?: IncidentFilterOptions,
    page?: number,
    size?: number
  ): Observable<BaseIncidentCollectionTOV2> {
    return this.incidentServiceV2.getCompleted(
      filter.assignedParty,
      filter.assignee != null && filter.assignee !== '' ? filter.assignee : null,
      filter.city != null && filter.city !== '' ? filter.city : null,
      filter.fromDate,
      filter.hasComplaint === false ? null : filter.hasComplaint,
      new Set(filter.incidentStatus),
      filter.isNotTransferredToLuxData === false ? null : filter.isNotTransferredToLuxData,
      filter.lightingOrControlCabinetNo != null &&
        filter.lightingOrControlCabinetNo !== ''
        ? filter.lightingOrControlCabinetNo
        : null,
      filter.netArea,
      filter.orderDueDatePassed,
      new Set(filter.orderTypes),
      page ? page : 0,
      new Set(filter.relationTypes),
      size ? size : 10,
      filter.sortBy,
      filter.sortDir,
      StatusGroup.Completed,
      filter.street != null && filter.street !== '' ? filter.street : null,
      filter.toDate
    );
  }

  public getIncidents(
    filter?: IncidentFilterOptions,
    page?: number,
    size?: number,
    viewMode?: ViewMode
  ): Observable<BaseIncidentCollectionTOV2> {
    if (viewMode === ViewMode.ALL) {
      return this.getInProgressIncidents(filter, page, size);
    } else if (viewMode === ViewMode.OWN) {
      return this.getOwnInProgressIncidents(filter, page, size);
    } else if (viewMode === ViewMode.ARCHIVED) {
      return this.getCompletedIncidents(filter, page, size);
    }
  }

  public getIncidentById(incUuid: string): Observable<IncidentTOV2> {
    return this.incidentServiceV2.getByIdV2(incUuid);
  }

  public getOrdersByIncidentId(id: string): Observable<Array<OrderTO>> {
    return this.orderServiceV2.getOrdersForIncidentV2(id);
  }

  public setIncidentToRejectedNoContract(incUuid: string): Observable<void> {
    return this.incidentServiceV2.approveIncident(incUuid, {
      approvalState: 'REJECTED_NO_CONTRACT',
    });
  }
  public setIncidentToRejectedImminentDanger(
    incUuid: string
  ): Observable<void> {
    return this.incidentServiceV2.approveIncident(incUuid, {
      approvalState: 'REJECTED_IMMINENT_DANGER',
    });
  }
  public setIncidentToApprovedByEnBW(incUuid: string): Observable<void> {
    return this.incidentServiceV2.approveIncident(incUuid, {
      approvalState: 'APPROVED',
    });
  }

  public deleteIncident(incUuid: string): Observable<HttpResponse<any>> {
    return this.incidentServiceV2.deleteByIdV2(incUuid, 'response');
  }

  public unlinkChildIncident(incUuid: string): Observable<object> {
    return this.incidentServiceV2.unlinkParentIncidentByIdV2(incUuid);
  }

  public getChildrenOfIncident(
    incidentUuid: string
  ): Observable<IncidentCollectionTOV2> {
    return this.incidentServiceV2.getByParentIdV2(incidentUuid);
  }

  public setAssignee(
    incidentUuid: string,
    assignee: string
  ): Observable<HttpResponse<any>> {
    return this.incidentServiceV2.setAssigneeV2(
      incidentUuid,
      {
        assignee,
      },
      'response'
    );
  }
  public setAssignedParty(
    incidentUuid: string,
    assignedParty: AssignedParty
  ): Observable<HttpResponse<any>> {
    return this.incidentServiceV2.setAssignedPartyV2(
      incidentUuid,
      { assignedParty },
      'response'
    );
  }

  public transmitToLuxData(incUuid: string, docs?: string[]): Observable<any> {
    const luxdtaArchiveTO: LuxdataArchiveTO = { docObjectKeys: docs };

    return this.incidentServiceV2.sendIncidentToLuxdataV2(
      incUuid,
      luxdtaArchiveTO,
      'response'
    );
  }

  public editIncidentOrder(orderId: string, order: any): Observable<any> {
    return this.orderServiceV2.updateOrderDependingOnStateAndTypeV2(
      orderId,
      order,
      'response'
    );
  }

  public createOrderFeedback(
    orderId: string,
    orderFeedback: CreateNonSapOrderFeedbackTO
  ): Observable<any> {
    return this.orderFeedbackService.createFeedbackForManualOrder(
      orderId,
      orderFeedback
    );
  }

  public validateOrderFeedback(
    orderId: string,
    orderFeedbackId: number,
    validateFeedback: ValidateSapOrderFeedbackTO
  ): Observable<any> {
    return this.orderFeedbackService.validateFeedbackForSapOrder(
      orderId,
      orderFeedbackId,
      validateFeedback
    );
  }

  public setComplaintFlag(
    incUuid: string,
    hasComplaint: boolean
  ): Observable<any> {
    return this.incidentServiceV2.setComplaintFlagV2(
      incUuid,
      { hasComplaint },
      'response'
    );
  }

  public getStateHistoryV2ByIncidentId(incidentUuid: string): Observable<StateHistoryTO> {
    return this.stateHistoryService.getAllByIncidentIdV2(
      incidentUuid
    );
  }

  public setIncidentStateV2(
    incUuid: string,
    comment?: string
  ): Observable<object> {
    return this.stateHistoryService.addCommentByIdV2(
      incUuid,
      { comment },
      'response'
    );
  }

  public deleteIncidentProtocolV2(incUuid: string, processingId: number): Observable<object> {
    return this.stateHistoryService.deleteByStateIdV2(
      incUuid,
      processingId
    );
  }

  public updateIncidentProtocolV2(
    incUuid: string,
    processingID: number,
    processingHistory
  ): Observable<object> {
    return this.stateHistoryService.updateByStateIdV2(
      incUuid,
      processingID,
      processingHistory,
      'response'
    );
  }

  public forwardIncident(
    incUuid: string,
    mailText: string,
    recipients: string[],
    ccRecipients: string[],
    docObjectKeys?: string[]
  ): Observable<any> {
    return this.incidentService.sendMailNotification(
      incUuid,
      v4(),
      this.getEnvironmentName(),
      { recipients, mailText, docObjectKeys, ccRecipients },
      'response'
    );
  }

  private getEnvironmentName(): string {
    return EnvironmentService.getEnvironment().name;
  }
}
