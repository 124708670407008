import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginResultModalDialogData } from './login-result-modal-dialog.data';

@Component({
  selector: 'app-login-result-modal',
  templateUrl: './login-result-modal.component.html',
  styleUrls: ['./login-result-modal.component.scss'],
})
export class LoginResultModalComponent {
  constructor(
    public dialogRef: MatDialogRef<LoginResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoginResultModalDialogData,
    protected sanitizer: DomSanitizer,
    protected iconRegistry: MatIconRegistry,
    private router: Router
  ) {
    iconRegistry.addSvgIcon(
      'checked',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/checked.svg')
    );
    iconRegistry.addSvgIcon(
      'failed',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/failed.svg')
    );
  }

  onClsBtnClick(): void {
    if (!!this.data.link) {
      this.router.navigateByUrl(this.data.link);
    }
    this.dialogRef.close();
  }
}
