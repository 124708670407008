import { DocumentDeleteModalDialogData } from './document-delete-modal-dialog.data';

export class DocumentDeleteModalDialogDataImpl implements DocumentDeleteModalDialogData {
  message: string;
  hint: string;
  dlt: boolean;
  button: string;
  button2: string;
  objKey: string;

  public static getDefaultDeleteDialogData(objKey: string): DocumentDeleteModalDialogData {
    return {
      message: 'Dokument löschen',
      hint: 'Wollen Sie dieses Dokument wirklich löschen?',
      button: 'Nein',
      button2: 'Ja',
      dlt: true,
      objKey,
    };
  }
}
