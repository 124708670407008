import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { ObjUtils } from 'app/shared/common/services/obj.utils';
import { DocumentUploadResponseTO } from '../../api/generated/v2/incident';

@Injectable({
  providedIn: 'root',
})
export class DocUploadService {
  constructor(
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private logger: NGXLogger
  ) {
    // avoid using default http interceptor
    this.httpClient = new HttpClient(httpBackend);
  }

  public uploadDocumentForPresignedUrl(
    presignedUrl: string,
    document: File,
    documentUploadResponseTO: DocumentUploadResponseTO,
  ): Observable<any> {
    let hdrs: HttpHeaders = new HttpHeaders();

    hdrs = ObjUtils.isNullOrUndefinedOrEmpty(
        documentUploadResponseTO.encodedUniqueFileName
      )
        ? hdrs
        : hdrs.append(
            'x-amz-meta-name',
            documentUploadResponseTO.encodedUniqueFileName
          );
    hdrs = ObjUtils.isNullOrUndefinedOrEmpty(documentUploadResponseTO.docId)
        ? hdrs
        : (hdrs = hdrs.append(
            'x-amz-meta-doc-id',
            documentUploadResponseTO.docId
          ));
    hdrs = ObjUtils.isNullOrUndefinedOrEmpty(
        documentUploadResponseTO.incidentSourceUuid
      )
        ? hdrs
        : (hdrs = hdrs.append(
            'x-amz-meta-source-incident-uuid',
            documentUploadResponseTO.incidentSourceUuid
          ));
    hdrs = ObjUtils.isNullOrUndefinedOrEmpty(
        documentUploadResponseTO.uploadTimestamp
      )
        ? hdrs
        : (hdrs = hdrs.append(
            'x-amz-meta-created',
            documentUploadResponseTO.uploadTimestamp
        ));
    this.logger.trace('headers=', hdrs);
    const docObs: Observable<any> = this.httpClient
      .put(presignedUrl, document, {
        headers: hdrs,
        observe: 'response',
      })
      .pipe(share());
    return docObs;
  }
}
