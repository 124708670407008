/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ArchiveLuxdataResponseTO } from '../model/archiveLuxdataResponseTO';
// @ts-ignore
import { AssignedParty } from '../model/assignedParty';
// @ts-ignore
import { AssignedPartyTO } from '../model/assignedPartyTO';
// @ts-ignore
import { AssigneeTO } from '../model/assigneeTO';
// @ts-ignore
import { BaseIncidentCollectionTOV2 } from '../model/baseIncidentCollectionTOV2';
// @ts-ignore
import { ComplaintTO } from '../model/complaintTO';
// @ts-ignore
import { CreateIncidentTOV2 } from '../model/createIncidentTOV2';
// @ts-ignore
import { Direction } from '../model/direction';
// @ts-ignore
import { DuplicateResponseTO } from '../model/duplicateResponseTO';
// @ts-ignore
import { DuplicateResponseTOV2 } from '../model/duplicateResponseTOV2';
// @ts-ignore
import { IncidentApprovalStateTOV2 } from '../model/incidentApprovalStateTOV2';
// @ts-ignore
import { IncidentCollectionTOV2 } from '../model/incidentCollectionTOV2';
// @ts-ignore
import { IncidentRelationType } from '../model/incidentRelationType';
// @ts-ignore
import { IncidentStatus } from '../model/incidentStatus';
// @ts-ignore
import { IncidentTOV2 } from '../model/incidentTOV2';
// @ts-ignore
import { LuxdataArchiveTO } from '../model/luxdataArchiveTO';
// @ts-ignore
import { NetArea } from '../model/netArea';
// @ts-ignore
import { OrderType } from '../model/orderType';
// @ts-ignore
import { SaveIncidentResponseTOV2 } from '../model/saveIncidentResponseTOV2';
// @ts-ignore
import { StatusGroup } from '../model/statusGroup';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class IncidentService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Approve or reject an incident
     * Approve or reject an existing incident in DiCon
     * @param id UUID of the incident
     * @param incidentApprovalStateTOV2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveIncident(id: string, incidentApprovalStateTOV2?: IncidentApprovalStateTOV2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public approveIncident(id: string, incidentApprovalStateTOV2?: IncidentApprovalStateTOV2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public approveIncident(id: string, incidentApprovalStateTOV2?: IncidentApprovalStateTOV2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public approveIncident(id: string, incidentApprovalStateTOV2?: IncidentApprovalStateTOV2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling approveIncident.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/approvalState`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: incidentApprovalStateTOV2,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates an incident
     * API for GUI based incident inputs
     * @param createIncidentTOV2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createByIdV2(createIncidentTOV2?: CreateIncidentTOV2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DuplicateResponseTO>;
    public createByIdV2(createIncidentTOV2?: CreateIncidentTOV2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DuplicateResponseTO>>;
    public createByIdV2(createIncidentTOV2?: CreateIncidentTOV2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DuplicateResponseTO>>;
    public createByIdV2(createIncidentTOV2?: CreateIncidentTOV2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents`;
        return this.httpClient.request<DuplicateResponseTO>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: createIncidentTOV2,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an incident
     * Deletes an incident
     * @param id Störung UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteByIdV2(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deleteByIdV2(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deleteByIdV2(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deleteByIdV2(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteByIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List of all incidents as CSV
     * List of all incidents as CSV; page and pagesize are ignored here, all matching incidents are returned in one go
     * @param assignedParty Partei, der die Störung im Verlauf der Behebung aktuell zugeordnet ist
     * @param assignee Email des zugewiesenen Bearbeiters
     * @param city Ort der Störung
     * @param fromDate Untere Grenze bzgl. Anlagedatum
     * @param hasComplaint Liegt eine Beschwerde vor?
     * @param incidentStatus Current status of the incident
     * @param isNotTransferredToLuxData Only incidents not tranferred to LuxData
     * @param lightingOrControlCabinetNo Nummer der Leuchtstelle oder des Schaltschranks
     * @param netArea Netzgebiet der Störung
     * @param orderDueDatePassed Ist der Incident überfällig was die anhängigen Aufträge angeht?
     * @param orderTypes Filter nach bestimmten Typen von Aufträgen, die am Incident hinterlegt sein müssen
     * @param page Zu ladende Seite bei Verwendung von Pagination; default: 0
     * @param relationTypes Typ des Incidents (Single, Parent, Child)
     * @param size Anzahl an Incidents, die zurückgegeben werden sollen; max. 100
     * @param sortBy Feld nach dem das Ergebnis sortiert werden soll; default: reportingTimestamp
     * @param sortDir Sortierrichtung (ascending/descending); default: descending
     * @param statusGroup Current DiCon status group
     * @param street Straße der Störung
     * @param toDate Obere Grenze bzgl. Anlagedatum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCsvV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any>;
    public exportCsvV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpResponse<any>>;
    public exportCsvV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpEvent<any>>;
    public exportCsvV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (assignedParty !== undefined && assignedParty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignedParty, 'assignedParty');
        }
        if (assignee !== undefined && assignee !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignee, 'assignee');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (hasComplaint !== undefined && hasComplaint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasComplaint, 'hasComplaint');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (isNotTransferredToLuxData !== undefined && isNotTransferredToLuxData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotTransferredToLuxData, 'isNotTransferredToLuxData');
        }
        if (lightingOrControlCabinetNo !== undefined && lightingOrControlCabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingOrControlCabinetNo, 'lightingOrControlCabinetNo');
        }
        if (netArea !== undefined && netArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>netArea, 'netArea');
        }
        if (orderDueDatePassed !== undefined && orderDueDatePassed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDatePassed, 'orderDueDatePassed');
        }
        if (orderTypes) {
            orderTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderTypes');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (relationTypes) {
            relationTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'relationTypes');
            })
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (statusGroup !== undefined && statusGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusGroup, 'statusGroup');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/export`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reads an incident
     * API for GUI based incident inputs
     * @param id Störungs-Uuid.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByIdV2(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<IncidentTOV2>;
    public getByIdV2(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<IncidentTOV2>>;
    public getByIdV2(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<IncidentTOV2>>;
    public getByIdV2(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getByIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<IncidentTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List of all Child incidents
     * List of all Child incidents
     * @param id Störungs Uuid des Parents.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByParentIdV2(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<IncidentCollectionTOV2>;
    public getByParentIdV2(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<IncidentCollectionTOV2>>;
    public getByParentIdV2(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<IncidentCollectionTOV2>>;
    public getByParentIdV2(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getByParentIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/children`;
        return this.httpClient.request<IncidentCollectionTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists all incidents which are completed
     * Returns all incidents which are currently considered as being in completed for DiCon
     * @param assignedParty Partei, der die Störung im Verlauf der Behebung aktuell zugeordnet ist
     * @param assignee Email des zugewiesenen Bearbeiters
     * @param city Ort der Störung
     * @param fromDate Untere Grenze bzgl. Anlagedatum
     * @param hasComplaint Liegt eine Beschwerde vor?
     * @param incidentStatus Current status of the incident
     * @param isNotTransferredToLuxData Only incidents not tranferred to LuxData
     * @param lightingOrControlCabinetNo Nummer der Leuchtstelle oder des Schaltschranks
     * @param netArea Netzgebiet der Störung
     * @param orderDueDatePassed Ist der Incident überfällig was die anhängigen Aufträge angeht?
     * @param orderTypes Filter nach bestimmten Typen von Aufträgen, die am Incident hinterlegt sein müssen
     * @param page Zu ladende Seite bei Verwendung von Pagination; default: 0
     * @param relationTypes Typ des Incidents (Single, Parent, Child)
     * @param size Anzahl an Incidents, die zurückgegeben werden sollen; max. 100
     * @param sortBy Feld nach dem das Ergebnis sortiert werden soll; default: reportingTimestamp
     * @param sortDir Sortierrichtung (ascending/descending); default: descending
     * @param statusGroup Current DiCon status group
     * @param street Straße der Störung
     * @param toDate Obere Grenze bzgl. Anlagedatum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompleted(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<BaseIncidentCollectionTOV2>;
    public getCompleted(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<BaseIncidentCollectionTOV2>>;
    public getCompleted(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<BaseIncidentCollectionTOV2>>;
    public getCompleted(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (assignedParty !== undefined && assignedParty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignedParty, 'assignedParty');
        }
        if (assignee !== undefined && assignee !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignee, 'assignee');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (hasComplaint !== undefined && hasComplaint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasComplaint, 'hasComplaint');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (isNotTransferredToLuxData !== undefined && isNotTransferredToLuxData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotTransferredToLuxData, 'isNotTransferredToLuxData');
        }
        if (lightingOrControlCabinetNo !== undefined && lightingOrControlCabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingOrControlCabinetNo, 'lightingOrControlCabinetNo');
        }
        if (netArea !== undefined && netArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>netArea, 'netArea');
        }
        if (orderDueDatePassed !== undefined && orderDueDatePassed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDatePassed, 'orderDueDatePassed');
        }
        if (orderTypes) {
            orderTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderTypes');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (relationTypes) {
            relationTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'relationTypes');
            })
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (statusGroup !== undefined && statusGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusGroup, 'statusGroup');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/completed`;
        return this.httpClient.request<BaseIncidentCollectionTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists all incidents which are in progress
     * Returns all incidents which are currently considered as being in progress for DiCon
     * @param assignedParty Partei, der die Störung im Verlauf der Behebung aktuell zugeordnet ist
     * @param assignee Email des zugewiesenen Bearbeiters
     * @param city Ort der Störung
     * @param fromDate Untere Grenze bzgl. Anlagedatum
     * @param hasComplaint Liegt eine Beschwerde vor?
     * @param incidentStatus Current status of the incident
     * @param isNotTransferredToLuxData Only incidents not tranferred to LuxData
     * @param lightingOrControlCabinetNo Nummer der Leuchtstelle oder des Schaltschranks
     * @param netArea Netzgebiet der Störung
     * @param orderDueDatePassed Ist der Incident überfällig was die anhängigen Aufträge angeht?
     * @param orderTypes Filter nach bestimmten Typen von Aufträgen, die am Incident hinterlegt sein müssen
     * @param page Zu ladende Seite bei Verwendung von Pagination; default: 0
     * @param relationTypes Typ des Incidents (Single, Parent, Child)
     * @param size Anzahl an Incidents, die zurückgegeben werden sollen; max. 100
     * @param sortBy Feld nach dem das Ergebnis sortiert werden soll; default: reportingTimestamp
     * @param sortDir Sortierrichtung (ascending/descending); default: descending
     * @param statusGroup Current DiCon status group
     * @param street Straße der Störung
     * @param toDate Obere Grenze bzgl. Anlagedatum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<BaseIncidentCollectionTOV2>;
    public getInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<BaseIncidentCollectionTOV2>>;
    public getInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<BaseIncidentCollectionTOV2>>;
    public getInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (assignedParty !== undefined && assignedParty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignedParty, 'assignedParty');
        }
        if (assignee !== undefined && assignee !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignee, 'assignee');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (hasComplaint !== undefined && hasComplaint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasComplaint, 'hasComplaint');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (isNotTransferredToLuxData !== undefined && isNotTransferredToLuxData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotTransferredToLuxData, 'isNotTransferredToLuxData');
        }
        if (lightingOrControlCabinetNo !== undefined && lightingOrControlCabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingOrControlCabinetNo, 'lightingOrControlCabinetNo');
        }
        if (netArea !== undefined && netArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>netArea, 'netArea');
        }
        if (orderDueDatePassed !== undefined && orderDueDatePassed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDatePassed, 'orderDueDatePassed');
        }
        if (orderTypes) {
            orderTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderTypes');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (relationTypes) {
            relationTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'relationTypes');
            })
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (statusGroup !== undefined && statusGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusGroup, 'statusGroup');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/inprogress`;
        return this.httpClient.request<BaseIncidentCollectionTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists all in progress incidents of the currently signed in user
     * Returns all incidents which are in progress for the currently signed in user only
     * @param assignedParty Partei, der die Störung im Verlauf der Behebung aktuell zugeordnet ist
     * @param assignee Email des zugewiesenen Bearbeiters
     * @param city Ort der Störung
     * @param fromDate Untere Grenze bzgl. Anlagedatum
     * @param hasComplaint Liegt eine Beschwerde vor?
     * @param incidentStatus Current status of the incident
     * @param isNotTransferredToLuxData Only incidents not tranferred to LuxData
     * @param lightingOrControlCabinetNo Nummer der Leuchtstelle oder des Schaltschranks
     * @param netArea Netzgebiet der Störung
     * @param orderDueDatePassed Ist der Incident überfällig was die anhängigen Aufträge angeht?
     * @param orderTypes Filter nach bestimmten Typen von Aufträgen, die am Incident hinterlegt sein müssen
     * @param page Zu ladende Seite bei Verwendung von Pagination; default: 0
     * @param relationTypes Typ des Incidents (Single, Parent, Child)
     * @param size Anzahl an Incidents, die zurückgegeben werden sollen; max. 100
     * @param sortBy Feld nach dem das Ergebnis sortiert werden soll; default: reportingTimestamp
     * @param sortDir Sortierrichtung (ascending/descending); default: descending
     * @param statusGroup Current DiCon status group
     * @param street Straße der Störung
     * @param toDate Obere Grenze bzgl. Anlagedatum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<BaseIncidentCollectionTOV2>;
    public getOwnInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<BaseIncidentCollectionTOV2>>;
    public getOwnInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<BaseIncidentCollectionTOV2>>;
    public getOwnInProgress(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (assignedParty !== undefined && assignedParty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignedParty, 'assignedParty');
        }
        if (assignee !== undefined && assignee !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignee, 'assignee');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (hasComplaint !== undefined && hasComplaint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasComplaint, 'hasComplaint');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (isNotTransferredToLuxData !== undefined && isNotTransferredToLuxData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotTransferredToLuxData, 'isNotTransferredToLuxData');
        }
        if (lightingOrControlCabinetNo !== undefined && lightingOrControlCabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingOrControlCabinetNo, 'lightingOrControlCabinetNo');
        }
        if (netArea !== undefined && netArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>netArea, 'netArea');
        }
        if (orderDueDatePassed !== undefined && orderDueDatePassed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDatePassed, 'orderDueDatePassed');
        }
        if (orderTypes) {
            orderTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderTypes');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (relationTypes) {
            relationTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'relationTypes');
            })
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (statusGroup !== undefined && statusGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusGroup, 'statusGroup');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/inprogress/own`;
        return this.httpClient.request<BaseIncidentCollectionTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists all incidents
     * API for GUI based incident inputs
     * @param assignedParty Partei, der die Störung im Verlauf der Behebung aktuell zugeordnet ist
     * @param assignee Email des zugewiesenen Bearbeiters
     * @param city Ort der Störung
     * @param fromDate Untere Grenze bzgl. Anlagedatum
     * @param hasComplaint Liegt eine Beschwerde vor?
     * @param incidentStatus Current status of the incident
     * @param isNotTransferredToLuxData Only incidents not tranferred to LuxData
     * @param lightingOrControlCabinetNo Nummer der Leuchtstelle oder des Schaltschranks
     * @param netArea Netzgebiet der Störung
     * @param orderDueDatePassed Ist der Incident überfällig was die anhängigen Aufträge angeht?
     * @param orderTypes Filter nach bestimmten Typen von Aufträgen, die am Incident hinterlegt sein müssen
     * @param page Zu ladende Seite bei Verwendung von Pagination; default: 0
     * @param relationTypes Typ des Incidents (Single, Parent, Child)
     * @param size Anzahl an Incidents, die zurückgegeben werden sollen; max. 100
     * @param sortBy Feld nach dem das Ergebnis sortiert werden soll; default: reportingTimestamp
     * @param sortDir Sortierrichtung (ascending/descending); default: descending
     * @param statusGroup Current DiCon status group
     * @param street Straße der Störung
     * @param toDate Obere Grenze bzgl. Anlagedatum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<BaseIncidentCollectionTOV2>;
    public getV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<BaseIncidentCollectionTOV2>>;
    public getV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<BaseIncidentCollectionTOV2>>;
    public getV2(assignedParty?: AssignedParty, assignee?: string, city?: string, fromDate?: string, hasComplaint?: boolean, incidentStatus?: Set<IncidentStatus>, isNotTransferredToLuxData?: boolean, lightingOrControlCabinetNo?: string, netArea?: NetArea, orderDueDatePassed?: boolean, orderTypes?: Set<OrderType>, page?: number, relationTypes?: Set<IncidentRelationType>, size?: number, sortBy?: string, sortDir?: Direction, statusGroup?: StatusGroup, street?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (assignedParty !== undefined && assignedParty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignedParty, 'assignedParty');
        }
        if (assignee !== undefined && assignee !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>assignee, 'assignee');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (fromDate !== undefined && fromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fromDate, 'fromDate');
        }
        if (hasComplaint !== undefined && hasComplaint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasComplaint, 'hasComplaint');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (isNotTransferredToLuxData !== undefined && isNotTransferredToLuxData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotTransferredToLuxData, 'isNotTransferredToLuxData');
        }
        if (lightingOrControlCabinetNo !== undefined && lightingOrControlCabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingOrControlCabinetNo, 'lightingOrControlCabinetNo');
        }
        if (netArea !== undefined && netArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>netArea, 'netArea');
        }
        if (orderDueDatePassed !== undefined && orderDueDatePassed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDatePassed, 'orderDueDatePassed');
        }
        if (orderTypes) {
            orderTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'orderTypes');
            })
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (relationTypes) {
            relationTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'relationTypes');
            })
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sortBy !== undefined && sortBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDir, 'sortDir');
        }
        if (statusGroup !== undefined && statusGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusGroup, 'statusGroup');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }
        if (toDate !== undefined && toDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>toDate, 'toDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents`;
        return this.httpClient.request<BaseIncidentCollectionTOV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers an incident to Luxdata.
     * Allows the transfer of the given incident to Luxdata. Either a new fault is created in Luxdata if it is the first transfer or otherwise the existing fault is updated.
     * @param id Störung UUID
     * @param luxdataArchiveTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ArchiveLuxdataResponseTO>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ArchiveLuxdataResponseTO>>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ArchiveLuxdataResponseTO>>;
    public sendIncidentToLuxdataV2(id: string, luxdataArchiveTO?: LuxdataArchiveTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sendIncidentToLuxdataV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/luxdata/transfer`;
        return this.httpClient.request<ArchiveLuxdataResponseTO>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: luxdataArchiveTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assigns the currently processing party to the Incident
     * API for setting the editing party
     * @param id UUID der Störung
     * @param assignedPartyTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAssignedPartyV2(id: string, assignedPartyTO?: AssignedPartyTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public setAssignedPartyV2(id: string, assignedPartyTO?: AssignedPartyTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public setAssignedPartyV2(id: string, assignedPartyTO?: AssignedPartyTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public setAssignedPartyV2(id: string, assignedPartyTO?: AssignedPartyTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setAssignedPartyV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/assignedParty`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: assignedPartyTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assigns a assignee for the incident
     * API for setting an assignee
     * @param id Störung UUID
     * @param assigneeTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAssigneeV2(id: string, assigneeTO?: AssigneeTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public setAssigneeV2(id: string, assigneeTO?: AssigneeTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public setAssigneeV2(id: string, assigneeTO?: AssigneeTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public setAssigneeV2(id: string, assigneeTO?: AssigneeTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setAssigneeV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/assignee`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: assigneeTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets a complaint flag for the incident
     * API for setting a complaint flag
     * @param id Störung UUID
     * @param complaintTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setComplaintFlagV2(id: string, complaintTO?: ComplaintTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public setComplaintFlagV2(id: string, complaintTO?: ComplaintTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public setComplaintFlagV2(id: string, complaintTO?: ComplaintTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public setComplaintFlagV2(id: string, complaintTO?: ComplaintTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setComplaintFlagV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/complaint`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: complaintTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Releases a parent-child relationship for a child incident
     * Releases a parent-child relationship for a child incident
     * @param id Incident UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkParentIncidentByIdV2(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public unlinkParentIncidentByIdV2(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public unlinkParentIncidentByIdV2(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public unlinkParentIncidentByIdV2(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unlinkParentIncidentByIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/unlinkParentIncident`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an incident
     * API for GUI based incident inputs
     * @param id Incident UUID
     * @param incidentTOV2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateByIdV2(id: string, incidentTOV2?: IncidentTOV2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DuplicateResponseTOV2>;
    public updateByIdV2(id: string, incidentTOV2?: IncidentTOV2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DuplicateResponseTOV2>>;
    public updateByIdV2(id: string, incidentTOV2?: IncidentTOV2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DuplicateResponseTOV2>>;
    public updateByIdV2(id: string, incidentTOV2?: IncidentTOV2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateByIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/incidents/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DuplicateResponseTOV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: incidentTOV2,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
