import * as SystemUsers from '../data/SystemUsers.json';
import { SystemUser } from '../models/systemUser';
import { SystemUserSettings } from '../models/systemUserSettings';

export class SystemUserData {
  public static readonly SYSTEM_USERS: SystemUser[] = (SystemUsers as any)
    .default;

  public static findSystemUserByUsername(username: string): SystemUser {
    return this.SYSTEM_USERS.find(
      (su) =>
        !!su.username && su.username.toLowerCase() === username.toLowerCase()
    );
  }

  public static findSystemUserByEmail(email: string): SystemUser {
    return this.SYSTEM_USERS.find(
      (su) => !!su.email && su.email.toLowerCase() === email.toLowerCase()
    );
  }

  public static findSystemUserSettingsByEmail(
    email: string
  ): SystemUserSettings {
    const systemUser: SystemUser = this.findSystemUserByEmail(email);
    return !systemUser ? null : systemUser.settings;
  }

  public static findEmailFavoritesByEmail(email: string): string[] {
    const systemUser: SystemUser = this.findSystemUserByEmail(email);
    return !systemUser?.settings ? [] : systemUser.settings.emailFavorites;
  }
}
