import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ViewMode } from 'app/shared/common/models/enums/view-mode/view-mode';
import { IncidentsFilter } from '../models/incidentsFilter';
import {
  resetIncidentsFilterAction,
  setIncidentsFilterAction,
} from './incidents.actions';

export interface IncidentState {
  incidentsFilter: IncidentsFilter;
  crossFilterStore: crossFilterStore;
}

export interface crossFilterStore {
  ALL: IncidentsFilter;
  OWN: IncidentsFilter;
  ARCHIVED: IncidentsFilter;
}

const incidentsFilter: IncidentsFilter = {
  incidentStatus: [],
  unassignedOnly: false,
  assignee: '',
  assignedParty: null,
  hasComplaintOnly: false,
  orderDueDatePassed: false,
  street: '',
  city: '',
  reportingTimestampFrom: '',
  reportingTimestampTo: '',
  netAreas: [],
  priorities: [],
  lightingOrControlCabinetNo: '',
  dirty: false,
  page: 0,
  pageSize: 30,
  sortDir: 'Descending',
  sortBy: 'reportingTimestamp',
};

export const defaultState: IncidentState = {
  incidentsFilter,
  crossFilterStore: {
    ALL: { ...incidentsFilter },
    OWN: { ...incidentsFilter },
    ARCHIVED: { ...incidentsFilter },
  },
};

const getIncidentFeatureState =
  createFeatureSelector<IncidentState>('incidents');

export const incidentsFilterSelector = createSelector(
  getIncidentFeatureState,
  (state) => state.incidentsFilter
);
export const crossFilterStoreSelector = createSelector(
  getIncidentFeatureState,
  (state) => state.crossFilterStore
);

const crossFilterMapping = [
  {
    from: ViewMode.ALL,
    to: ViewMode.OWN,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
  {
    from: ViewMode.ALL,
    to: ViewMode.ARCHIVED,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'unassignedOnly',
      'assignee',
      'assignedParty',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
  {
    from: ViewMode.OWN,
    to: ViewMode.ALL,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
  {
    from: ViewMode.OWN,
    to: ViewMode.ARCHIVED,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
  {
    from: ViewMode.ARCHIVED,
    to: ViewMode.ALL,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'unassignedOnly',
      'assignee',
      'assignedParty',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
  {
    from: ViewMode.ARCHIVED,
    to: ViewMode.OWN,
    fields: [
      'lightingOrControlCabinetNo',
      'city',
      'street',
      'netAreas',
      'reportingTimestampFrom',
      'reportingTimestampTo',
      'hasComplaintOnly',
      'orderDueDatePassed',
    ],
  },
];

export const reducer = createReducer(
  defaultState,
  on(setIncidentsFilterAction, (state, action): IncidentState => {
    return {
      ...state,
      incidentsFilter: {
        ...action.filter,
        dirty: true,
      },
      crossFilterStore: {
        ...state.crossFilterStore,
        ALL:
          action.viewMode === ViewMode.ALL
            ? { ...action.filter }
            : { ...state.crossFilterStore.ALL },
        OWN:
          action.viewMode === ViewMode.OWN
            ? { ...action.filter }
            : { ...state.crossFilterStore.OWN },
        ARCHIVED:
          action.viewMode === ViewMode.ARCHIVED
            ? { ...action.filter }
            : { ...state.crossFilterStore.ARCHIVED },
      },
    };
  }),
  on(resetIncidentsFilterAction, (state, action): IncidentState => {
    return { ...defaultState };
  })
);

export function incidentReducer(state: IncidentState | undefined, action: Action) {
  return reducer(state, action);
}
