/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AutomatedOrderCreationActionTO } from './automatedOrderCreationActionTO';
import { IncidentProcessingCriteriaTO } from './incidentProcessingCriteriaTO';


/**
 * Daten zur automatischen Mitarbeiterzuordnung und Auftragsanlage
 */
export interface AutomatedIncidentPostProcessingRuleTO { 
    criteria: IncidentProcessingCriteriaTO;
    /**
     * Der Mitarbeiter welcher der Störung für das Kriterium zugeordnet werden soll
     */
    assignee?: string;
    /**
     * Aktivierungszustand der Regel, aktiv/inaktiv
     */
    active?: boolean;
    action?: AutomatedOrderCreationActionTO;
}

