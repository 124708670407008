import { Component, Inject } from '@angular/core';
import { PlannedMaintenanceTO } from '../../../api/generated/v2/incident';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
  styleUrls: ['./maintenance-dialog.component.scss']
})
export class MaintenanceDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public maintenance: PlannedMaintenanceTO,
    public dialogRef: MatDialogRef<MaintenanceDialogComponent>
  ) { }

}
