/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderType } from './orderType';
import { OrderState } from './orderState';
import { OrderFeedbackTO } from './orderFeedbackTO';


export interface OrderTO { 
    id?: string;
    incidentUuid?: string;
    type?: OrderType;
    state?: OrderState;
    contractor?: string;
    contractorNote?: string;
    orderFeedbacks?: Array<OrderFeedbackTO>;
    wishResolutionDateUtc?: string;
    plannedProcessingDateUtc?: string;
    externalNotificationId?: string;
    externalWfmId?: string;
    purchaseOrderNumber?: string;
    purchaseMaterial?: string;
    createdBy?: string;
    createdAtUtc?: string;
    linkedNotification?: string;
}
export namespace OrderTO {
}


