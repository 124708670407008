import { NetArea } from '../../api/generated/v2/incident';

export class NetAreaUtils {
  public static readonly NET_AREAS: NetAreaInfo[] = [
    {
      key: NetArea.North,
      selectLabel: 'Nord',
    },
    {
      key: NetArea.West,
      selectLabel: 'West',
    },
    {
      key: NetArea.Middle,
      selectLabel: 'Mitte',
    },
    {
      key: NetArea.South,
      selectLabel: 'Süd',
    },
    {
      key: NetArea.NoCode,
      selectLabel: 'Kein Netzgebiet',
    },
  ];

  public static getDefaultNetArea(na: NetArea): NetArea {
    return na ?? NetArea.NoCode;
  }
}

export interface NetAreaInfo {
  key: NetArea;
  selectLabel: string;
}
