<div class="cls-div">
  <button id="clsBtn" class="cls-btn" mat-icon-button (click)="onClsBtnClick()">
      <mat-icon style="margin: 0px;">close</mat-icon>
  </button>
</div>
<div class="div-stl" style="width: 400px;">
  <mat-icon id="sccIcn" svgIcon="checked" style="height: 50px; width: 50px;" *ngIf="data.success"></mat-icon>
  <mat-icon id="fldIcn" svgIcon="failed" style="height: 50px; width: 50px;" *ngIf="!data.success"></mat-icon>
</div>
<h1 id="message" mat-dialog-title>{{data.message}}</h1>
<div mat-dialog-content>
  <div id="hint" class="information">
      {{data.hint}}
  </div>
</div>
