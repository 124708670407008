/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentUploadResponseTO { 
    /**
     * Filename-part of the object key (part behind last \'/\')
     */
    fileName: string;
    /**
     * S3 object key
     */
    objectKey: string;
    /**
     * S3 document key
     */
    docId: string;
    /**
     * Timestamp when the document was saved in s3
     */
    uploadTimestamp?: string;
    /**
     * Direct document link url
     */
    objectUrl?: string;
    /**
     * The uuid of the incident where the doc was first created
     */
    incidentSourceUuid?: string;
    /**
     * Content length in bytes
     */
    contentLength?: string;
    /**
     * The UTF-8 encoded file name which was stored on the metadata
     */
    encodedUniqueFileName?: string;
    /**
     * Signed PUT url to upload a document with the exact same signature
     */
    uploadUrl?: string;
    expiresAt?: string;
}

