/**
 * DiCon Incident Service API
 * Folgende API wird dazu verwendet um Störungen im #LightOn System zu bearbeiten.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Übermittlungsstatus der SAP Meldung / Dienstleister Mail
 */
export type SendState = 'ACCEPTED' | 'CANCELED' | 'CREATED' | 'FAILED' | 'NOT_ACCEPTED' | 'SENDING';

export const SendState = {
    ACCEPTED: 'ACCEPTED' as SendState,
    CANCELED: 'CANCELED' as SendState,
    CREATED: 'CREATED' as SendState,
    FAILED: 'FAILED' as SendState,
    NOT_ACCEPTED: 'NOT_ACCEPTED' as SendState,
    SENDING: 'SENDING' as SendState
};

