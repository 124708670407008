/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReporterType } from './reporterType';
import { IncidentStatus } from './incidentStatus';
import { IssueType } from './issueType';
import { InputChannel } from './inputChannel';
import { NetArea } from './netArea';
import { SymptomType } from './symptomType';
import { SimpleStateHistoryEntryTO } from './simpleStateHistoryEntryTO';
import { FaultReport2Reporter } from './faultReport2Reporter';


/**
 * Basis Daten der Störung
 */
export interface FaultReport3 { 
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo?: string;
    /**
     * Vollständige Schaltstellennummer.
     */
    controlCabinetNo?: string;
    /**
     * PLZ der Störungsmeldung
     */
    zipCode?: string;
    /**
     * Ort der Störung
     */
    city: string;
    /**
     * Ortsteil
     */
    cityDistrict?: string;
    /**
     * Straße der Störung
     */
    street: string;
    /**
     * Hausnummer der Störungsmeldung
     */
    houseNo?: string;
    /**
     * Liste der gefundenen Symptome
     */
    symptoms: Set<SymptomType>;
    /**
     * Symptom Beschreibung für Sonstige
     */
    symptomOther?: string;
    /**
     * Weitere Anmerkungen zur Störungsmeldung
     */
    remarksMunicipality?: string;
    recognitionDateTime?: string;
    /**
     * Weitere Anmerkungen zum Ort der Störungsmeldung
     */
    furtherRemarks?: string;
    netArea?: NetArea;
    /**
     * UUID der Störung
     */
    uuid?: string;
    /**
     * customer scope this faultreport belongs to. Normally an AGS (amtlicher Gemeinde-Schluessel).
     */
    customerScope?: string;
    /**
     * Weitere Anmerkungen des Bürgers
     */
    remarksCitizen?: string;
    /**
     * Protokollierte Änderungen der Störung
     */
    journal?: Array<SimpleStateHistoryEntryTO>;
    /**
     * Automatisches Feld: Melder der Störung
     */
    createdBy?: string;
    createdAt?: string;
    reportingTimestamp: string;
    inputChannel?: InputChannel;
    completedAt?: string;
    reporterType?: ReporterType;
    reporter?: FaultReport2Reporter;
    issueType?: IssueType;
    incidentStatus?: IncidentStatus;
}
export namespace FaultReport3 {
}


