/**
 * DiCon Incident Service API
 * Folgende API wird dazu verwendet um Störungen im #LightOn System zu bearbeiten.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateMailTO } from '../model/models';
import { SendMailNotificationResponseTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface IncidentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Leitet eine Störung per Mail an einen Dienstleister zur Behebung weiter.
     * Die Ströung wird mit allen Informationen an einen angegeben Mail Empfänger weitergeleitet.
     * @param id Eindeutige Störung UUID
     * @param xRequestId Identifier to trace the request.
     * @param xExternalId Identifier for external traceing. Passthrough value.
     * @param createMailTO
     */
    sendMailNotification(id: string, xRequestId?: string, xExternalId?: string, createMailTO?: CreateMailTO, extraHttpRequestParams?: any): Observable<SendMailNotificationResponseTO>;

}
