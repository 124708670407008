import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { AppModule } from './app/app.module';

if (EnvironmentService.isProduction()) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
